const PURCHASE_URL = 'https://dsmarketing.datasociety.com/dmitri-adler-meldr'

function totalPathwayHours(pathway){
    return pathway.learnerPathSegmentsByPathIdList.reduce((totalHours, segment) => {
        return totalHours + Math.round(Number(segment.course.hours))
    }, 0)
}

function uniquePathwayTags(pathway) {
    
    const everyTag = pathway.learnerPathSegmentsByPathIdList.reduce((everyTag, segment) => {
        everyTag.push(...segment.course.tags)
        return everyTag
    }, [])
    
    return [...new Set(everyTag)]
}

    
export {
    PURCHASE_URL,
    totalPathwayHours,
    uniquePathwayTags
}