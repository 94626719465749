import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTopBarText } from '../redux/actions'
import { Grid } from '@material-ui/core'
import ChangePassword from "./../components/account_settings/ChangePassword"
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SideSubMenu from './../components/account_settings/SideSubMenu'






const AccountSettings = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser)
    const providerId = currentUser?.providerData[0]?.providerId

    const [activeSubMenuObj, setActiveSubMenuObj] = useState(providerId === 'password' && {
        menu: 'Change password',
        component: <ChangePassword />
    })

    // Update Top Bar Heading
    useEffect(() => {
        dispatch(updateTopBarText("Account Settings"))
    }, [dispatch])


    const useStyles = makeStyles((theme) => ({

        fixedNavContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: "fixed",
            borderRight: `1.5px solid ${theme.palette.primary[50]}`,
            padding: '1rem 1rem 2rem 1rem',
            width: "360px",
            height: '90%',
        },
        mainContainer: {
            display: 'flex',
            marginLeft: '360px',
            width: "100%",
            padding: '1rem 0',
            height: '90%',
        },
        lightText: {
            color: theme.palette.primary[400]
        },
    }));
    const classes = useStyles()


    const subMenuArray = [
        {
            menu: 'Change password',
            component: <ChangePassword />
        }
    ]

    return (
        <Grid item container>
            {/* left Submenu */}
            <Grid item className={classes.fixedNavContainer} >
                <SideSubMenu
                    subMenuArray={providerId === "password" ? subMenuArray : subMenuArray.slice(1)}
                    activeSubMenuObj={activeSubMenuObj}
                    setActiveSubMenuObj={setActiveSubMenuObj}
                />
            </Grid>
            <Grid item className={classes.mainContainer}>
                {activeSubMenuObj?.component}
            </Grid>
        </Grid >

    )
}

export default AccountSettings