import React from 'react';
import { useSelector } from 'react-redux'
import { Box, Button, CircularProgress } from '@material-ui/core'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';   
import { buildGQLDispatchConfig } from '../lib/util'

function CatalogDownload() {
    
    const currentOrg = useSelector(state => state.currentOrg)
    const [loading, setLoading] = React.useState(false);

    const handleDownload = () => {
        setLoading(true)
        const api_url = process.env.REACT_APP_API_URL
        const query = `query {
            CourseCatalog(orgSubdomain: "${currentOrg.subdomain}") {
              pdf
              filename
            }
          }`

        const configObj = buildGQLDispatchConfig(query);

        fetch(api_url, configObj)
        .then((res) => res.json())
        .then((json) => { 
            const { pdf, filename } = json.data.CourseCatalog
            
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
        
            downloadLink.href = linkSource;
            downloadLink.download = filename;
            downloadLink.click();
            downloadLink.remove()
            setLoading(false)

        })
        .catch((err) => console.log(err));
    }

    return (
        <Box>
            <Button
                color="secondary"
                startIcon={loading ? <CircularProgress size={20} color="secondary" /> : <GetAppOutlinedIcon />}
                onClick={()=> handleDownload()}
                disabled={loading}
            >
                Download Course Catalog
            </Button>
        </Box>
    )

}

export default CatalogDownload