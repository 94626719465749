import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router';
import { updateLoginOpen } from '../../redux/actions'
import { Button, Dialog, DialogContent, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core'
import { ReactComponent as MeldrLogoHz } from '../../assets/imgs/meldr-hz.svg'
import { ReactComponent as MeldrPoweredByDS } from '../../assets/imgs/meldr-powered-by-ds-login.svg'
import { ReactComponent as GoogleLogo } from '../../assets/imgs/google_logo.svg'
import { ReactComponent as GithubLogo } from '../../assets/imgs/github_logo.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '../DividerWithText'
import { login, loginWithGoogle, loginWithGithub } from '../../lib/firebase'
import { handleKeyEvent } from '../../lib/util'

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor:"#ffffff", 
        padding:"0rem",
        overflowX: "hidden", 
        maxWidth:"28rem"
    },
    loginItem:{
        padding: ".5rem 3rem"
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: "0rem 3rem"
    },
    logo: {
        width: '18px',
        height: '18px'
    },
    googleBtn:{
        color: theme.palette.secondary.main
    },
    githubBtn: {
        backgroundColor: '#191717',
        color: '#ffffff',
        '&:hover': {
            background: '#2c2a2a',
            color: '#ffffff'
        }
    },
    urlText: {
        color: theme.palette.secondary.main,
        cursor:"pointer"
    },
    errorMessage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        backgroundColor: '#ED086914',
        border: '1px solid #ED0869',
        borderRadius: '8px'
    }

}));

function Login(){
    const openLoginForm = useSelector(state => state.openLoginForm)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const classes = useStyles()
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const tenantId = useSelector(state => state.currentOrg?.tenantId)
    const providers = useSelector(state => state.currentOrg?.preferences?.loginProviders) || []
    const [selectedProvider, setSelectedProvider] = useState('')

    async function handleSubmit(e, provider) {
        e.preventDefault()

        try {
            setSelectedProvider(provider)
            setError("")
            setLoading(true)
        
            switch(provider) {
                case "email/password":
                    await login(emailRef.current.value, passwordRef.current.value, tenantId)
                    break;
                case "google":
                    await loginWithGoogle(tenantId);
                    break;
                case "github":
                    await loginWithGithub(tenantId)
                    break;
                default: 
                    return <Alert severity="error">{"Login failed, please contact your administrator."}</Alert>
            }

            setLoading(false)
            dispatch(updateLoginOpen(false)) 
            
        } catch(error) {
            console.error(`${provider} log in error`, error)
            setLoading(false)
            if(error.code !== 'auth/popup-closed-by-user')
                setError(error.message || "Failed to sign in. Please contact your administrator.")
        }
        
    }

    function handleCloseLogin() {
        dispatch(updateLoginOpen(false)) 
        navigate(location.pathname)         
    }

    function handleForgotPassword(e) {
        e.preventDefault()
        dispatch(updateLoginOpen(false))
        navigate('/reset-password')
    }

    const handleOpenSignup = (e) => {
        e.preventDefault()
        dispatch(updateLoginOpen(false))
        const params = new URLSearchParams(location.search)
        const searchParams = params.get('redirect') ? "?signup=true&redirect=true" : "?signup=true"
        navigate(location.pathname+searchParams)
    }

    return(
        <Dialog open={openLoginForm} onClose={handleCloseLogin} aria-labelledby="log in to meldR">
            <DialogContent className={classes.root} style={{paddingTop:"0rem"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <IconButton style={{float:"right", margin:".5rem"}} onClick={handleCloseLogin}>
                            <HighlightOffIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-1rem"}}>
                        <MeldrLogoHz style={{width:"10rem", height:"auto", display:"block", margin:"auto"}}/>
                    </Grid>
                    <Grid item xs={12} style={{margin:"1.5rem 0rem"}}>
                        <Typography align="center" variant="h5">Log in to your account</Typography>
                    </Grid>
                    { providers.length === 0 && 
                        <Grid item xs={12}>
                            <Alert severity="warning">
                                {"Supervisor dashboard has not been set up. Please contact your administrator."}
                            </Alert>
                        </Grid>
                    }
                    {providers.includes("google") && 
                        <Grid item xs={12} className={classes.loginItem}>
                            <Button 
                                variant="outlined"
                                onClick={(e) => handleSubmit(e, "google")}
                                fullWidth
                                startIcon={<GoogleLogo className={classes.logo} />}
                                className={classes.googleBtn}
                                >
                                    Log in with Google
                            </Button>
                        </Grid>
                    }
                    {providers.includes("github") && 
                        <Grid item xs={12} className={classes.loginItem}>
                            <Button
                                variant="outlined"
                                onClick={(e) => handleSubmit(e, "github")}
                                fullWidth
                                className={classes.githubBtn}
                                startIcon={<GithubLogo className={classes.logo} />}
                                >
                                Log in with Github
                            </Button>
                        </Grid>
                    }
                    { (selectedProvider === 'google' || selectedProvider === 'github') && error &&
                        <Grid item xs={12} className={classes.loginItem}>
                            <Alert severity="error" className={classes.errorMessage}>
                                {error}
                            </Alert>
                        </Grid>  
                    }
                    {providers.length > 1 && providers.includes("email/password") &&
                        <Divider className={classes.divider} horizontal="true"> or Log in with Email </Divider>
                    }
                    {providers.includes("email/password") &&
                        <Grid item container xs={12} className={classes.loginItem}>
                            <TextField 
                                id="email" 
                                label="Email"
                                type="email" 
                                variant="outlined" 
                                inputRef={emailRef}
                                required 
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                            />
                            <TextField 
                                id="password" 
                                label="Password"
                                type="password" 
                                variant="outlined" 
                                inputRef={passwordRef}
                                required 
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                                onKeyUp={(e) => handleKeyEvent(e, function(){ return handleSubmit(e, "email/password") })}
                            />
                            { selectedProvider === 'email/password' && error &&
                                <Alert severity="error" className={classes.errorMessage}>
                                    {error}
                                </Alert>
                            }
                            <Link to='#' component="button" style={{marginBottom: "1rem", flex: "auto"}} onClick={handleForgotPassword}>
                                <Typography variant="body2" align="right" className={classes.urlText}>Forgot password?</Typography>
                            </Link>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                fullWidth
                                onClick={(e) => handleSubmit(e, "email/password")}
                            >
                                Log in
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.loginItem} style={{textAlign: "center"}}>   
                        <Typography variant="body2" display="inline">Don't have an account? </Typography>
                        <Link to='#' component="button" onClick={handleOpenSignup} style={{paddingBottom: ".2rem"}}>
                            <Typography variant="body2" display="inline" className={classes.urlText}>Sign Up</Typography>
                        </Link>
                    </Grid>
                    <Grid item style={{backgroundColor:"#ebecee", padding:"1rem"}} xs={12}>
                        <MeldrPoweredByDS style={{width:"100%", display:"block", margin:"auto"}}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default Login