// FAQ
const FAQ_URL = 'https://lxcp.meldr.ai/faq';
const TERMS_URL = 'https://lxcp.meldr.ai/terms';

// localStorage keys
const LEARNER_KEY = 'learnerInfo'

// potential course view options
const VIEW_TYPES = { LEARNER: 'learner', SUPERVISOR: 'supervisor' }

const COHORT_STATUSES = {
  COMPLETED: "completed"
}

const SELECTION_TYPES = {
  COURSE: 'course',
  PATHWAY: 'pathway'
}

// Button text for Learner's View All Paths
const ALL_PATHS_TEXT = "All Learner Paths"

//initial selected course object
const INITIAL_SELECTED_COURSE = {
    name: "",
    description: "",
    hours: "",
    prerequisitesDescription: "",
    softwareRequirements: "",
    tags: [],
    topics:[],
    coursePurchasesByCourseIdList: [],
    courseVotesByCourseIdList: [],
    pathName: ""
  }

const ADMIN_PREFIX = "/admin"

const ANONYMOUS_DEFAULT_PAGE = '/course-catalog'
const LEARNER_DEFAULT_PAGE = '/dashboard'
const SUPERVISOR_DEFAULT_PAGE = '/admin/course-catalog'

export {
    ADMIN_PREFIX,
    ALL_PATHS_TEXT,
    FAQ_URL,
    TERMS_URL,
    LEARNER_KEY,
    VIEW_TYPES,
    SELECTION_TYPES,
    INITIAL_SELECTED_COURSE,
    ANONYMOUS_DEFAULT_PAGE,
    LEARNER_DEFAULT_PAGE,
    SUPERVISOR_DEFAULT_PAGE,
    COHORT_STATUSES
}