import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardContent, FormControl, FormGroup, Input, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { resetPassword } from '../../lib/firebase'
import { updateTopBarText } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    background: {
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.palette.primary[50]
    },
    card: {
        maxWidth: "22rem",
        maxHeight: "150vh",
        position: 'fixed',
        top: '40%',
        left: '50%',
        transform: "translate(-50%, -50%)",
        paddingBottom: theme.spacing(2),
        backgroundColor: '#FFFFFF'
    },
    header: {
        width: 'auto',
        display: 'flex',
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    bodyText: {
        marginBottom: theme.spacing(2)
    },
    loginText: {
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(5)
    },
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary['A700']
        }
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette.secondary.main
        }
    }
}));

function ForgotPassword() {
    const dispatch = useDispatch()
    const classes = useStyles();
    const emailRef = useRef();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const tenantId = useSelector(state => state.currentOrg?.tenantId)

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value, tenantId);
            setMessage('Please check your inbox for further instructions');
        } catch(err) {
            console.error(err);
            setError(err.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        dispatch(updateTopBarText(''))
    },[dispatch])
    
    return (
        <>
            <div className={classes.background}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h6" className={classes.header}>Reset Password</Typography>
                        <Typography variant="body1" className={classes.bodyText}>Enter your email address below. A link to reset your password will be sent.</Typography>
                        {error &&
                            <Alert severity="error">{error}</Alert>
                        }
                        {message &&
                            <Alert severity="success">{message}</Alert>
                        }
                        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
                            <FormGroup id="reset-password" margin="normal">
                                <FormControl name="email">
                                    <Input 
                                        type="email" 
                                        className={classes.input}
                                        inputRef={emailRef}
                                        disableUnderline={true}
                                        placeholder="Email"
                                        required 
                                        fullWidth
                                    />
                                </FormControl>
                            </FormGroup>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.submit}
                                fullWidth>
                                Reset Password
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

export default ForgotPassword
