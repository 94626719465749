import React, { useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CodeIcon from '@mui/icons-material/Code';
import ArticleIcon from '@mui/icons-material/Article';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as PageIcon } from '../../assets/imgs/icons/single_page.svg'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    expand: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    subMenu: {
        justifyContent: 'flex-start', 
        paddingLeft: '4rem', 
        textTransform:"none", 
        fontWeight:"400",
        color: theme.palette.primary.main
    },
    activeSubMenu: {
        backgroundColor: theme.palette.secondary[50],
        color: theme.palette.primary.main
    }
}));

function NavElement({navIndex, menuIcon, menuText, subMenuArray, selectedSlidesObj, setSelectedSlidesObj}) {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    function subMenuIcon(menuType) {
        switch(menuType){
            case 'code': 
                return <CodeIcon style={{color:"#138909"}}/>
            case 'launch':
                return <OpenInNewIcon style={{color:"#1426FF"}} />
            case 'welcome':
                return <PageIcon style={{fill:"#8129cc", paddingTop:"2px"}}/>
            default:
                return <ArticleIcon />
        }
    }

    function subMenuAction(navObj) {
        if(!(navObj.navAction==="new_tab")){
            setSelectedSlidesObj(navObj)
        }
    }

    // TODO: See if instead can override with CSS (textOverflow: "ellipsis")
    function truncate(input, charLength=25) {
        if (input.length > charLength) {
           return input.substring(0, charLength) + '...';
        }
        return input;
     };

    function isSelected(navItem){
        return (selectedSlidesObj?.link===navItem?.link && selectedSlidesObj?.text===navItem?.text)
    }

    return(
        <>
            <Grid item container alignItems="center">
                <Grid item>
                    <IconButton id={navIndex===0? "first-course-expand": ""} size="small" onClick={() => setExpanded(!expanded)}>
                        <ExpandMoreIcon 
                            className={
                                clsx(classes.expand, 
                                    {[classes.expandOpen]: expanded,
                            })}
                            color="primary" 
                        />
                    </IconButton>
                </Grid>
                <Grid item>
                    {menuIcon}
                </Grid>
                <Grid item style={{paddingLeft:"0.25rem"}}>
                    <Typography variant="body2" color="primary">
                        {menuText}
                    </Typography>
                </Grid>
            </Grid>
            {
                expanded && 
                subMenuArray.map((subMenuObj, index) => {
                    return(
                        <Grid key={subMenuObj.text+index} item xs={12}>
                            <Button 
                                fullWidth 
                                className={
                                    clsx(classes.subMenu, 
                                        {[classes.activeSubMenu]: isSelected(subMenuObj),
                                })}
                                startIcon={subMenuIcon(subMenuObj.navType)}
                                onClick={() => subMenuAction(subMenuObj)}
                                href={subMenuObj.navAction === 'new_tab' ? subMenuObj.link : null}
                                target='_blank' 
                                rel='noopener noreferrer'
                                disabled={isSelected(subMenuObj)}
                            >
                                <span id={navIndex===0 && index===0? "first-sub-menu-item" : ""}>
                                    {truncate(subMenuObj.text)}
                                </span>
                            </Button>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default NavElement