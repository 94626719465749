import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EventIcon from '@material-ui/icons/Event';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@mui/icons-material/Link';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import LearnerDrawerCta from '../components/drawer/LearnerDrawerCta';
import SupervisorDrawerCta from '../components/drawer/SupervisorDrawerCta';
import { nextEnrollableCohort, futureAvailableCohort } from '../lib/registrationRequest';
import { totalPathwayHours, uniquePathwayTags } from '../lib/coursePathwayHelp';
import { formattedDate, addHours, getTimezoneName } from '../lib/date';
import { INITIAL_SELECTED_COURSE, VIEW_TYPES, SELECTION_TYPES } from '../lib/globalKeys';
import pluralize from 'pluralize';
import { handleKeyEvent } from '../lib/util'
import { handleCopy } from '../lib/util';

const drawerWidth = "32rem";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default
    },
    fixedDrawerContainer: {
        position: "fixed",
        maxWidth: "100%",
        height: "100vh"
    },
    fixedTopClassName: {
        padding: "2rem",
        overflowY: "auto",
        height:"80%",
    },
    courseIcon: {
        width:"1rem", 
        paddingTop:"4px",
        color: theme.palette.primary[400]
    },
    lightText: {
        color: theme.palette.primary[400]
    },
    tagBackground: {
        padding:"0rem 0.5rem"
    },
    expandCollapseText: {
        cursor: "pointer",
        textDecoration: "underline",
        fontFamily: "Roboto",
        fontWeight: 400,
        color: theme.palette.primary.main  
    }
  }));

function SelectionDrawer({drawerOpen, setDrawerOpen, view, selectionType, selection, setSelection}) {
    const [expanded, setExpanded] = useState(false)
    const [expandedTags, setExpandedTags] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const classes = useStyles()
    const copyUrlText = `${window.location.href}`
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const registeredCourseNames = registeredCohorts.map(cohort => cohort.coursePurchase.course.name)
    const registeredCourseName = registeredCourseNames.includes(selection.name)
    
    let tagsCount ;

    useEffect(() => {
        const handler = function(e){
            handleKeyEvent(e, function(){ return handleClose() }, 'escape')
        }
        window.addEventListener("keyup", handler) 
        return () => {
            window.removeEventListener("keyup", handler);
        };
        // eslint-disable-next-line
    }, [])
    
    const handleClose = () => {
        setDrawerOpen(false)
        setSelection(INITIAL_SELECTED_COURSE)
        const prefix = view === VIEW_TYPES.SUPERVISOR? '/admin': ''
        const searchParams = new URLSearchParams(location.search)
        const pathParam = searchParams.get('path')
        const querySuffix = pathParam? `?path=${pathParam}` : ''
        if(location.pathname.includes('/course-catalog')){
            navigate(`${prefix}/course-catalog${querySuffix}`)
        }
        if(location.pathname.includes('/pathways')){
            navigate(`${prefix}/pathways${querySuffix}`)
        }
    }

    const trimmedDescription = (description) => {
        return description.length<=100? description : description.substring(0,100) +"..."
    }

    function handleExpandKeyUp(event, expanded){
        if (event.keyCode === 13) {
            event.preventDefault();
            setExpanded(!expanded)
          }
    }

    function handleExpandTagsKeyUp(event, expandedTags){
        if (event.keyCode === 13) {
            event.preventDefault();
            setExpandedTags(!expandedTags)
          }
    }

    function CopyButton() {
        return view === VIEW_TYPES.LEARNER && (
            <Tooltip 
                PopperProps={{
                    disablePortal: true,
                }}
                placement="top"
                onClose={() => setTooltipOpen(false)}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied to Clipboard"
            >
                <IconButton onClick={()=>handleCopy('copy-url', setTooltipOpen)}>
                    <LinkIcon />
                </IconButton>
            </Tooltip>
        )
    }

    const renderCta = () => {
        if (!!selection.name) {
            if (view === VIEW_TYPES.LEARNER) {
                return (<LearnerDrawerCta 
                    setDrawerOpen={setDrawerOpen}
                    selection={selection}
                    setSelection={setSelection}
                    selectionType={selectionType}
                />)
            } else if (view === VIEW_TYPES.SUPERVISOR) {
                return (<SupervisorDrawerCta 
                    setDrawerOpen={setDrawerOpen}
                    selection={selection}
                    setSelection={setSelection}
                    selectionType={selectionType}
                />)
            }

        }
    }

    const selectionHours = () => {
        if (!!selection.name) {
            if (selectionType === SELECTION_TYPES.COURSE) {
                return Math.round(selection.hours)
            } else {
                return totalPathwayHours(selection)
            }
        }
    }

    const selectionChildCount = () => {
        if (!!selection.name) {
            if (selectionType === SELECTION_TYPES.COURSE) {
                return pluralize("Topic", selection.topics.length, true)
            } else {
                return pluralize("Course", selection.learnerPathSegmentsByPathIdList.length, true)
            }
        }
    }

    const selectionTags = () => {
        if (!!selection.name) {
            let tagList = []
            if (selectionType === SELECTION_TYPES.COURSE) {
                tagList = selection.tags
            } else {
                tagList = uniquePathwayTags(selection)
            }
    
            tagsCount = tagList.length;
            return tagList.map((tag, index) => {
                return (
                    <Grid item key={index}>
                        <Paper className = {classes.tagBackground} elevation={0}>
                            <Typography variant="caption" style={{fontFamily:"Roboto", fontWeight:"500"}}>{tag.toUpperCase()}</Typography>
                        </Paper>
                    </Grid>
                )
            })
        }
    }

    const selectionChildList = () => {
        if (!!selection.name) {
            let childList = []
            if (selectionType === SELECTION_TYPES.COURSE) {
                childList = selection.topics.map(topic => topic.topic_title)
            } else {
                childList = selection.learnerPathSegmentsByPathIdList.map(segment => segment.course.name)
            }
    
            return childList.map((childName, index)=>{
                return(
                    <Grid key={index} item xs={12}>
                        <Grid container spacing={1} style={{flexFlow:"nowrap"}}>
                            <Grid item>
                                <CheckIcon color="primary"/>
                            </Grid>
                            <Grid item>
                                <Typography dispay="inline">{childName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })
        }
    }

    const setCohortInfo = (selection) => {
        const registeredCohort = registeredCohorts.find(cohort => cohort.coursePurchase?.course?.id === selection.id)
        let foundCohort = registeredCohort || nextEnrollableCohort(selection) || futureAvailableCohort(selection)
        let cohortSessions = foundCohort?.cohortSessionsByCohortIdList || []
        const timeZone = getTimezoneName()
       
        if(cohortSessions.length > 0 ) {
            return(
            <>
                <Grid item xs={12}>
                    <Typography align="left" variant="subtitle1" style={{fontWeight:"bold"}} color="primary">
                        { registeredCourseName ? 'Your Cohort Schedule' : 'Next Cohort Schedule' }
                    </Typography>
                </Grid>
                <Grid item container xs={8}>
                    <Paper style={{padding:"1rem", backgroundColor:"#fafafa", width:"100%"}} elevation={0}>
                        {cohortSessions.map( (session, index) => {
                            const dateTime = new Date(session.startTimestamp)
                            return(
                                <Grid key={`cohort-session-${index}`} item container justifyContent='space-between' spacing={2}>
                                    <Grid item>{dateTime.toLocaleDateString()}</Grid>
                                    <Grid item>{`${dateTime.toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'})}-${addHours(session.durationHours, dateTime)} ${timeZone}`}</Grid>
                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </>)
        }                
    }

    return(
        selectionType && <Drawer 
            anchor="right" 
            variant="persistent" 
            aria-labelledby="registration-form"
            className={classes.drawer} classes={{
            paper: classes.drawerPaper,
            }} 
            open={drawerOpen} 
            PaperProps={{elevation:20}}
        >
            <div className={classes.fixedDrawerContainer}>
                <div className={classes.fixedTopClassName}>
                    <textarea id="copy-url" readOnly name="copy-url" value={copyUrlText} hidden/>
                    <IconButton style={{float:"right", marginRight:"-1.5rem", marginTop:"-1.5rem"}} onClick={handleClose}>
                        <HighlightOffIcon color="primary" />
                    </IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography display="inline" align="left" variant="subtitle1" style={{fontWeight:"bold"}} color="primary">
                                {selection.name}
                            </Typography>
                            <CopyButton />
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <ScheduleIcon className={classes.courseIcon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.lightText} variant="subtitle2">
                                                {selectionHours()} Hours
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <FormatListNumberedRtlIcon className={classes.courseIcon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.lightText} variant="subtitle2">
                                                {selectionChildCount()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    ( selectionType === SELECTION_TYPES.COURSE && 
                                        (nextEnrollableCohort(selection) || futureAvailableCohort(selection))
                                    ) &&
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <EventIcon className={classes.courseIcon} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.lightText} variant="subtitle2">
                                                    {   nextEnrollableCohort(selection)?
                                                        formattedDate(nextEnrollableCohort(selection).dateStart) + "-" + formattedDate(nextEnrollableCohort(selection).dateEnd)
                                                        :
                                                        formattedDate(futureAvailableCohort(selection).dateStart) + "-" + formattedDate(futureAvailableCohort(selection).dateEnd)
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={1}>
                                {expandedTags ? selectionTags() : selectionTags()?.slice(0,8)}
                            </Grid>
                            <Grid container>
                                {
                                tagsCount > 8 &&
                                    <Typography tabIndex="0" variant="overline" className={classes.expandCollapseText} onClick={()=>setExpandedTags(!expandedTags)} onKeyUp={(e)=>handleExpandTagsKeyUp(e, expandedTags)}>
                                        {expandedTags? "View Fewer Tags": "View All Tags"}
                                    </Typography>
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1" style={{ marginBottom:"-0.25rem"}}>{expanded ? selection.description : trimmedDescription(selection.description)}</Typography>
                            {
                                selection.description.length>100 &&
                                    <Typography tabIndex="0" variant="overline" className={classes.expandCollapseText} onClick={()=>setExpanded(!expanded)} onKeyUp={(e)=>handleExpandKeyUp(e, expanded)}>
                                        {expanded? "Collapse": "Expand"}
                                    </Typography>
                            }
                        </Grid>
                        {selectionType === SELECTION_TYPES.COURSE && setCohortInfo(selection)}
                        <Grid item xs={12}>
                            <Typography align="left" variant="subtitle1" style={{fontWeight:"bold"}} color="primary">
                                {selectionType === SELECTION_TYPES.COURSE ? 'Topics' : 'Courses'}
                            </Typography>
                        </Grid>
                        {selectionChildList()}
                        {selectionType === SELECTION_TYPES.COURSE && <Grid item xs={12}>
                            <Paper style={{padding:"1rem"}} elevation={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography color="primary" variant="subtitle2" style={{fontWeight:"bold", fontSize: "0.625rem"}}>SOFTWARE REQUIREMENTS:</Typography>
                                        <Typography color="primary" variant="subtitle2" style={{fontSize: "0.75rem"}}>{selection.softwareRequirements}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography color="primary" variant="subtitle2" style={{fontWeight:"bold", fontSize: "0.625rem"}}>PREREQUISITES:</Typography>
                                        <Typography color="primary" variant="subtitle2" style={{fontSize: "0.75rem"}}>{selection.prerequisitesDescription}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>}
                    </Grid>
                </div>
                {renderCta()}
            </div>
        </Drawer>
    )
}

export default SelectionDrawer
