import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

const auth = app.auth()

function signup(email, password, tenantId) {
  auth.tenantId = tenantId
  return auth.createUserWithEmailAndPassword(email, password)
}

function login(email, password, tenantId) {
  auth.tenantId = tenantId
  return auth.signInWithEmailAndPassword(email, password)
}

function loginWithGoogle(tenantId) {
  auth.tenantId = tenantId
  const googleProvider = new firebase.auth.GoogleAuthProvider()
  return auth.signInWithPopup(googleProvider)
}

function loginWithGithub(tenantId) {
  auth.tenantId = tenantId
  const githubProvider = new firebase.auth.GithubAuthProvider()
  return auth.signInWithPopup(githubProvider)
}

function logout() {
  return auth.signOut()
}

function resetPassword(email, tenantId) {
  auth.tenantId = tenantId
  return auth.sendPasswordResetEmail(email)
}

function reauthenticateUser(currentPassword) {
  let user = firebase.auth().currentUser;
  let cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
}

function changePassword(currentPassword, newPassword, setError, setSuccess) {
  reauthenticateUser(currentPassword).then(() => {
    let user = firebase.auth().currentUser;
    user.updatePassword(newPassword).then(() => {
      setSuccess({ successMessage: 'Password updated!' })
    }).catch((error) => {
      setError({ passwordError: error?.message })
    });
  }).catch((error) => {
    setError({ passwordError: error?.message })
  });
}


export {
  auth,
  signup,
  login,
  loginWithGoogle,
  loginWithGithub,
  logout,
  resetPassword,
  changePassword
}