import { buildGQLDispatchConfig } from './util';

const api_url = process.env.REACT_APP_API_URL

const fetchCurrentCohorts = async (learnerId) => {
    const query = `{
        allLearnercohortstatusesList (condition: {learnerId: ${learnerId}}) {
          courseCode
          learnerId
          learnerStatus
          cohortStatus
          materialsStatus
          cohortdates {
            dateArchived
            dateAvailable
            dateStart
            dateEnd
            enrollmentStart
            enrollmentEnd
          }
          enrollment {
            cohort {
              id
              dateStart
              dateEnd
              externalDelivery
              coursePurchase {
                course {
                  id
                  name
                  courseCode
                  courseSlug
                  tags
                  hours
                  courseStructure
                  partnership {
                    id
                    name
                    color
                    logo
                    orientationDocs
                  }
                }
                path {
                  name
                  pathwaySlug
                  pathwayCode
                }
              }
            }
          }
        }
      }`
    
    const configObj = buildGQLDispatchConfig(query);
    try {
      const resp = await fetch(api_url, configObj)
      const json = await resp.json()
      return json.data.allLearnercohortstatusesList
    } catch (err) {
      console.log('Could not retrieve valid enrollment information', err)
    }
  }

export {
    fetchCurrentCohorts
}