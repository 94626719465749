import React, { useState } from 'react' 
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { updateCartContent, updateSnackbarData, updateCourseRegistrations, updatePathwayRegistrations, updateCourseRequests } from '../../redux/actions'
import { createCourseVote, createEnrollment, fetchInvoiceUrl, fetchPaymentUrl, nextEnrollableCohort, futureAvailableCohort, getEnrollableCohortsFromPathway } from '../../lib/registrationRequest';
import { COHORT_STATUSES, INITIAL_SELECTED_COURSE, SELECTION_TYPES } from '../../lib/globalKeys';
import { formattedDate } from '../../lib/date';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EnrollmentPrice from '../course_catalog/EnrollmentPrice';
import DaysLeftIndicator from '../course_state/DaysLeftIndicator';
import PriceWrapper from '../course_state/PriceWrapper';
import TextIconIndicator from '../course_state/TextIconIndicator';

const useStyles = makeStyles((theme) => ({
    drawerFooter: {
        height: "8rem",
        padding: "1.5rem 2rem",
        borderTop: `4px solid ${theme.palette.primary[50]}`,
    },
    centered: {
        display: 'flex',
        alignItems: 'center',
    }
  }));

function LearnerDrawerCta({setDrawerOpen, selection, setSelection, selectionType}) {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const classes = useStyles()
    const learnerInformation = useSelector(state => state.learnerInformation)
    const [loading, setLoading] = useState(false);
    const requestedCourses = useSelector(state => state.requestedCourses)
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const registeredPathways = useSelector(state => state.registeredPathways)
    const currentOrg = useSelector(state => state.currentOrg)
    const foundUser = useSelector(state => state.foundUser)
    const cartContent = useSelector(state => state.cartContent)
    const learnerPaths = useSelector(state => state.learnerPaths)
    const allCohorts = useSelector(state => state.cohorts)

    /**
     * Enroll into a cohort or Request access to a course for the current user.
     *
     * @param {Object} learnerinfo Details about the learner.
     * @param {CourseCatalogEntry} Selection Course or Pathway which the learner should enroll or request
     */
    async function submitCourseRegistration(learnerinfo, course) {
        // Enroll in a cohort if there are any enrollable cohorts
        const cohort = nextEnrollableCohort(course)
        const result = await createEnrollment(learnerinfo, cohort.id)
        if (result && result.length > 0) {
            dispatch(updateSnackbarData(<>You've successfully registered for <strong>{course.name}</strong></>, true, 'success'))
            // Add registration to store
            const updatedRegistrations = [...registeredCohorts, {
                "id": cohort?.id,
                "deliveryId": cohort?.deliveryId,
                "deliveryLinks": cohort?.deliveryLinks,
                "liveSessionLink": cohort?.liveSessionLink,
                "cohortSessionsByCohortIdList": cohort?.cohortSessionsByCohortIdList,
                "coursePurchase": {
                    "course": {
                        "name": course?.name,
                        "description": course?.description,
                        "tags": course?.tags,
                        "prerequisitesDescription": course?.prerequisitesDescription,
                        "softwareRequirements": course?.softwareRequirements
                    }
                }
            }]
            dispatch(updateCourseRegistrations(updatedRegistrations))
        } else {
            dispatch(updateSnackbarData(<>We encountered a problem registering for <strong>{course.name}</strong></>, true, 'error'))
        }
        
    }

    async function submitPathwayRegistration(learnerinfo, path) {
        console.log('registering for all without paying')

        // Map courses to open cohortIds
        // NOTE that this filters missing enrollable cohorts.
        const coursesAndNextCohorts = getEnrollableCohortsFromPathway(path)

        // Map tuples to just cohortIds
        const enrollableCohortIds = coursesAndNextCohorts.map( courseAndCohort => courseAndCohort.cohort.id)

        // handle enroll submission

        const result = await createEnrollment(learnerInformation, enrollableCohortIds, path.id)
        if (result && result.length > 0) {

            dispatch(updateSnackbarData(<>You've successfully registered for <strong>{path.name}</strong></>, true, 'success'))

            // update registered pathways
            const updatedPathwayRegistrations = [...registeredPathways, path.name]
            dispatch(updatePathwayRegistrations(updatedPathwayRegistrations))

            // update registered courses
            const updatedRegistrations = [...registeredCohorts, ...coursesAndNextCohorts.map(courseAndNextCohort => {
                    
                return {
                    "id": courseAndNextCohort?.cohort?.id,
                    "deliveryId": courseAndNextCohort?.cohort?.deliveryId,
                    "deliveryLinks": courseAndNextCohort?.cohort?.deliveryLinks,
                    "liveSessionLink": courseAndNextCohort?.cohort?.liveSessionLink,
                    "cohortSessionsByCohortIdList": courseAndNextCohort?.cohort?.cohortSessionsByCohortIdList,
                    "coursePurchase": {
                        "course": {
                            "name": courseAndNextCohort?.cohort?.coursePurchase?.course?.name,
                            "description": courseAndNextCohort?.cohort?.coursePurchase?.course?.description,
                            "tags": courseAndNextCohort?.cohort?.coursePurchase?.course?.tags,
                            "prerequisitesDescription": courseAndNextCohort?.cohort?.coursePurchase?.course?.prerequisitesDescription,
                            "softwareRequirements": courseAndNextCohort?.cohort?.coursePurchase?.course?.softwareRequirements
                        }
                    }
                }
            })]
            dispatch(updateCourseRegistrations(updatedRegistrations))
        }
        else {
            dispatch(updateSnackbarData(<>We encountered a problem registering for <strong>{path.name}</strong></>, true, 'error'))
        }

    }

    async function submitUserRequest(learnerinfo, course, requestType) {
        try {
            const requestedCourseVote = await createCourseVote(learnerinfo, course.id)
            if(requestType==="REQUEST"){
                dispatch(updateSnackbarData(<>You've successfully requested access to <strong>{course.name}</strong></>, true, 'success'))
            }
            if(requestType==="WAITLIST"){
                dispatch(updateSnackbarData(<>You've successfully joined the waitlist for <strong>{course.name}</strong></>, true, 'success'))
            }
            const updatedCourseRequests = [...requestedCourses, course.name]
            dispatch(updateCourseRequests(updatedCourseRequests))
            return requestedCourseVote
        } catch(courseVoteError) {
            // TODO: Need to add error handling!
            console.error(`Error requesting course ${course.name}`, courseVoteError)
            dispatch(updateSnackbarData(<>We encountered a problem registering for <strong>{course.name}</strong></>, true, 'error'))
        }
    }

    function handleClickLogin(){
        navigate(location.pathname+"?login=true")
    }

    async function handleClickRegisterAndPay(){
        const message = selectionType === SELECTION_TYPES.COURSE ? 'register & pay' : 'registering for all and paying'
        console.log('learnerDrawerCta', message)
        
        const successUrl = window.location.origin+"/successful-payment"
        const currentUrl = window.location.href

        // TODO: Figure out how best to incoporate unit
        // Redirect to payment URL
        const {firstName, lastName, email, orgUnitName} = learnerInformation
        const fullName = firstName + " " + lastName

        const stripePackage = [selection.name, selection.description, selectionType, currentUrl, successUrl, fullName, email, orgUnitName, selection.id]
        console.log('stripePackage', stripePackage)
        const paymentUrlResponse = await fetchPaymentUrl(...stripePackage)
        
        // Checking to see if stripe is returning errors
        if(paymentUrlResponse.errors || !paymentUrlResponse.data.StripeCheckout){
            dispatch(updateSnackbarData("A checkout session could not be generated. Please contact an administrator.", true, 'error'))
            console.log('paymentUrlResponse', paymentUrlResponse)
        } else {
            const paymentUrl = paymentUrlResponse.data.StripeCheckout.session_url
            window.location.href = paymentUrl
        }
    }

    async function handleClickDownloadInvoice(){
        console.log('download invoice')
        
        setLoading(true)
            const invoiceUrlResponse = await fetchInvoiceUrl(selection, learnerInformation, selectionType)

            // Checking to see if stripe is returning errors
            if(invoiceUrlResponse.errors || !invoiceUrlResponse.data.StripeInvoice){
                dispatch(updateSnackbarData("An invoice could not be generated. Please contact an administrator.", true, 'error'))
            } else {
                const invoiceUrl = invoiceUrlResponse.data.StripeInvoice?.invoice_pdf
                // TODO: Refactor so that backend forward stripe errors from earlier on in resolver
                if(invoiceUrl){
                    window.location.href=invoiceUrl
                }else{
                    dispatch(updateSnackbarData("An invoice could not be generated. Please contact an administrator.", true, 'error'))
                }
            }
            setLoading(false)
    }
    
    function handleClickRegister(){
        const message = selectionType === SELECTION_TYPES.COURSE ? 'register' : 'registering for all without paying'
        console.log(message)

        // handle enroll submission
        if (selectionType === SELECTION_TYPES.COURSE) {
            submitCourseRegistration(learnerInformation, selection)
        } else {
            submitPathwayRegistration(learnerInformation, selection)
        }


        // update state
        setDrawerOpen(false)
        setSelection(INITIAL_SELECTED_COURSE)
    }

    function handleClickRequestOrWaitlist(requestType){
        console.log('request')

        // handle request submission
        submitUserRequest(learnerInformation, selection, requestType)

        // update state
        setDrawerOpen(false)
        setSelection(INITIAL_SELECTED_COURSE)
    }

    const addCourseToCart = (course) => {
        let updatedCartContent
        let courseArray = []
        if(cartContent[course.pathwayCode]){
            courseArray = cartContent[course.pathwayCode]
        }
        courseArray.push(course.courseCode)
        updatedCartContent = {...cartContent,  [course.pathwayCode]: courseArray }  
        dispatch(updateCartContent(updatedCartContent))
        const stringifiedCartContent=JSON.stringify(updatedCartContent)
        localStorage.setItem('cartContent', stringifiedCartContent)
        setDrawerOpen(false)
    }
    
    const addPathwayToCart = (pathway) => {
        let updatedCartContent
        let courseArray = []
        const pathwayObj = learnerPaths.find(path => pathway.pathwayCode === path.pathwayCode)
        if(pathwayObj){
            const courseSegments = pathwayObj.learnerPathSegmentsByPathIdList
            courseArray = courseSegments.map(cs => {
                return cs.course.courseCode
            })
            if(courseArray?.length < 1){
                console.log('Error: No courses found')
                // TODO: Throw error notification
            } else {
                updatedCartContent = {...cartContent,  [pathway.pathwayCode]: courseArray }  
                dispatch(updateCartContent(updatedCartContent))
                const stringifiedCartContent=JSON.stringify(updatedCartContent)
                localStorage.setItem('cartContent', stringifiedCartContent)
            }
        } else {
            console.log('Error: Pathway not found')
            // TODO: Throw error notification
        }   
        setDrawerOpen(false)
    }

    function handleOnClickAddToCart() {
        if (selectionType === SELECTION_TYPES.COURSE) {
            addCourseToCart(selection)
        } else {
            addPathwayToCart(selection)
        }
    }

    const selectionInCart = () => {
        if(selectionType === SELECTION_TYPES.COURSE){
            // checks to see if the cart contains anything from the course's pathway
            if(cartContent[selection.pathwayCode]){
                return cartContent[selection.pathwayCode].includes(selection.courseCode)
            }
        } else {
            // checks to see if the cart contains the pathway
            if(cartContent[selection.pathwayCode]){
                const pathwayObj = learnerPaths.find(path => selection.pathwayCode === path.pathwayCode)
                return pathwayObj.learnerPathSegmentsByPathIdList?.length === cartContent[selection.pathwayCode]?.length
            } else {
                return false
            }
        }
    }

    function mappedFooter(allCohorts) {
        const registeredCohort = registeredCohorts.find(cohort => cohort.coursePurchase.course.name === selection.name)
        const requested = requestedCourses.includes(selection.name)
        let foundAvailableCohort
        if(selectionType === SELECTION_TYPES.COURSE) {
            foundAvailableCohort = nextEnrollableCohort(selection)
        }
        const availableEnrollableCohort = allCohorts?.find(cohort => foundAvailableCohort?.id === cohort.id)
        const isAvailableCohortFull = !!(parseInt(availableEnrollableCohort?.statusDetails.seatsRemaining) <= 0)
        const willBeEnrollable = selectionType === SELECTION_TYPES.COURSE && futureAvailableCohort(selection)
        const loginEnabled = currentOrg.featLogin
        const paymentEnabled = currentOrg.featPayment
        const enrollmentEnabled = currentOrg.featCohortEnrollment
        // removed pathwayEnrollment functionality from learner drawer
        // const pathwayEnrollmentEnabled = currentOrg.featPathwayEnrollment
        const coursePurchaseExists = selectionType === SELECTION_TYPES.COURSE && selection.coursePurchasesByCourseIdList?.length > 0
        const coursePurchase = coursePurchaseExists ? selection.coursePurchasesByCourseIdList[0] : null
        // TODO: need to check course purchase hasn't ended in original fetch
        const requestEnabled = currentOrg.featCohortRequest
        const loggedIn = !!foundUser.email
        const updatedRegisteredCohort = allCohorts?.find(cohort => cohort.id === registeredCohort?.id)

        function FooterTypography(props) {
            return <Typography align="right" variant="subtitle1" style={{fontWeight:"bold"}} color="primary" {...props}/>
        }

        function PublicCartFooter() {
            if (selectionInCart()) {
                return(
                    <Grid item>
                        <TextIconIndicator text='Added to Cart' color="green" icon="check"/>
                    </Grid>
                )
            } 
            return (
                <Grid item>
                    <Button 
                        variant="contained"
                        color="secondary"
                        onClick={handleOnClickAddToCart}>
                        Add to Cart
                    </Button>
                </Grid>
            )
        }

        function LogInToRegister() {
            return (
                <PriceWrapper coursePurchase={coursePurchase}>
                    <Button 
                        variant="contained" 
                        color="secondary"  
                        onClick={handleClickLogin}
                    >
                        Log in to Register
                    </Button>
                </PriceWrapper>
            )
        }

        function LogInToRequest() {
            return selectionType === SELECTION_TYPES.COURSE && (
                <Button 
                    variant="contained" 
                    color="secondary"  
                    onClick={handleClickLogin}
                >
                    Log in to Request
                </Button>
            )
        }

        function LogInToJoinWaitlist() {
            return (
                <PriceWrapper coursePurchase={coursePurchase}>
                    <Button 
                        variant="contained" 
                        color="secondary"  
                        onClick={handleClickLogin}
                    >
                        Log in to Join Waitlist
                    </Button>
                </PriceWrapper>
            )
        }

        function RegisterAndPay() {
            return (
                <>
                    <Grid item xs={12} container spacing={2} justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <DaysLeftIndicator course = {selection} />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" fullWidth name="register_pay" type="submit" onClick={handleClickRegisterAndPay}>
                                Register & Pay:
                                &nbsp;
                                <EnrollmentPrice coursePurchase={coursePurchase} styled={false}/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="text" 
                            color="secondary" 
                            name="download_invoice" 
                            startIcon={loading ? <CircularProgress size={20} color="secondary" /> : <FileDownloadOutlinedIcon />}
                            disabled={loading}
                            onClick={handleClickDownloadInvoice}
                        >
                            { loading ? 'Downloading' : 'Download a Printable Invoice' }
                        </Button>
                    </Grid>
                </>
            )
        }

        function RegisterWithoutPay(){
            return (
                <Grid item xs={12} container spacing={2} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <DaysLeftIndicator course = {selection} />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" fullWidth name="register" onClick={handleClickRegister}>
                            Register
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        function Request(){
            return selectionType === SELECTION_TYPES.COURSE && (
                <Grid item>
                    <Button variant="contained" color="secondary" fullWidth name="request" onClick={() => handleClickRequestOrWaitlist("REQUEST")}>
                        Request
                    </Button>
                </Grid>
            )
        }

        function Requested(){
            return (
                <Grid item>
                    <TextIconIndicator text='Requested' color="green" icon="check"/>
                </Grid>
            )
        }

        function Registered() {
            return (
                <Grid item xs={12} container spacing={2} justifyContent='flex-end' alignItems='center'> 
                    <Grid item>
                        <DaysLeftIndicator course = {selection} />
                    </Grid>
                    <Grid item>
                        <TextIconIndicator text='Registered' color="green" icon="check"/>
                    </Grid>
                </Grid>
            )
        }

        function RegisteredAndCompleted() {
            return (
                <Grid item>
                    <TextIconIndicator text='Completed' color="green" icon="check"/>
                </Grid>
            )
        }


        function OnTheWaitlist(){
            return (
                <Grid item>
                    <PriceWrapper coursePurchase={coursePurchase}>
                        <TextIconIndicator text='On the Waitlist' color="green" icon="check"/>
                    </PriceWrapper>
                </Grid>
            )
        }

        function WillBeOpenForRegistration(){
            return selectionType === SELECTION_TYPES.COURSE && (
                <PriceWrapper coursePurchase={coursePurchase}>
                    <FooterTypography>Registration will open on {formattedDate(willBeEnrollable.enrollmentStart)}.</FooterTypography>
                </PriceWrapper>
            )
        }

        function JoinWaitlist(){
            return (
                <>
                    <Grid item>
                        <Box 
                            style={{
                                display: "flex", 
                                flexDirection:"column", 
                                alignItems: "center", 
                                gap:"0.25rem"
                            }}
                        >
                            <Button 
                                variant="contained" 
                                color="secondary"  
                                name="register" 
                                onClick={() => handleClickRequestOrWaitlist("WAITLIST")}
                                style={{position:"relative"}}
                            >
                                Join the Waitlist
                            </Button>
                            <Typography align="right" variant="caption" style={{color: "#7D7F84"}}>
                                Price on registation:  <EnrollmentPrice coursePurchase={coursePurchase} styled={false} />
                            </Typography>
                        </Box>
                    </Grid>
                </>
            )
        }
        //NOTE: NOT CURRENTLY SUPPORTING PATHWAY ENROLLMENT
        if(loginEnabled){
            if(!enrollmentEnabled){
                return ''
            } else {
                if(!loggedIn){
                    if(availableEnrollableCohort && !isAvailableCohortFull){
                        // not logged in, enrollmentEnabled and available cohort not full -> log in to register
                        return <LogInToRegister />
                    }
                    if(willBeEnrollable){
                        // not logged in and future enrollable cohort -> registration will open...
                        return <WillBeOpenForRegistration />
                    }
                    if(coursePurchaseExists){
                        // not logged in and course purchase available, but no open or future cohort -> join waitlist
                        return <LogInToJoinWaitlist />
                    } else {
                        if(requestEnabled){
                            // not logged in, no course purchase, and request enabled -> log in to request
                            return <LogInToRequest />
                        }
                    }
                }
                if(loggedIn){
                    if(registeredCohort){
                        if(updatedRegisteredCohort?.status===COHORT_STATUSES.COMPLETED){
                            return <RegisteredAndCompleted />
                        } else {
                            return <Registered />
                        }
                    }
                    if(availableEnrollableCohort && !isAvailableCohortFull){
                        if(paymentEnabled){
                            // logged in, available cohort not full, payment enabled -> register and pay, invoice
                            return <RegisterAndPay />
                        } else {
                            // logged in and available cohort not full, payment not enabled -> register
                            return <RegisterWithoutPay />
                        }
                    }
                    if(willBeEnrollable){
                        // logged in and future enrollable cohort -> registration will open...
                        return <WillBeOpenForRegistration />
                    }
                    if(coursePurchaseExists){
                        if(requested){
                            // logged in, course purchase available, no open or future cohort, already requested/waitlisted -> on the waitlist
                            return <OnTheWaitlist />
                        } else {
                            // logged in, course purchase available, no open or future cohort, not already requested/waitlisted -> join waitlist
                            return <JoinWaitlist />
                        }
                    } else {
                        if(requestEnabled){
                            if(requested){
                                // logged in, no course purchase, request enabled, and already requested -> requested
                                return <Requested />
                            } else {
                                // logged in, no course purchase, request enabled, and not already requested -> request
                                return <Request />
                            }
                        }
                    }
                }
            }

        } else {
            // login not enabled -> contact sales
             return <PublicCartFooter />
        }
        // default return is empty
        return ''
    }


    return(
        <div className = {classes.drawerFooter}>
            {
                <Grid container spacing={1} justifyContent='flex-end'>
                    {mappedFooter(allCohorts)}
                </Grid>
            }
        </div>
    )
}

export default LearnerDrawerCta