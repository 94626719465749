import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { pathwayColorMap } from '../../assets/colors'
import RoadToMordR from './RoadToMordR'
import { VIEW_TYPES, INITIAL_SELECTED_COURSE, SELECTION_TYPES } from '../../lib/globalKeys'
import { fetchLearnerPathsForSupervisors } from '../../redux/actions'

// Keys that are parsed from the ID
const mapOjectKeys = {
    objectType: "-ot-",
    courseCodes: "-cc-",
    pathwayCodes: "-pc-",
    certificateCodes: "-crt-"
}

// Given an event and/or an id, this function grabs the id to be passed to other functions
function getId(passedEvent, passedId){
    if(passedId){
        return passedId
    }
    let mapElementId = ''
    const targetId = passedEvent.target.id
    // if no passedId, checks if target id includes an identifier injected in XD. If not, checks parent id
    if(targetId.includes(mapOjectKeys['objectType'])){
        mapElementId = targetId
    } else {
        const parentId = passedEvent.target.parentElement.id
        if(parentId.includes(mapOjectKeys['objectType'])){
            mapElementId = parentId
        } else {
            console.log('No mapped id found at ', targetId, passedEvent)
        }
    }
    return mapElementId
}

// Given an id and key, parses the id to return object type, courseCode, etc...
function parseId(idString, idKey){
    if(idString.includes(idKey)){
        return idString.split(idKey)[1]
    } else {
        console.log(`Key ${idKey} not found`)
    }
}

function PathwaysMapSVGContainer({ drawerOpen, setDrawerOpen, setSelection, setSelectionType }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathName, courseName } = useParams()
    const learnerPaths = useSelector(state => state.learnerPaths)
    const allCourses = useSelector(state => state.allCourses)
    const [activeCourseObj, setActiveCourseObj] = useState({})
    const [activePathwayObj, setActivePathwayObj] = useState({})
    const [hoverCourseObj, setHoverCourseObj] = useState({})
    const [hoverPathwayRegionObj, setHoverPathwayRegionObj] = useState({})
    const [hoverPathwayNodeObj, setHoverPathwayNodeObj] = useState({})
    const orgId = useSelector(state => state.currentOrg?.id)
    const foundUser = useSelector(state => state.foundUser)
    const recentHoveredPathway = useRef(null)
    const [isSupervisorView, setIsSupervisorView] = useState(false)

    // TODO: May want to refactor how learnerPaths are store for supervisors/learners
    useEffect(() => {
        if(foundUser.role === VIEW_TYPES.SUPERVISOR){
          dispatch(fetchLearnerPathsForSupervisors(orgId))
        }
    }, [orgId, dispatch, foundUser])

    useEffect(() => {
        setIsSupervisorView(location.pathname.includes("/admin/"))
    }, [location.pathname])

    function matchUrlPath(pathSlug) {
        const foundPath = learnerPaths.find(path => {
            return path.pathwaySlug===pathSlug
        })
        return foundPath
    } 

    function matchUrlCourse(courseSlug, foundPathName) {
        const foundCourse = allCourses.find(course => {
            return course.courseSlug===courseSlug && course.pathName===foundPathName
        })
        return foundCourse
    }

    useEffect(()=>{
        if(allCourses.length>0){
            const foundPath = matchUrlPath(pathName)
            if(foundPath){
                if(courseName && learnerPaths.length > 0){
                    const foundCourse = matchUrlCourse(courseName, foundPath.name)
                    if(foundCourse){
                        setSelection(foundCourse)
                        setDrawerOpen(true)
                        setSelectionType(SELECTION_TYPES.COURSE)
                        let courseNodeObj = {courseCode: foundCourse.courseCode, pathwayCode: foundCourse.pathwayCode}
                        setActiveCourseObj(courseNodeObj)
                    } else {
                        alert(`Sorry, ${courseName} could not be located`)
                    }
                } else {
                    setSelection(foundPath)
                    setDrawerOpen(true)
                    setSelectionType(SELECTION_TYPES.PATHWAY)
                    setActivePathwayObj({pathwayCode: foundPath.pathwayCode})
                }
            } 
        }
        // eslint-disable-next-line
    }, [allCourses])

    // On close of the drawer, sets active course/path to empty object
    useEffect(() => {
        if(!drawerOpen){
            setActiveCourseObj({})
            setActivePathwayObj({})
        }
    }, [drawerOpen])
    
    const useStyles = makeStyles((theme) => ({
        // classNames are dynamically generated/mapped below, which add visually reference various over/selected states
        clickable:{
            cursor: 'pointer'
        },
        courseNodeHover: {
            filter: `drop-shadow(0px 0px 8px rgb(${pathwayColorMap[hoverCourseObj.pathwayCode]?.rgbValues}, .32))`,
            stroke: pathwayColorMap[hoverCourseObj.pathwayCode]?.hex
        },
        courseNodeActive: {
            filter: `drop-shadow(0px 0px 12px rgb(${pathwayColorMap[activeCourseObj.pathwayCode]?.rgbValues || pathwayColorMap[activePathwayObj.pathwayCode]?.rgbValues}, .56))`,
            stroke: pathwayColorMap[activeCourseObj.pathwayCode]?.hex || pathwayColorMap[activePathwayObj.pathwayCode]?.hex,
        },
        courseNodeActiveIndicator: {
            fill: pathwayColorMap[activeCourseObj.pathwayCode]?.hex || pathwayColorMap[activePathwayObj.pathwayCode]?.hex,
        },
        pathwayRegionInteriorHover: {
            fill: pathwayColorMap[hoverPathwayRegionObj.pathwayCode]?.hex,
            opacity: '4%'
        },
        pathwayRegionBorderHover: {
            fill: pathwayColorMap[hoverPathwayRegionObj.pathwayCode]?.hex,
            opacity: '24%'
        },
        pathwayNodeIconActive: {
            fill: '#ffffff'
        },
        pathwayNodeBackgroundActive: {
            filter: `drop-shadow(0px 0px 12px rgb(${pathwayColorMap[activePathwayObj.pathwayCode]?.rgbValues}, .56))`,
            fill: pathwayColorMap[activePathwayObj.pathwayCode]?.hex
        },
        pathwayNodeBackgroundHover: {
            filter: `drop-shadow(0px 0px 8px rgb(${pathwayColorMap[hoverPathwayNodeObj.pathwayCode]?.rgbValues}, .32))`,
        },
        pathwaySegmentActive: {
            stroke: pathwayColorMap[activePathwayObj.pathwayCode]?.hex,
        },
      }));

    const classes = useStyles()

    const mappedClass = (idString) =>{
        let classArray = []
        let clickableObjects = ['courseNode', 'courseLabel', 'courseNodeActive', 'pathwayNode', 'pathwayLabel']
        const objectType = parseId(idString, mapOjectKeys['objectType'])
        // adds clickable class to all clickableObjects assigned above
        if (clickableObjects.includes(objectType)){
            classArray.push(classes.clickable)
        }
        if (objectType === 'courseNode'){
            const courseCode = parseId(idString, mapOjectKeys['courseCodes'])
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // courseNode appears active if:
            // 1. courseCode matches the active courseCode
            // 2. the courseNode's pathway matches the active pathwayCode
            // 3. the courseNode's pathway code includes the active pathway Code and '-and' (indicating it belongs to more than one pathway)
            if(activeCourseObj.courseCode === courseCode || activePathwayObj.pathwayCode===pathwayCode || (pathwayCode.includes(activePathwayObj.pathwayCode) && (pathwayCode.includes('-and')))){
                classArray.push(classes['courseNodeActive'])
            // courseNode appears hovered if the courseCode matches the hover courseCode
            } else if(hoverCourseObj.courseCode === courseCode){
                classArray.push(classes['courseNodeHover'])
            }
        }
        if (objectType === 'courseNodeActive'){
            const courseCode = parseId(idString, mapOjectKeys['courseCodes'])
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // courseNodeActive appears active if:
            // 1. courseCode matches the active courseCode
            // 2. the courseNode's pathway matches the active pathwayCode
            // 3. the courseNode's pathway code includes the active pathway Code and '-and' (indicating it belongs to more than one pathway)
            if(activeCourseObj.courseCode === courseCode || activePathwayObj.pathwayCode===pathwayCode || (pathwayCode.includes(activePathwayObj.pathwayCode) && (pathwayCode.includes('-and')))){
                classArray.push(classes['courseNodeActiveIndicator'])
            } 
        }
        if (objectType === 'pathwayRegionInterior'){
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // pathwayRegionInterior appears hovered if the pathwayCode matches the hover pathwayCode
            if(hoverPathwayRegionObj.pathwayCode === pathwayCode){
                classArray.push(classes['pathwayRegionInteriorHover'])
            }
        }
        if (objectType === 'pathwayRegionBorder'){
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // pathwayRegionBorder appears hovered if the pathwayCode matches the hover pathwayCode
            if(hoverPathwayRegionObj.pathwayCode === pathwayCode){
                classArray.push(classes['pathwayRegionBorderHover'])
          }
        }
        if (objectType === 'pathwayNodeBackground'){
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // pathwayNodeBackground appears active if the pathwayCode matches the active pathwayCode
            if(activePathwayObj.pathwayCode === pathwayCode){
                classArray.push(classes['pathwayNodeBackgroundActive'])
            // pathwayNodeBackground appears hovered if the pathwayCode matches the hover pathwayCode
            } else if(hoverPathwayNodeObj.pathwayCode === pathwayCode){
                classArray.push(classes['pathwayNodeBackgroundHover'])
            } 
        }
        if (objectType === 'pathwayNodeIcon'){
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // pathwayNodeIcon appears active if the pathwayCode matches the active pathwayCode
            if(activePathwayObj.pathwayCode === pathwayCode){
                classArray.push(classes['pathwayNodeIconActive'])
            } 
        }
        if (objectType === 'pathwaySegment'){
            const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
            // pathwaySegment appears active if:
            // 1. the courseNode's pathway matches the active pathwayCode
            // 2. the courseNode's pathway code includes the active pathway Code and '-and' (indicating it belongs to more than one pathway)
            if(activePathwayObj.pathwayCode===pathwayCode || (pathwayCode.includes(activePathwayObj.pathwayCode) && (pathwayCode.includes('-and')))){
                classArray.push(classes['pathwaySegmentActive'])
            }
        }
        return classArray.join(' + ')
    }

    // maps to different onMouseOut functions depending on object type
    function mappedMouseOut(event, passedId) {
        const idString = passedId ? passedId : getId(event)
        const objectType = parseId(idString, mapOjectKeys['objectType'])
        switch(objectType){
            case 'courseNode':
                return handleMouseOutCourse()
            case 'courseLabel':
                return handleMouseOutCourse()
            case 'courseNodeActive':
                return handleMouseOutCourse()
            case 'pathwayRegionInterior':
                return handleMouseOutPathwayRegion()
            case 'pathwaySegment':
                return handleMouseOutPathwayRegion()
            case 'pathwayNode': 
                return handleMouseOutPathway()
            case 'pathwayLabel': 
                return handleMouseOutPathway()
            default: 
                return
        }
    }

    // maps to different onMouseOver functions depending on object type
    function mappedMouseOver(event, passedId) {
      const idString = passedId ? passedId : getId(event)
      const objectType = parseId(idString, mapOjectKeys['objectType'])
      switch(objectType){
          case 'courseNode':
              return handleMouseOverCourse(idString)
          case 'courseLabel':
              return handleMouseOverCourse(idString)
          case 'courseNodeActive':
              return handleMouseOverCourse(idString)
          case 'pathwayRegionInterior':
              return handleMouseOverPathwayRegion(idString)
          case 'pathwaySegment':
              return handleMouseOverPathwaySegment()
          case 'pathwayNode':
              return handleMouseOverPathwayNode(idString)
          case 'pathwayLabel':
              return handleMouseOverPathwayNode(idString)
          default: 
              return
      }
    }

    // maps to different onClick functions depending on object type
    function mappedClick(event, passedId){
      const idString = passedId ? passedId : getId(event)
        const objectType = parseId(idString, mapOjectKeys['objectType'])
        switch(objectType){
            case 'courseNode':
                return handleClickCourse(idString)
            case 'courseLabel':
                return handleClickCourse(idString)
            case 'courseNodeActive':
                return handleClickCourse(idString)
            case 'pathwayNode':
                return handleClickPathway(idString)
            case 'pathwayLabel':
                return handleClickPathway(idString)
            default: 
                return
        }
    }

    function handleClickCourse(idString) {
        const courseCode = parseId(idString, mapOjectKeys['courseCodes'])
        let pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
        let courseNodeObj = {courseCode, pathwayCode}
        const prefix = isSupervisorView ? '/admin': ''
        // if the course is already active and it's pathwayCode was also active, reclicking clears the selection
        // this accounts for courses that are in more than one pathway
        if (activeCourseObj.courseCode === courseCode && pathwayCode.includes(activeCourseObj.pathwayCode)){
            setActiveCourseObj({})
            setDrawerOpen(false)
            setSelection(INITIAL_SELECTED_COURSE)
            setSelectionType('')
            navigate(`${prefix}/pathways`)
        } else {
            // this keeps track of which pathway the mouse was recently hovering over and 
            // selects that version of the course (accounts for if a course is in two pathways)
            // the course is considered selected and the courses are iterated through to pass data
            // to the course drawer
            if(pathwayCode.includes(recentHoveredPathway.current)){
                pathwayCode = recentHoveredPathway.current
                courseNodeObj = {courseCode, pathwayCode}
            }
            setActiveCourseObj(courseNodeObj)
            setActivePathwayObj({})
            let course = INITIAL_SELECTED_COURSE
            learnerPaths.forEach( path => {
              const pathSegments = path.learnerPathSegmentsByPathIdList
              pathSegments.forEach(segment => {
                if(segment.course.courseCode === courseCode && segment.course.pathwayCode === pathwayCode){
                  course = segment.course
                }
              })
            })
            setSelection(course)
            setSelectionType(SELECTION_TYPES.COURSE)
            setDrawerOpen(true)
            navigate(`${prefix}/pathways/${course.pathwaySlug}/${course.courseSlug}`)
        }
    }

    function handleClickPathway(idString) {
        const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
        const prefix = isSupervisorView ? '/admin': ''
        // if the pathway is already active, reclicking clears the selection
        if (activePathwayObj.pathwayCode === pathwayCode){
            setActivePathwayObj({})
            setDrawerOpen(false)
            setSelection(INITIAL_SELECTED_COURSE)
            setSelectionType('')
            navigate(`${prefix}/pathways`)
        // otherwise, the pathwas is considered selected and the paths are iterated through
        // to pass the data to the drawer
        } else {
            setActivePathwayObj({pathwayCode})
            setActiveCourseObj({})
            const path = learnerPaths.find( path => path.pathwayCode === pathwayCode )
            setSelection(path)
            setSelectionType(SELECTION_TYPES.PATHWAY)
            setDrawerOpen(true)
            navigate(`${prefix}/pathways/${path.pathwaySlug}`)
        }
    }

    // onMouseOver, applies hover effects
    // this keeps track of which pathway the mouse was recently hovering over and 
    // selects that version of the course (if a course is in two pathways)
    function handleMouseOverCourse(idString) {
        const courseCode = parseId(idString, mapOjectKeys['courseCodes'])
        const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
        let courseNodeObj = {courseCode, pathwayCode}
        if(pathwayCode.includes(recentHoveredPathway.current)){
            courseNodeObj = {courseCode, pathwayCode: recentHoveredPathway.current}
            setHoverCourseObj(courseNodeObj)
            setHoverPathwayRegionObj({pathwayCode: recentHoveredPathway.current})
        } else {
            setHoverPathwayRegionObj({pathwayCode})
            recentHoveredPathway.current=pathwayCode
        }
        setHoverCourseObj(courseNodeObj)
    }

    // onMouseOver, applies hover effects
    // this keeps track of which pathway the mouse was recently hovering over and 
    // uses that color for joint pathway regions
    function handleMouseOverPathwayRegion(idString) {
        const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
        if(pathwayCode.includes(recentHoveredPathway.current)){
            setHoverPathwayRegionObj({pathwayCode: recentHoveredPathway.current})
        } else {
            setHoverPathwayRegionObj({pathwayCode})
            recentHoveredPathway.current=pathwayCode
        }
    }

    // onMouseOver, applies hover effects
    function handleMouseOverPathwaySegment() {
        setHoverPathwayRegionObj({pathwayCode: recentHoveredPathway.current})
    }

    // onMouseOver, applies hover effects
    function handleMouseOverPathwayNode(idString) {
        const pathwayCode = parseId(idString, mapOjectKeys['pathwayCodes'])
        setHoverPathwayNodeObj({pathwayCode})
        setHoverPathwayRegionObj({pathwayCode})
    }

    // onMouseOut, removes hover effects
    function handleMouseOutCourse(){
        setHoverCourseObj({})
        setHoverPathwayRegionObj({})
    }

    // onMouseOut, removes hover effects
    function handleMouseOutPathway(){
        setHoverPathwayNodeObj({})
        setHoverPathwayRegionObj({})
    }

    // onMouseOut, removes hover effects
    function handleMouseOutPathwayRegion(){
        setHoverPathwayRegionObj({})
    }

    return(
        <RoadToMordR mappedMouseOver={mappedMouseOver} mappedMouseOut={mappedMouseOut} mappedClick={mappedClick} mappedClass={mappedClass} />
    )
}

export {
    getId,
    parseId
  }

export default PathwaysMapSVGContainer