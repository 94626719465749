import React from 'react'
import { Button } from '@material-ui/core'


const SideSubMenu = ({ subMenuArray, activeSubMenuObj, setActiveSubMenuObj }) => {
    return (
        <>
            {subMenuArray.map((subMenuObj, index) => {
                const { menu } = subMenuObj
                const activeBtnStyle = activeSubMenuObj.menu === menu ? { background: '#2C2F45', color: 'white' } : { color: 'black' }

                return <Button
                    onClick={() => setActiveSubMenuObj(subMenuObj)}
                    style={{
                        padding: '12px 16px',
                        width: '312px',
                        margin: '0',
                        display: 'flex',
                        justifyContent: "flex-start",
                        textTransform: 'capitalize',
                        fontWeight: 400,
                        fontSize: '1rem',
                        ...activeBtnStyle
                    }}
                    key={subMenuObj.menu + index}
                >
                    {subMenuObj?.menu}
                </Button>
            })}
        </>
    )
}

export default SideSubMenu