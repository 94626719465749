import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import leonError from'../assets/imgs/leon-error.png';

/**
 * Wrapper for error-page text
 */
class ErrorPageType {
    constructor(errorType, headline, tag, errorMessage, errorSubtitleText, leonText, onloadCallback=undefined){
        this.errorType = errorType                  // error code
        this.headline = headline                    // headline
        this.tag = tag                              // tag
        this.errorMessage = errorMessage            // subhead
        this.errorSubtitleText = errorSubtitleText  // copy
        this.leonText = leonText

        this.onloadCallback = onloadCallback
    }
}

/** Known error types */
const ERROR_TYPES = {
    add: function(error){
        this[error.errorType] = error
    }
}

// Build known error types
ERROR_TYPES.add(new ErrorPageType("404",
        "404 Error",
        "Page not found",
        "Something went wrong.",
        "Head back to the homepage.",
        ["I can't find that page.", "And it's not because of", "camouflage, either."]))

ERROR_TYPES.add(new ErrorPageType("403",
        "403 Error",
        "Unauthorized user",
        "Something went wrong.",
        "Log in or head back to the homepage.",
        ["Hmm... I don't think you're", "supposed to be here.", "I won't tell if you don't."],
        (location, navigate) => navigate(location.pathname+"?login=true")))

ERROR_TYPES.add(new ErrorPageType("Unknown",
        "Error?!",
        "Page not found",
        "How'd you get here?",
        "Head back to the homepage.",
        ["I can't come to", "the phone right now.", "Call back later."]))

function ErrorPage({errorType="404"}){
    // Defaults to errorType 404
    const error = ERROR_TYPES[errorType] ? ERROR_TYPES[errorType] : ERROR_TYPES["404"]

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if( error.onloadCallback ){
            error.onloadCallback(location, navigate)
        }

        // only want this to run on initial load
        // eslint-disable-next-line
    }, [])

    return(
        <Grid container style={{padding:"4rem 2rem"}} alignItems="center">
            <Grid item style={{padding:"4rem 3rem"}} xs={6}>
                <Paper elevation={0} style={{display:"inline-block", padding:"0.25rem 0.75rem"}}>
                    <Typography color="primary" variant="body1" style={{fontWeight:700}}>{error.tag}</Typography>
                </Paper>
                <Typography color="primary" style={{fontSize: "6rem", fontWeight:700}}>{error.headline}</Typography>
                <Typography color="primary" style={{fontSize: "3rem", fontWeight:200}}>{error.errorMessage}</Typography>
                <Typography color="primary" variant="h5">{error.errorSubtitleText}</Typography>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    style={{marginTop:"4rem"}}
                    href="/"
                >
                    Back to Homepage
                </Button>
            </Grid>
            <Grid item xs={6} style={{display:'flex', justifyContent:'center'}}>     
                <div id="image-and-message-container" style={{height:"32rem", position:"relative"}} >
                    <div id="message-container" style={{position:"absolute", left:'-3rem', top:'1.5rem'}}>
                        <div id="text-container" style={{position:'relative'}}>
                            {
                                error.leonText.map((text, index) => {
                                    return(
                                        <Typography key = {'leon-line-'+index} color="primary" variant="body1" align="center">
                                            {text}
                                        </Typography>
                                    )
                                })
                            }
                        </div>
                        <div 
                            style={{
                                borderBottom: "1px solid black", 
                                width: "10%", 
                                transform: "rotate(50deg)", 
                                transformOrigin: "top left",
                                position:'absolute',
                                right:'2rem',
                                bottom:'-0.5rem'
                            }} 
                        />
                    </div>
                    <img  src={leonError}  alt="Error Page" style={{height:"100%"}}/>
                </div>
            </Grid>
        </Grid>
    )
}

export default ErrorPage