import { buildGQLDispatchConfig } from './util';

const api_url = process.env.REACT_APP_API_URL

const fetchUsersListForSupervisor = async (id) => {
    const query = `{
      allUsersList( condition: {orgId: ${id}} ) {
        id
        firstName
        lastName
        jobTitle
        orgUnitName
        email
        phoneNumber
        openToMentorship
        role
        tags
        enrollmentsByLearnerIdList {
            cohort {
              id
              coursePurchase {
                course {
                  name
                }
              }
            }
        }
      }
    }`
  
    const configObj = buildGQLDispatchConfig(query);
  
    try {
        const resp = await fetch(api_url, configObj)
        const json = await resp.json()
        return json?.data?.allUsersList
    } catch (err) {
        console.log('Could not retrieve user information', err)
    }
}

const fetchAllCohortsStatusesList= async (orgId) => {
    const query = `{
      cohortStatuses: allCohortstatusesList(condition: {orgId:${orgId}}) {
        orgId
        courseCode
        cohortStatus
        enrollmentStatus
        materialsStatus
        cohortId
        seatsRemaining
        dates {
          dateAvailable
          dateArchived
          dateStart
          dateEnd
          enrollmentStart
          enrollmentEnd
        }
        cohort {
          id
          coursePurchaseId
          dateStart
          dateEnd
          enrollmentStart
          enrollmentEnd
          externalDelivery
          coursePurchase {
            path {
              name
              pathwaySlug
              pathwayCode
            }
            course {
              id
              name
              courseCode
              description
              courseSlug
              tags
              hours
              courseStructure
              partnership {
                id
                name
                color
                logo
                orientationDocs
              }
              coursePurchasesByCourseIdList(condition:{orgId:${orgId}}) {
                organization: org {
                  id
                  name
                }
                cohortsByCoursePurchaseIdList{
                  id
                  dateStart
                  dateEnd
                  enrollmentStart
                  enrollmentEnd
                }
              }
            }
          }
          enrollments: enrollmentsByCohortIdList {
            id
            learner {
              firstName
              lastName
              email
              orgUnitName
            }
            pathwayEnrollment {
              pathway {
                name
                pathwaySlug
                pathwayCode
              }
            }
          }
        }
      }
    }`
    const configObj = buildGQLDispatchConfig(query);
  
    try {
        const resp = await fetch(api_url, configObj)
        const json = await resp.json()
        const cohortStatuses = json.data.cohortStatuses
        const cohorts = cohortStatuses.map( cstat => {
            // Pull cohort out of stat and remove stat from hierarchy
            const cohort = cstat.cohort
            cstat.cohort = undefined

            // embed statusDetail object
            cohort.statusDetails = cstat

            // duplicate the 'cohortStatus' as 'status' to replace deprecated 'addCohortStatus' fn
            cohort.status = cstat.cohortStatus.toLowerCase()
            cohort.materialsStatus = cstat.materialsStatus.toLowerCase()

            return cohort
        })
        return cohorts
    } catch (err) {
        console.log('Could not retrieve cohort information', err)
    }
}


// HEADERS
const headCells = [
    { id: 'fullName', numeric: false, disablePadding: false, label: 'Name', sortable: true },
    { id: 'jobTitle', numeric: false, disablePadding: false, label: 'Role', sortable: true },
    { id: 'orgUnitName', numeric: false, disablePadding: false, label: 'Department', sortable: true },
    { id: 'currentCourses', numeric: false, disablePadding: false, label: 'Current Course', sortable: true },
    { id: 'appRole', numeric: false, disablePadding: false, label: "Access Level", sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false },
];

// CREATE ROWS
const makeRows = (learners) => {
    return learners.map(learner => {
        return createData (
            learner.id,
            learner.firstName,
            learner.lastName,
            `${learner.firstName} ${learner.lastName}`,
            learner.jobTitle,
            learner.orgUnitName,
            learner.email,
            learner.currentCourses || 'None',
            learner.role
        )
    })
}

function createData(id, firstName, lastName, fullName, jobTitle, orgUnitName, email, currentCourses, appRole) {
    return { id, firstName, lastName, fullName, jobTitle, orgUnitName, email, currentCourses, appRole };
}

// SORT HELPERS
function descendingComparator(a, b, orderBy) {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, order, orderBy) {
    const comparator = getComparator(order, orderBy)
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export { 
    fetchUsersListForSupervisor,
    fetchAllCohortsStatusesList,
    headCells,
    makeRows,
    stableSort
}