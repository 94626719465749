import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { GREEN, PINK, YELLOW, BLUE } from '../assets/colors';

function SnackbarNotification({handleClose, message, notificationType}) {
    const mappedColor = {
        'success': GREEN,
        'warning': YELLOW,
        'error': PINK,
        'information': BLUE
    }

    const useStyles = makeStyles(() => ({
        snackbarContainer: {
          backgroundColor: mappedColor[notificationType], 
          paddingLeft: "1rem",
          borderRadius: "4px",
          boxShadow: "0px 4px 8px #00000066"
        },
        snackbarMessageInner: {
          padding:"1rem",
          backgroundColor: "#FFFFFF",
          height: "100%",
          width: "100%",
          borderRadius: "0px 4px 4px 0px"
        }
      }));

    function mappedIcon(notificationType){
        switch(notificationType){
            case 'success':
                return <CheckCircleOutlineIcon style={{color: mappedColor[notificationType]}}/>
            case 'warning':
                return <ReportProblemOutlinedIcon style={{color: mappedColor[notificationType]}}/>
            case 'error':
                return <ErrorOutlineIcon style={{color: mappedColor[notificationType]}}/>
            case 'information':
                return <InfoOutlinedIcon style={{color: mappedColor[notificationType]}}/>
            default:
                return <CheckCircleOutlineIcon style={{color: mappedColor[notificationType]}}/>
        }
    }

    const classes = useStyles()

    return(
        <div className={classes.snackbarContainer}>
            <div className={classes.snackbarMessageInner}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item style={{marginBottom:"-0.3rem"}}>
                        {mappedIcon(notificationType)}
                    </Grid>
                    <Grid item>
                        {message}
                    </Grid>
                    <Grid item style={{paddingLeft:"2rem"}}>
                        <Button 
                            onClick={handleClose} 
                            color="secondary"
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default SnackbarNotification