import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { Avatar, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from '@material-ui/core/styles';
import ProfileBackground from '../assets/imgs/profile-bg.png'
import ProfilePicture from '../assets/imgs/leon_profile.png'
import ProfileCard from '../components/profile/ProfileCard';
import ProfileTag from '../components/ProfileTag'
import CurrentCourseCard from '../components/profile/CurrentCourseCard';
import EditProfile from '../components/profile/EditProfile'
import { updateTopBarText } from '../redux/actions'
import { REGISTERED_LEARNER_STATUS } from "../lib/status"


const useStyles = makeStyles((theme) => ({
    paddedItem: {
        padding:"1rem"
    },
    fixedProfile: {
        position:"fixed",
        padding:"1rem",
        width:"450px",
    },
    fixedNavTop: {
        overflowY: "auto",
        height: "65%",
        padding:"2rem"
    },
    fixedNavFooter: {
        padding:"2rem",
        borderTop: `4px solid ${theme.palette.primary[50]}`,
        height:"35%"
    },
    profilePaper:{
        backgroundImage: `url(${ProfileBackground})`
    },
    avatar: {
        width: '9rem',
        height: '9rem',
        marginTop: '-4rem'
    },
    categoryHeading: {
        fontSize: '.625rem',
        color: theme.palette.primary.main,
        opacity: .64,
        textTransform: 'uppercase',
        paddingBottom: '1rem'
    },
    subtitle: {
        color: theme.palette.primary.main,
        opacity: .8,
    },
    contactSection:{
        padding:"1rem",
        marginTop: "4rem",
        backgroundColor: theme.palette.primary[50], 
        borderBottomRightRadius: "4px", 
        borderBottomLeftRadius:"4px"
    },
    editIcon:{
        color: '#1426FF',
        float: 'right',
        width: '1.6rem',
        height: '1.6rem',
        margin: '-5rem 1rem',
        padding: '0.3rem',
        backgroundColor: '#ffffff',
        borderRadius: '50%'
    }
}));

function Profile(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const foundUser = useSelector(state => state.foundUser)
    const currentCohorts = useSelector(state => state.learnerCurrentCohorts)
    const learner = useSelector(state => state.learnerInfoForSupervisor)
    const { userId } = useParams()

    let user = userId ? learner : foundUser
    let topBarProfileText = userId ? `${learner.firstName} ${learner.lastName} Profile` : 'My Profile'
    const [skillsTags, setSkillsTags] = useState([])
    const [open, setOpen] = useState(false);

     // Update Top Bar Heading
     useEffect(() => {
        dispatch(updateTopBarText(topBarProfileText))
    }, [dispatch, topBarProfileText])

    const placeholder = "-"

    const handleClickOpen = () => {
        setOpen(true);
    };

    /**
    * Returns a Set of unique Tag values from an array of Cohorts
    * @param {Array} cohorts Array of Cohort objects
    * @param {string} cohortStatus Cohort status value to filter by
    * @returns 
    */
     const getCohortTagsByStatus = ( cohorts, cohortStatus ) => {
        const skillsTags = new Set()
    
        // Filter cohorts to target status
        // Map to tags arrays
        // Add individual tags to skillTags set
        cohorts?.filter(course => course.learnerStatus.toUpperCase() === cohortStatus)
                .map(completedCourse => completedCourse.tags)
                .forEach( tags => tags.forEach(tag => skillsTags.add(tag)))
        return [...skillsTags]
    }

    useEffect(() => {
        setSkillsTags(getCohortTagsByStatus(currentCohorts, REGISTERED_LEARNER_STATUS.completed.label.toUpperCase()))
    }, [currentCohorts])

    
    return(
        <>
            <div className={classes.fixedProfile}>
                <Paper elevation={3} className={classes.profilePaper} style={{paddingTop:"6rem"}}>
                    <IconButton className={classes.editIcon} onClick={handleClickOpen}>
                        <EditOutlinedIcon fontSize='small'/>
                    </IconButton>
                    <Paper elevation={0} style={{backgroundColor:"#ffffff"}}>
                        <Grid container justifyContent="space-between">
                            <Grid item container xs={12} spacing={2} className={classes.paddedItem}>
                                <Grid item>
                                    <Avatar variant="rounded" className={classes.avatar} src={ProfilePicture} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" color="primary">{user.firstName + " " + user.lastName}</Typography>
                                    <Typography className={classes.subtitle}>{user.jobTitle || placeholder}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.paddedItem}>
                                <Typography className={classes.categoryHeading}>
                                    Skills & Qualifications
                                </Typography>
                                <Grid item container spacing={1}>
                                { skillsTags.length > 0 
                                    ? skillsTags.map((tag, index) => {
                                        return <ProfileTag key={`skills-${index}`} tagText={tag} />
                                    })
                                    : <Typography color="primary">{placeholder}</Typography>
                                }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.contactSection}>
                                <Typography className={classes.categoryHeading}>
                                    Email Address
                                </Typography>
                                <Typography color="primary">
                                    {user.email}
                                </Typography>
                                <Typography className={classes.categoryHeading} style={{paddingTop:'1rem'}}>
                                    Department
                                </Typography>
                                <Typography color="primary">
                                    {user.orgUnitName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Paper>
            </div>
            <div style={{marginLeft:"455px"}}>
                <Grid container spacing={2} style={{paddingLeft:"0.4rem"}}>
                    <Grid item xs={12} style={{marginTop: "1rem"}}>
                        <CurrentCourseCard currentCohorts={currentCohorts}/>
                    </Grid>
                    {
                        foundUser.courseVotesByUserIdList.length>0 &&
                    <>
                        <Grid item xs={12} style={{padding:"1rem"}}>
                            <Typography variant='h6' color="primary" style={{fontWeight:700, marginTop:"-1.8rem", marginBottom:"0.5rem"}}>
                                Pending Courses
                            </Typography>
                        </Grid>
                        <Grid item xs = {12} style={{marginTop: "-1rem", marginBottom: "2rem"}}>
                            <Paper elevation={3} style={{backgroundColor:"#ffffff", padding:"1rem"}}>
                                <Grid container item spacing={1} >
                                    {   foundUser.courseVotesByUserIdList?.map((courseObj, index) => {
                                            console.log(courseObj)
                                            let waitlistedCourseObj = {
                                                courseName: courseObj.course?.name,
                                                dateCompleted: courseObj.creationTimestamp,
                                                tagsList: courseObj.course?.tags
                                            }
                                            return(  
                                                <ProfileCard key={`waitlist-course-${waitlistedCourseObj.courseName}`} cardType={courseObj.requestType.toUpperCase()} courseObj={waitlistedCourseObj} />  
                                        )})
                                    }  
                                </Grid>
                            </Paper> 
                        </Grid>        
                    </>
                    }
                    { currentCohorts?.find(cohort => cohort.learnerStatus.toUpperCase() === REGISTERED_LEARNER_STATUS.completed.label.toUpperCase()) &&
                        <>
                            <Grid item xs={12} style={{padding:"1rem"}}>
                                <Typography variant='h6' color="primary" style={{fontWeight:700, marginTop:"-1.8rem", marginBottom:"0.5rem"}}>
                                    Completed Courses
                                </Typography>
                            </Grid>
                            <Grid item xs = {12} style={{marginTop: "-1rem", marginBottom: "2rem"}}>
                                <Paper elevation={3} style={{backgroundColor:"#ffffff", padding:"1rem"}}>
                                    <Grid container item spacing={1} >
                                        { currentCohorts?.map((course, index) => {
                                            let completedCourseObj = {
                                                courseName: course?.enrollment.cohort.coursePurchase.course.name,
                                                courseSlug: course?.enrollment.cohort.coursePurchase.course.courseSlug,
                                                pathName: course?.enrollment.cohort.coursePurchase.path.name,
                                                cohortId: course?.enrollment.cohort.id,
                                                cohortStatus: course?.cohortStatus,
                                                dateCompleted: course?.enrollment.cohort.dateEnd,
                                                tagsList: course?.tags,
                                                materialsStatus: course?.materialsStatus 
                                            }
                                            return(
                                                course.learnerStatus.toUpperCase() === REGISTERED_LEARNER_STATUS.completed.label.toUpperCase() &&
                                                <ProfileCard key={`completed-course-${completedCourseObj.courseName}`} cardType="COMPLETED" courseObj={completedCourseObj} />  
                                            )})
                                        }  
                                    </Grid>
                                </Paper> 
                            </Grid>        
                        </>
                    }
                </Grid>
            </div>
            <EditProfile
                open={open}
                setOpen={setOpen}
                foundUser={foundUser}
                skillsTags={skillsTags}
                placeholder={placeholder}
            />
        </>
    )
}

export default Profile