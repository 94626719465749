import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    tagBackground: {
        padding:"0 .5rem",
        backgroundColor: theme.palette.background.paper
    },
}));

function ProfileTag({ tagText }) {
    const classes = useStyles();
    return(
        <Grid item >
            <Paper className = {classes.tagBackground} elevation={0}>
                <Typography variant="caption" style={{fontFamily:"Roboto", fontWeight:"500"}}>{tagText.toUpperCase()}</Typography>
            </Paper>
        </Grid>
    )
}
export default ProfileTag