import React from 'react'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

function WarningBanner({bannerType,additionalText=""}){

    const banners = {
        browserSupport: {
            contents: browserSupport
        }
    }

    function browserSupport(){
        return <Typography className={classes.bannerText}>
            {'meldR is optimized for use with Google Chrome. '}
            <Link href="https://www.google.com/chrome/" color="inherit">Click here for the latest version</Link>.
        </Typography>
    }

    const useStyles = makeStyles((theme) => ({
        banner: {
            backgroundColor: theme.palette.secondary.main,
            color: '#000000',
            padding: ".5rem 0rem",
            'box-shadow': `0px 3px ${theme.palette.secondary[100]}`,
            width:"100%"
        },
        bannerText: {
            textAlign: "center",
        }
      }));

    const classes=useStyles()

    return(
        <div className={classes.banner} style={{backgroundColor: banners[bannerType].backgroundColor}}>
            {banners[bannerType].contents()}
        </div>
    )
}

export default WarningBanner