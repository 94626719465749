import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { nextEnrollableCohort, futureAvailableCohort } from '../lib/registrationRequest';
import { pathIconComponent } from '../lib/mappedPathIcons';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { totalPathwayHours } from '../lib/coursePathwayHelp';
import { SELECTION_TYPES, VIEW_TYPES, INITIAL_SELECTED_COURSE } from '../lib/globalKeys';
import CheckIcon from '@material-ui/icons/Check';
import { GREEN, pathwayColorMap } from '../assets/colors'

function LearnerPathHeading ({divId, path, view, drawerOpen, setDrawerOpen, selection, setSelection, setSelectionType}) {
    const navigate = useNavigate()
    const location = useLocation()
    const currentOrg = useSelector(state => state.currentOrg)
    const registeredPathways = useSelector(state => state.registeredPathways)
    const learnerPaths = useSelector(state => state.learnerPaths)
    const [hover, setHover] = useState(false)

    const pathwayColorObj = pathwayColorMap[path.pathwayCode] || pathwayColorMap.default

    const isPathwaySelected = () => {
        return selection===path
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            cursor: "pointer",
            padding:"1.5rem 1rem", 
            borderRight: `2px solid ${isPathwaySelected()? theme.palette.primary['A100']: theme.palette.primary[50]}`,
            borderBottom: `1px solid ${isPathwaySelected()? theme.palette.primary['A100']: theme.palette.primary[50]}`,
            borderLeft: `4px solid ${isPathwaySelected()? theme.palette.primary['A700'] : (hover? theme.palette.primary.main : theme.palette.secondary['A700'])}`,
            borderTop: `0px`,
            backgroundImage: `linear-gradient(to left, rgb(${pathwayColorObj.rgbValues}, 0.6), rgb(${pathwayColorObj.rgbValues}))`,
            backgroundSize: '100% 4px',
            backgroundPosition:'top',
            backgroundRepeat: 'no-repeat',
            backgroundColor:  isPathwaySelected()? theme.palette.primary[50] : ""

        },
        pathwayText:{
            textDecoration: hover?"underline":"",
            fontWeight:"bold",
            color: theme.palette.primary.main 
        },
        squareIcon: {
            width:"1rem", 
            paddingTop:"4px",
            color: theme.palette.primary[300]
        },
        verticalIcon:{
            paddingTop:"6px",
            color: theme.palette.primary[300]
        },
        lightText: {
            color: theme.palette.primary[300]
        },
    }));
    const classes = useStyles();

    const pathSegments = path.learnerPathSegmentsByPathIdList
    const purchasedCoursesAvailableEnrollment = []
    pathSegments.forEach(segment => {
        const course = segment.course
        if(course && nextEnrollableCohort(course)){
            purchasedCoursesAvailableEnrollment.push(course)
        }
    })

    const handleClickPathway = () => {
        const prefix = view===VIEW_TYPES.SUPERVISOR? '/admin': ''
        const searchParams = new URLSearchParams(location.search)
        const pathParam = searchParams.get('path')
        const querySuffix = pathParam? `?path=${pathParam}` : ''
        if (isPathwaySelected()) {
            setDrawerOpen(!drawerOpen)
            setSelection(INITIAL_SELECTED_COURSE)
            setSelectionType('')
            navigate(`${prefix}/course-catalog${querySuffix}`)
        } else {
            setSelection(path)
            setSelectionType(SELECTION_TYPES.PATHWAY)
            setDrawerOpen(true)
            navigate(`${prefix}/course-catalog/${path.pathwaySlug}${querySuffix}`)
        }
    }

    const pathwayPurchased = (thisPathway) => {
        const pathwayObj = learnerPaths.find(catalogPathway => thisPathway.pathwayCode === catalogPathway.pathwayCode)
        return !!pathwayObj && pathwayObj.learnerPathSegmentsByPathIdList.reduce((bool, segment) => {
            return bool && (nextEnrollableCohort(segment.course) || futureAvailableCohort(segment.course))
        }, true)
    }

    const actionButton = (label) => {
        return (<Grid item style={{paddingTop:"1rem"}}>
            <Button color="secondary" variant="contained">
                {label}
            </Button>
        </Grid>)
    }

    const completedActionButton = (label) => {
        return (<Grid item container alignItems="center" style={{paddingTop:"1rem"}} spacing={1}>
            <Grid item>
                <Typography style={{color: GREEN}}>
                    {label} 
                </Typography>
            </Grid>
            <Grid item>
                <CheckIcon style={{color: GREEN}}/>
            </Grid>
        </Grid>)
    }

    const setCtaLabel = () => {
        if (currentOrg.featPathwayEnrollment) {
            if (view===VIEW_TYPES.LEARNER) {
                return registeredPathways.includes(path.name) ? completedActionButton('Registered') : actionButton('Register All Courses')
            } else {
                return pathwayPurchased(path) ? completedActionButton('Purchased') : actionButton('Purchase')
            }
        }
    }

    return(
        <div id={divId} className={classes.root} onClick={handleClickPathway} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                    {pathIconComponent(path.pathwayCode, {height:"3rem", width:"3rem", display:"inherit", margin:"auto"}, "#ffffff")}
                </Grid>
                <Grid item style={{paddingLeft: "1rem"}} xs={11}>
                    <Typography variant="h6" display="inline" className={classes.pathwayText}>
                        {path.name}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <ScheduleIcon className={classes.squareIcon} />
                                </Grid>
                                <Grid item style={{padding:'0rem'}}>
                                    <Typography className={classes.lightText} variant="subtitle2">
                                        {totalPathwayHours(path)} Hours
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <BookOutlinedIcon className={classes.verticalIcon} />
                                </Grid>
                                <Grid item style={{padding:'0rem'}}>
                                    <Typography className={classes.lightText} variant="subtitle2">
                                        {path.learnerPathSegmentsByPathIdList.length} Courses
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography color="primary" variant="body1">
                            {path.description}
                        </Typography>
                    </Grid>
                    {setCtaLabel()}
                </Grid>
            </Grid>
        </div>
    )
}

export default LearnerPathHeading