import React from 'react'
import { Box, Typography } from '@material-ui/core'
import EnrollmentPrice from '../course_catalog/EnrollmentPrice'

const PriceWrapper = ({children, coursePurchase}) => {
  return (
    <Box
      style={{
          display: "flex", 
          flexDirection:"column", 
          alignItems: "center", 
          gap:"0.25rem"
      }}
  >
      {children}
      {
        coursePurchase?.price &&
          <Typography align="right" variant="caption" style={{color: "#7D7F84"}}>
              Price on registation:  <EnrollmentPrice coursePurchase={coursePurchase} styled={false} />
          </Typography>
      }
  </Box>
  )
}

export default PriceWrapper