import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { makeRows, stableSort, headCells } from '../../lib/cohortDisplay';
import CohortDisplayTableHead from './CohortDisplayTableHead'
import CohortDisplayTableRow from './CohortDisplayTableRow'

// STYLING
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    fontWeight: 'bold'
  },
  nestedTableHeader: {
    fontSize: '.75rem'
  },
  nestedTableLast:{
    border: "none"
  },
  table: {
    minWidth: 400
  },
  status: {
    fontFamily: "Roboto",
    fontSize: '0.625rem',
    fontWeight:"500",
    textTransform: 'uppercase',
    display: 'inline-block',
    padding: '5px 8px',
    borderRadius: '4px'
  },
  upcoming: {backgroundColor: '#FAED623D'},
  unavailable: {backgroundColor: '#FAED623D'},
  open: {backgroundColor: '#0A95653D'},
  available: {backgroundColor: '#0A95653D'},
  closed: {backgroundColor: '#C114B13D'},
  archived: {backgroundColor: '#C114B13D'},
  ongoing: {backgroundColor: '#5BB6E73D'},
  completed: {backgroundColor: '#CCCCCC'},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  daysLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary[900],
    borderRadius: ".25rem",
    opacity: "1",
    fontSize: ".75rem",
    color: "#FFFFFF",
    margin: '0px 7px 0px 0px',
    padding: '0px 4px'
  }, 
  registration: {
    justifyContent: 'start',
    alignItems: 'center'
  }
}));

const CohortDisplayTable = (props) => {
    const rows = makeRows(props.cohorts)

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('dates');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <CohortDisplayTableHead
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, order, orderBy)
                            .map((row, index) => {
                              return (
                                  <CohortDisplayTableRow key={row.id} row={row} classes={classes} />
                              );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

        </div>
    );
}

export default CohortDisplayTable