import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router';
import { updateSignupOpen } from '../../redux/actions'
import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core'
import { ReactComponent as MeldrLogoHz } from '../../assets/imgs/meldr-hz.svg'
import { ReactComponent as MeldrPoweredByDS } from '../../assets/imgs/meldr-powered-by-ds-login.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { signup } from '../../lib/firebase'
import { getIsEmailDomainValid } from '../../lib/registrationRequest'

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor:"#ffffff", 
        padding:"0rem",
        overflowX: "hidden", 
        maxWidth:"36rem"
    },
    loginItem:{
        padding: ".5rem 3rem",
        textAlign: "center"
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: "0rem 3rem"
    },
    logo: {
        width: '18px',
        height: '18px'
    },
    googleBtn:{
        color: theme.palette.secondary.main
    },
    githubBtn: {
        backgroundColor: '#191717',
        color: '#ffffff',
        '&:hover': {
            background: '#2c2a2a',
            color: '#ffffff'
        }
    },
    loginUrl: {
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    errorMessage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        backgroundColor: '#ED086914',
        width: '100%',
        border: '1px solid #ED0869',
        borderRadius: '8px'
    }
}));

function Signup(){
    const openSignupForm = useSelector(state => state.openSignupForm)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const classes = useStyles()
    const emailRef = useRef()
    const passwordRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const titleRef = useRef()
    const departmentRef = useRef()
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)
    const tenantId = useSelector(state => state.currentOrg?.tenantId)
    const emailDomains = useSelector(state => state.currentOrg?.preferences?.emailDomains)

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const emailValid = validateEmailDomain();
            setLoading(true)
            if (emailValid) {
                if (Object.keys(error).length === 0) {
                    localStorage.setItem('newUserDetails', JSON.stringify({firstName: firstNameRef.current.value, lastName: lastNameRef.current.value, title: titleRef.current.value, department: departmentRef.current.value}))
                    const response = await signup(emailRef.current.value, passwordRef.current.value, tenantId)
            
                    if(response) {
                        //send email verification
                        await response.user.sendEmailVerification()
                    }
                    dispatch(updateSignupOpen(false)) 
                }
            }
            setLoading(false)

        } catch(error) {
            console.error(`Sign up error`, error)
            localStorage.removeItem('newUserDetails')
            setLoading(false)
            if(error.code !== 'auth/popup-closed-by-user'){
                let firebaseError = {'firebaseError': error.message || 'Failed to sign up. Please contact your administrator.'}
                setError(err => ({...err, ...firebaseError}))
            }
        }
        
    }

    const handleCloseSignup = () => {
        dispatch(updateSignupOpen(false))
        navigate(location.pathname)
    }

    const handleOpenLogin = (e) => {
        e.preventDefault()
        dispatch(updateSignupOpen(false))
        const params = new URLSearchParams(location.search)
        const searchParams = params.get('redirect') ? "?login=true&redirect=true" : "?login=true"
        navigate(location.pathname+searchParams)
    }

    const validateConfirmPasswordInput = (e) => {
        const confirmPassword = e.target.value
        let passwordErr = {}

        if (confirmPassword !== passwordRef.current.value) {
            passwordErr = {'passwordError': 'Password and confirmation password do not match'}
            setError(err => ({ ...err, ...passwordErr }))
        } 
        else {
            passwordErr = {...error}
            delete passwordErr.passwordError
            setError({ ...passwordErr })
        }
    }

    const validateEmailDomain = () => {
        const isEmailValid = getIsEmailDomainValid(emailRef.current.value, emailDomains)
        let emailErr = {}

        if(!isEmailValid) {
            emailErr = {'emailError': 'Please provide a valid email domain'}
            setError(err => ({ ...err, ...emailErr }))
        } else {
            emailErr = {...error}
            delete emailErr.emailError
            setError({ ...emailErr })
        }
        return isEmailValid
    }

    const resetErrorMessage = (type) => {
        let resetErr = {...error}
        if (type === 'email') {
            delete resetErr.emailError
        } else if (type === 'password') {
            delete resetErr.passwordError
        } else {
            delete resetErr.firebaseError
        }
        setError({ ...resetErr })
    }

    return(
        <Dialog open={openSignupForm} onClose={handleCloseSignup} aria-labelledby="log in to meldR">
            <DialogContent className={classes.root} style={{paddingTop:"0rem"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <IconButton style={{float:"right", margin:".5rem"}} onClick={handleCloseSignup}>
                            <HighlightOffIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"-1rem"}}>
                        <MeldrLogoHz style={{width:"10rem", height:"auto", display:"block", margin:"auto"}}/>
                    </Grid>
                    <Grid item xs={12} style={{margin:"1.5rem 0rem"}}>
                        <Typography align="center" variant="h5">Create your meldR account</Typography>
                    </Grid>
                    <Grid item container xs={12} className={classes.loginItem}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <TextField 
                                id="firstname" 
                                label="First Name"
                                type="text" 
                                variant="outlined" 
                                inputRef={firstNameRef}
                                required
                                size="small"
                                style={{paddingBottom:".5rem", paddingRight: ".5rem", width: "50%"}}
                            />
                            <TextField 
                                id="lastname" 
                                label="Last Name"
                                type="text" 
                                variant="outlined" 
                                inputRef={lastNameRef}
                                required
                                size="small"
                                style={{paddingBottom:".5rem", width: "50%"}}
                            />
                            <TextField 
                                id="title" 
                                label="Job Title"
                                type="text" 
                                variant="outlined" 
                                inputRef={titleRef}
                                required
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                            />
                            <TextField 
                                id="department" 
                                label="Department"
                                type="text" 
                                variant="outlined" 
                                inputRef={departmentRef}
                                required
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                            />
                            <TextField 
                                id="email" 
                                label="Email"
                                type="email" 
                                variant="outlined" 
                                inputRef={emailRef}
                                required 
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                                onBlur={validateEmailDomain}
                                onFocus={e => resetErrorMessage(e, 'email')}
                            />
                            <TextField 
                                id="password" 
                                label="Password"
                                type="password" 
                                variant="outlined" 
                                inputRef={passwordRef}
                                required 
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                            />
                            <TextField 
                                id="confirm-password" 
                                label="Confirm Password"
                                type="password" 
                                variant="outlined" 
                                required 
                                fullWidth
                                size="small"
                                style={{paddingBottom:".5rem"}}
                                onBlur={validateConfirmPasswordInput}
                                onFocus={e => resetErrorMessage(e, 'password')}
                            />
                            { Object.keys(error).length > 0 &&
                                <Alert severity="error" className={classes.errorMessage}>
                                    { error.emailError || error.passwordError || error.firebaseError }
                                </Alert>
                            }
                            <FormControlLabel
                                value="end"
                                control={<Checkbox required color="primary" />}
                                label={<Typography>I have read and agree to the <a target="_blank" rel="noopener noreferrer" href="/terms">terms and conditions</a>.</Typography>}
                                labelPlacement="end"
                                style={{width: "100%", padding:".25rem 0 1rem 0"}}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                fullWidth
                            >
                                Sign Up
                            </Button>
                        </form>
                    </Grid>
                    
                    <Grid item xs={12} className={classes.loginItem}>  
                        <Typography variant="body2" display="inline">Already have an account? </Typography> 
                        <Link to='#' component="button" onClick={handleOpenLogin} style={{paddingBottom: ".2rem"}}>
                            <Typography variant="body2" display="inline" className={classes.loginUrl}>Log in</Typography>
                        </Link>
                    </Grid>
                    <Grid item style={{backgroundColor:"#ebecee", padding:"1rem"}} xs={12}>
                        <MeldrPoweredByDS style={{width:"100%", display:"block", margin:"auto"}}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default Signup