import React, { useRef, useState, useEffect } from 'react'
import { Button, TextField, Divider, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { changePassword } from '../../lib/firebase';



const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#ffffff",
        padding: "0rem",
        overflowX: "hidden",
        width: "100%"
    },
    divider: {
        color: theme.palette.primary[50],
        width: 'calc(100vw - 460px)',
        left: '460px',
        height: '1.5px'
    },
    errorMessage: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        backgroundColor: '#ED086914',
        width: '530px',
        border: '1px solid #ED0869',
        borderRadius: '8px'
    },
    successMessage: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        width: '530px',
        border: '1px solid #2F7C31',
        borderRadius: '8px'
    }
}));


function ChangePassword() {
    const currentPasswordRef = useRef()
    const newPasswordRef = useRef()
    const confirmPasswordRef = useRef()
    const formRef = useRef()
    const classes = useStyles()
    const [error, setError] = useState({})
    const [success, setSuccess] = useState({})

    useEffect(() => {
        if (Object.keys(success).length > 0) {
            formRef.current.reset()
            setError({})
            setTimeout(() => {
                setSuccess({})
            }, 5000);
        }
    }, [success])

    const validatePasswordConfirmation = () => {
        let passwordErr = {}

        if ((confirmPasswordRef.current.value !== newPasswordRef.current.value) && confirmPasswordRef.current.value !== '') {
            passwordErr = { 'passwordError': 'Password and confirmation password do not match' }
            setError(err => ({ ...err, ...passwordErr }))
        }
        else {
            passwordErr = { ...error }
            delete passwordErr.passwordError
            setError({ ...passwordErr })
        }
    }


    const resetErrorMessage = (type) => {
        let resetErr = { ...error }
        if (type === 'password') {
            delete resetErr.passwordError
        }
        setError({ ...resetErr })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (confirmPasswordRef.current.value === newPasswordRef.current.value) {
            await changePassword(currentPasswordRef.current.value, newPasswordRef.current.value, setError, setSuccess)
        } else {
            let passwordErr = {}
            passwordErr = { 'passwordError': 'Password and confirmation password do not match' }
            setError(err => ({ ...err, ...passwordErr }))
        }
    }

    return (
        <Grid>
            <Grid >
                <Typography variant="h5" style={{ fontWeight: "bold", margin: "0 0 1rem 1.5rem" }}>
                    Change Your Password
                </Typography>
            </Grid>
            <Grid item container xs={8} >
                <form onSubmit={(e) => handleSubmit(e)} ref={formRef} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        id="current-password"
                        label="Current Password"
                        type="password"
                        variant="outlined"
                        inputRef={currentPasswordRef}
                        required
                        size="small"
                        style={{ margin: "1rem 0 1.5rem 1.5rem", width: '530px' }}
                    />
                    <Divider className={classes.divider} />
                    <TextField
                        id="new-password"
                        label="New Password"
                        type="password"
                        variant="outlined"
                        inputRef={newPasswordRef}
                        required
                        size="small"
                        style={{ margin: "1.5rem 0 1rem 1.5rem", width: '530px' }}
                        onChange={validatePasswordConfirmation}
                        onFocus={e => resetErrorMessage(e, 'password')}
                    />
                    <TextField
                        id="confirm-password"
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        inputRef={confirmPasswordRef}
                        required
                        size="small"
                        style={{ margin: "0 0 1.5rem 1.5rem", width: '530px' }}
                        onChange={validatePasswordConfirmation}
                        onFocus={e => resetErrorMessage(e, 'password')}
                    />
                    <Divider className={classes.divider} />
                    {Object.keys(error).length > 0 &&
                        <Alert severity="error" className={classes.errorMessage}>
                            {error.passwordError}
                        </Alert>
                    }
                    {Object.keys(success).length > 0 &&
                        <Alert severity="success" className={classes.successMessage}>
                            {success.successMessage}
                        </Alert>
                    }
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        style={{ margin: "1.5rem 0 0 1.5rem", width: '100px' }}
                    >
                        Save
                    </Button>
                </form>
            </Grid>
        </Grid>
    )
}

export default ChangePassword