import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { VIEW_TYPES } from '../../lib/globalKeys'
import LinearProgress from '@material-ui/core/LinearProgress';
import { ANONYMOUS_DEFAULT_PAGE, LEARNER_DEFAULT_PAGE, SUPERVISOR_DEFAULT_PAGE } from '../../lib/globalKeys'

//In react-router terms, this is technically a layout

function InitialNavigate({ loadingAuth, loadingUser}) {
    const currentUser = useSelector(state => state.currentUser)
    const currentRole = useSelector(state => state.foundUser?.role)

    // 1. If currentUser(loadingAuth) or foundUser(loadingUser) are still loading, return loading component
    // 2. If currenUser and currentRole are found, redirect to the learner/supervisor default page
    // 3. If currentUser and/or currentRole are not found, redirect to the anonymous default page
    if(loadingAuth || loadingUser){
        return <LinearProgress color="secondary" />
    }
    else {
        if(currentUser && currentRole){
            if(currentRole === VIEW_TYPES.SUPERVISOR){
                return <Navigate to={SUPERVISOR_DEFAULT_PAGE} replace/>
            }
            else if(currentRole === VIEW_TYPES.LEARNER){
                return <Navigate to={LEARNER_DEFAULT_PAGE} replace/>
            }
        }
            return <Navigate to={ANONYMOUS_DEFAULT_PAGE} replace/> 
    }
}

export default InitialNavigate