import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarText } from '../redux/actions'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchUsersListForSupervisor, fetchAllCohortsStatusesList } from '../lib/usersDisplay'
import UsersSearch from '../components/supervisor_browse_users/UsersSearch'
import UsersDisplayTable from '../components/supervisor_browse_users/UsersDisplayTable'
import { COHORT_STATUSES } from "../lib/status";


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    header: {
      fontWeight: 'bold'
    },
    searchbox: {
      padding: '0.8rem',
      backgroundColor: '#e1e1e4', //#e1e1e4
      border: '0.1rem solid #f0f0f1'
    }
}));

function BrowseUsers(){
    const classes = useStyles();
    const dispatch = useDispatch()
    const currentOrg = useSelector(state => state.currentOrg)
    const [users, setUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1);
    const placeholderSearch = 'Search Learners, Roles, Departments, and Current Courses'

    const filteredLearners = users.filter(user => {
        return user.firstName?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
                || user.lastName?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
                || `${user.firstName} ${user.lastName}`?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
                || user.jobTitle?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
                || user.orgUnitName?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
                || user.currentCourses?.toLowerCase().includes(searchTerm?.toLowerCase().trim())
    })

    const handleSearch = (event) => {
        setPage(1);
        setSearchTerm(event.target.value)
    };

    const resetSearch = () => {
        setPage(1);
        setSearchTerm('')
    };


    // Update Top Bar Heading
    useEffect(() => {
        (async () => {
            dispatch(updateTopBarText('Browse Users'))
            const users = await fetchUsersListForSupervisor(currentOrg?.id)
            const cohortStatuses = await fetchAllCohortsStatusesList(currentOrg?.id)
            
            // Assign cohort status and cohort name to each learner's cohorts
            users?.forEach(learner => {
                let currentCourses = []
                learner?.enrollmentsByLearnerIdList.map(enroll => {
                    return cohortStatuses?.map(cohort => {
                        if(cohort.id === enroll.cohort.id) {
                            enroll.cohortStatus = cohort.cohortStatus
                            if(cohort.status.toLowerCase() === COHORT_STATUSES.ongoing.label.toLocaleLowerCase()) {
                                currentCourses.push(enroll?.cohort?.coursePurchase?.course?.name)
                            }
                        }
                        return cohort
                    })
                })
                learner.currentCourses = currentCourses.join(', ')
            })
            setUsers(users)
        })()
       
    }, [currentOrg, dispatch])

    return(
        <>
            <Grid container className={classes.searchbox}>
                <Grid item xs={12}>
                    <Grid item style={{paddingLeft: "1rem"}}>
                        <UsersSearch 
                            placeholder={placeholderSearch} 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                            handleSearch={handleSearch}
                            resetSearch={resetSearch} 
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container>
                    <UsersDisplayTable learners={filteredLearners} page={page} setPage={setPage}/>
                </Grid>
            </Grid>
        </>
    )
}

export default BrowseUsers