import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import ErrorPage from '../../pages/ErrorPage';
import { LEARNER_DEFAULT_PAGE, VIEW_TYPES } from '../../lib/globalKeys'
import LinearProgress from '@material-ui/core/LinearProgress';

//In react-router terms, this is technically a layout

function PrivateRoute({ loadingAuth, loadingUser, routeType="admin"}) {
    const currentUser = useSelector(state => state.currentUser)
    const currentRole = useSelector(state => state.foundUser?.role)
    const currentOrg = useSelector(state => state.currentOrg)

    // If no current org, render loading component
    // If org and auth/user have loaded by no login feature is not supported, return 404 page.
    if( !currentOrg ){
        return <LinearProgress color="secondary" />
    }
    else if(!currentOrg.featLogin && !(loadingAuth || loadingUser)){
        return <ErrorPage errorType="404"/>
    }
    
    // Protected Route (Learner or Supervisor)
    // 1. If currentUser(loadingAuth) or foundUser(loadingUser) are still loading, return loading component
    // 2. If currentUser loaded and the user is a learner or supervisor, return the page
    // 3. If currentUser loaded but no user is found or is not a learner or supervisor, return error page
    if(routeType === "general"){
        if(loadingAuth || loadingUser){
            return <LinearProgress color="secondary" /> 
        }
        else{
            if(currentUser && (currentRole === VIEW_TYPES.SUPERVISOR || currentRole === VIEW_TYPES.LEARNER)){
                return <Outlet />
            }
            return <ErrorPage errorType="403" />
        }
    }

    // Protected Admin Route
    // 1. If currentUser(loadingAuth) or foundUser(loadingUser) are still loading, return loading component
    // 2. If currentUser and foundUser loaded and the user is a supervisor, return the page
    // 3. If currentUser and foundUser loaded and the user is a learner, navigate to learner default page
    // 4. If currentUser & foundUser loaded and no user found, return error page
    if(loadingAuth || loadingUser){
        return <LinearProgress color="secondary" />
    }
    else{
        if(currentUser && currentRole === VIEW_TYPES.SUPERVISOR){
            return <Outlet />
        }
        else {
            if(currentUser && currentRole === VIEW_TYPES.LEARNER){
                return <Navigate to={LEARNER_DEFAULT_PAGE} />
            }
            return <ErrorPage errorType="403" />
        }
    } 
}

export default PrivateRoute