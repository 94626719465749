import { betweenDates, dateParse } from './date';

const upcomingStatusKey = "upcoming"
const openStatusKey = "open"
const closedStatusKey = "closed"
const ongoingStatusKey = "ongoing"
const completedStatusKey = "completed"

const REGISTRATION_STATUSES_SUPERVISOR = {
    upcoming: {
        label: 'Upcoming',
        initialValue: false
    },
    open: {
        label: 'Open',
        initialValue: true
    },
    closed: {
        label: 'Closed',
        initialValue: false
    },
}

const REGISTRATION_STATUSES_LEARNER = {
    upcoming: {
        label: 'Upcoming',
        initialValue: false
    },
    open: {
        label: 'Open',
        initialValue: false
    }
}

const COHORT_MATERIAL_STATUSES = {
    unavailable: {
        label: 'Unavailable',
        initialValue: false
    },
    available: {
        label: 'Available',
        initialValue: false
    },
    archived: {
        label: 'Archived',
        initialValue: false
    }
}

const COHORT_STATUSES = {
    ongoing: {
        label: 'Ongoing',
        initialValue: false
    },
    completed: {
        label: 'Completed',
        initialValue: false
    },
    archived: {
        label: 'Archived',
        initialValue: false
    }
}

const COURSE_STATUSES = {
    requested: {
        label: 'Requested',
        initialValue: false
    },
    purchased: {
        label: 'Purchased',
        initialValue: false
    }
}

const REGISTERED_LEARNER_STATUS = {
    registered: {
        label: 'Registered',
        initialValue: false
    },
    taking: {
        label: 'Taking',
        initialValue: false
    },
    completed: {
        label: 'Completed',
        initialValue: false
    }

}

const showDaysLeftTrigger = 14

/**
 * @deprecated Cohort Status is calculated serverside
 *
 * Calculates date-based status of each Cohort
 * and appends to the object as the `status` field
 *
 * @param {Array} cohorts Array of cohort objects
 */
const addCohortStatus = (cohorts) => {
     
    return cohorts.map(cohort => {
        const { enrollmentStart, enrollmentEnd, dateStart, dateEnd } = cohort
        const now = new Date()
        let status

        if (dateParse(enrollmentStart) > now) {
            status = "upcoming"
        } else if (betweenDates(now, enrollmentStart, enrollmentEnd, {"startInclusive": true, "endInclusive": true})) {
            status = "open"
        } else if (betweenDates(now, enrollmentEnd, dateStart, {"startInclusive": false, "endInclusive": false})) {
            status = "closed"
        } else if (betweenDates(now, dateStart, dateEnd, {"startInclusive": true, "endInclusive": true})) {
            status = "ongoing"
        } else if (dateParse(dateEnd) < now) {
            status = "completed"
        }
        
        return {...cohort, status}
    })
}

/**
 * 
 * @param {Object} defn Status type definitions. Object containing nested pairs of 'label' and 'initialValue' keys
 */
function createStatusFilter ( defn ){
    const filter = {}
    Object.keys(defn).forEach(status => filter[status] = defn[status].initialValue)
    return filter
}

const INITIAL_REGISTRATION_SUPERVISOR_STATUS_FILTER = createStatusFilter(REGISTRATION_STATUSES_SUPERVISOR)
const INITIAL_REGISTRATION_LEARNER_STATUS_FILTER = createStatusFilter(REGISTRATION_STATUSES_LEARNER)
const INITIAL_COHORT_STATUS_FILTER = createStatusFilter(COHORT_STATUSES)
const INITIAL_COHORT_MATERIAL_STATUS_FILTER = createStatusFilter(COHORT_MATERIAL_STATUSES)
const INITIAL_COURSE_STATUS_FILTER = createStatusFilter(COURSE_STATUSES)

export { 
    upcomingStatusKey,
    openStatusKey,
    closedStatusKey,
    ongoingStatusKey,
    completedStatusKey,
    addCohortStatus,
    createStatusFilter,
    showDaysLeftTrigger,
    REGISTRATION_STATUSES_SUPERVISOR,
    REGISTRATION_STATUSES_LEARNER,
    COHORT_STATUSES,
    COHORT_MATERIAL_STATUSES,
    COURSE_STATUSES,
    INITIAL_REGISTRATION_SUPERVISOR_STATUS_FILTER,
    INITIAL_REGISTRATION_LEARNER_STATUS_FILTER,
    INITIAL_COHORT_STATUS_FILTER,
    INITIAL_COHORT_MATERIAL_STATUS_FILTER,
    INITIAL_COURSE_STATUS_FILTER,
    REGISTERED_LEARNER_STATUS
}