import React from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    checkboxText: {
        fontSize: "0.8rem"
    },
  }));

function StatusFilter({ statusFilter, handleStatusFilterChange, statuses, label }) {
    const currentOrg = useSelector(state => state.currentOrg)
    const classes = useStyles()

    // determine if this component should even display:
    //   org enrollment must be enabled
    const isEnabled = !!currentOrg.featCohortEnrollment

    const formControlLabels = Object.keys(statuses).map(status => {
        return (
            <FormControlLabel
                key={status}
                control={<Checkbox checked={statusFilter[status]} onChange={handleStatusFilterChange} name={status} size="small"/>}
                label={statuses[status].label}
                classes={{
                    label: classes.checkboxText
                  }}
            />
        )
    }) 
    
    return(
        <div>
            { isEnabled && <FormControl component="fieldset" >
                <FormLabel
                    focused={false}
                    component="legend"
                >
                    {label}
                </FormLabel>
                <Grid>
                    {formControlLabels}
                </Grid>
            </FormControl>
            }
        </div>
    )
}

export default StatusFilter