import React from 'react'
import { daysLeftMessage, daysLeftInCourse } from '../../lib/registrationRequest';
import { showDaysLeftTrigger } from '../../lib/status';
import AvTimerIcon from '@material-ui/icons/AvTimer';

const DaysLeftIndicator = ({course}) => {
  return daysLeftInCourse(course) <= showDaysLeftTrigger && (
    <div 
        className="MuiButton-textSizeMedium" 
        style={{
            display: 'flex', 
            background: "none",
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <AvTimerIcon /> 
        <span>
            {daysLeftMessage(course)}
        </span>
    </div>
  )
}

export default DaysLeftIndicator