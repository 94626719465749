import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { ReactComponent as MeldrLogoHz } from '../assets/imgs/meldr-hz.svg'
import leonInfo from'../assets/imgs/Leon_info_2x.png';

function UnsupportedDevice(){

    const errorMsg = 'Screen Size Not Supported'
    const errorDesc = 'meldR is not currently supported at this screen size, but we’re working on it!'

    return(
        <div>
            <div style={{padding:"4rem 2rem", margin:"auto", width:"80%"}}>
                <div>
                    <MeldrLogoHz style={{width:"10rem", height:"auto", display:"block"}}/>
                </div>
                <div style={{paddingTop:"2rem"}}>
                    <Typography variant="h5" style={{fontWeight:700}}>{errorMsg}</Typography>
                </div>
                <div id="text-container" style={{paddingTop:"1rem"}}>
                    <Typography style={{maxWidth: "261px", fontSize:"14px"}}>
                        {errorDesc}
                    </Typography>
                </div>
                <Button
                    variant="contained" 
                    color="secondary" 
                    style={{marginTop:"3rem"}}
                    href="https://meldr.ai/"
                >
                    visit meldr.ai
                </Button>
            </div>
            <div style={{
                height:"32rem",
                marginLeft: "30vw",
                marginTop: "-20vw",
                zIndex: "-1",
                backgroundImage: `url(${leonInfo})`,
                backgroundSize: "contain",
                backgroundRepeat: 'no-repeat'
                }}/>
        </div>
    )
}

export default UnsupportedDevice