import { buildGQLDispatchConfig } from './util';
const api_url = process.env.REACT_APP_API_URL

const fetchCourseSlides = (courseSlug, cohortId) => {
    const query = `query CourseSlideURLs {
      CourseSlideURLs(course_slug: "${courseSlug}", cohort_id: ${cohortId}) {
        delivery_id
        course_slide_urls
      }
    }`

    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
            .then((resp) => resp.json())
            .then((json) => {
                if (json.errors) {
                    console.log(json.errors[0].message)
                }
                resolve(json)
            })
            .catch((err) => console.log(err));
    });
};

export {
    fetchCourseSlides
}