import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopBarText } from '../redux/actions'
import { Grid } from '@material-ui/core';
import StatusFilter from '../components/StatusFilter'
import CourseSearch from '../components/course_catalog/CourseSearch'
import CohortDisplayTable from '../components/supervisor_cohorts/CohortDisplayTable'
import { fetchAllCohortsStatusesList } from '../lib/usersDisplay' 
import {
    REGISTRATION_STATUSES_SUPERVISOR,
    INITIAL_REGISTRATION_SUPERVISOR_STATUS_FILTER,
    COHORT_STATUSES,
    INITIAL_COHORT_STATUS_FILTER
} from "../lib/status";

/**
 * Create an object to filter by key
 * @param {*} filterState 'State' prop, defining filtering state
 * @param {*} filterKeyName Name of an object key to be filtered
 */
function objectKeyFilter( filterState, filterKeyName ){
    return {
        keyReg: Object.keys(filterState),
        filterState,
        filterKeyName,
        filter: function(obj){
            return this.filterState[obj[filterKeyName]]
        },
        isActive: function(){
            // if any key is selected in state
            return this.keyReg.some(key => this.filterState[key])
        }
    }
}

function SupervisorCohorts() {
    const dispatch = useDispatch()
    const currentOrg = useSelector(state => state.currentOrg)
    const [cohorts, setCohorts] = useState([])

    // Update Top Bar Heading
    useEffect(() => {
        (async () => {
            dispatch(updateTopBarText('Cohorts'))
            const cohortStatuses = await fetchAllCohortsStatusesList(currentOrg?.id)
            setCohorts(cohortStatuses)
        })()
    }, [dispatch, currentOrg])
    
    // Status Filtering
    const [statusFilterRegistration, setStatusFilterRegistration] = useState( INITIAL_REGISTRATION_SUPERVISOR_STATUS_FILTER)
    const [statusFilterCohort, setStatusFilterCohort] = useState(INITIAL_COHORT_STATUS_FILTER)

    const handleStatusFilterChangeRegistration = (event) => {
        setStatusFilterRegistration({ ...statusFilterRegistration, [event.target.name]: event.target.checked });
    };
    
    const handleStatusFilterChangeCohort = (event) => {
        setStatusFilterCohort({ ...statusFilterCohort, [event.target.name]: event.target.checked });
    };

    // Course Filtering  
    const [courseSearchTerm, setCourseSearchTerm] = useState('')

    // Cohort Display

    // cohort filtering
    const cohortFilters = [
        objectKeyFilter( statusFilterRegistration, 'status'),
        objectKeyFilter( statusFilterCohort, 'status'),
    ]

    // Determine if any filter is selected ('active')
    const isFilter = cohortFilters.some( filterDef => filterDef.isActive() )

    // Apply filters to cohorts
    let filteredCohorts
    if(cohorts?.length>0){
        filteredCohorts = cohorts?.filter(cohort => {
            // match name
            const cohortName = cohort?.coursePurchase?.course.name
            const nameFilter = cohortName.toLowerCase().includes(courseSearchTerm.toLowerCase())
    
            // match other filters
            const otherFilters = !isFilter || !!cohortFilters.some(f => f.filter(cohort))
    
            // filter if any matches
            return otherFilters && nameFilter
        })
    } else {
        filteredCohorts=[]
    }

    return(
        <>
            <Grid container style={{padding:"2rem"}}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{paddingBottom: "1rem"}}>
                        <Grid item >
                            <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid item>
                                    <StatusFilter statusFilter={statusFilterRegistration} handleStatusFilterChange={handleStatusFilterChangeRegistration} statuses={REGISTRATION_STATUSES_SUPERVISOR} label={"Registration Status"} />
                                </Grid>
                                <Grid item>
                                    <StatusFilter statusFilter={statusFilterCohort} handleStatusFilterChange={handleStatusFilterChangeCohort} statuses={COHORT_STATUSES} label={"Cohort Status"} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CourseSearch courseSearchTerm={courseSearchTerm} setCourseSearchTerm={setCourseSearchTerm} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <CohortDisplayTable cohorts={filteredCohorts || []}/>
                </Grid>
            </Grid>
        </>
    )
}

export default SupervisorCohorts