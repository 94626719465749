import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Divider, Grid, Paper, Typography } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { YELLOW } from '../assets/colors'
import { updateCartContent } from '../redux/actions';
import CartPath from './supervisor_cart/CartPath';
import { pathwayColorMap } from '../assets/colors'
import { handleKeyEvent, buildGQLDispatchConfig } from '../lib/util'


const useStyles = makeStyles((theme) => ({
    clearCart: {
        cursor: "pointer",
        display:"inline",
        textDecoration: "underline",
        float:"right", 
    },
    popverArrow: {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: '1.25rem',
        width: 10,
        height: 10,
        backgroundColor: '#FFFFFF',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    }
  }));

function SupervisorCart() {
    const classes=useStyles()
    const dispatch=useDispatch()
    const [loading, setLoading] = useState(false)
    const cartContent = useSelector(state => state.cartContent)
    const learnerPaths = useSelector(state => state.learnerPaths)
    const currentOrg = useSelector(state => state.currentOrg)
    const pathwayCodes = Object.keys(cartContent)

    function handleClickClearCart(){
        localStorage.removeItem('cartContent')
        dispatch(updateCartContent({}))
    }

    function handleClickCartAsPdf() {
        console.log('cartPDF')

        const cartWithColors = pathwayCodes.reduce((obj, code) => {
            obj.push({
                pathwayCode: code,
                courses: [...cartContent[code]],
                color: pathwayColorMap[code]
            })  
                
            return obj
        }, [])

        
        setLoading(true)
        const api_url = process.env.REACT_APP_API_URL
        const query = `query {
            CartPDF(orgSubdomain: "${currentOrg.subdomain}", cartContent: ${JSON.stringify(JSON.stringify(cartWithColors))}) {
              pdf
              filename
            }
          }`

        const configObj = buildGQLDispatchConfig(query);

        fetch(api_url, configObj)
        .then((res) => res.json())
        .then((json) => { 
            const { pdf, filename } = json.data.CartPDF
            
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
        
            downloadLink.href = linkSource;
            downloadLink.download = filename;
            downloadLink.click();
            downloadLink.remove()
            setLoading(false)

        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }

    return(
        <div style={{padding:"1rem", height:"100%", position:"relative", overflow:"auto"}}>
             {/* <div className={classes.popverArrow}/> */}
            {pathwayCodes.length > 0
            ?
            <>
                <Typography variant="overline" tabIndex="0" className={classes.clearCart} onClick={handleClickClearCart} onKeyUp={(e) => handleKeyEvent(e, function(){ return handleClickClearCart() })}>
                    Clear Cart
                </Typography>
                <Grid container style={{paddingTop: "0.5rem"}}>
                    <Grid item xs={12}>
                        <Paper elevation={0} style={{backgroundColor: "rgba(240, 199, 23, 0.08)", padding:"0.5rem"}}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <WarningAmberIcon style={{color: YELLOW, paddingTop: "0.25rem"}}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="subtitle1">
                                        Course Removal Warning
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Removing courses from a pathway may cause a future course to be inaccessible.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                    {
                        pathwayCodes.map((pathwayCode, index) => {
                            const pathway = learnerPaths.find(pathway => pathwayCode === pathway.pathwayCode)
                            return(
                                <CartPath key={"cart-path-"+index} pathway={pathway} pathwayCode={pathwayCode}/>
                            )
                        })
                    }
                    </Grid>
                    <Divider style={{width:'100%', height:"4px", margin:"0.5rem 0rem 1rem 0rem"}}/>
                    <Grid item xs={12}>
                        <Typography>
                            Schedule time with Data Society to review your course selections.
                        </Typography>
                        <Grid container spacing={1} style={{marginTop:"0.5rem"}}>
                            <Grid item>
                                <Button href="https://dsmarketing.datasociety.com/dmitri-adler-meldr" target="_blank" rel='noreferrer noopener' variant="contained" color="secondary">
                                    Schedule Meeting
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    onClick={handleClickCartAsPdf} 
                                    color="secondary" 
                                    variant="outlined"
                                    disabled={loading}
                                    startIcon={loading ? <CircularProgress size={20} color="secondary" /> : <GetAppOutlinedIcon />}>
                                    Cart as PDF
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                 
            </>
            :
                <Typography variant="h6" align="center">There are no items in your cart</Typography>
            }
        </div>
    )
}

export default SupervisorCart