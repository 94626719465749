import React, { useState } from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ProfileTag from '../ProfileTag'
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon'
import { COHORT_MATERIAL_STATUSES } from "../../lib/status"

const useStyles = makeStyles((theme) => ({
    expandCollapseText: {
        cursor: "pointer",
        display: "inline",
        textDecoration: "underline",
        color: theme.palette.primary.main,  
        fontSize:"0.7rem",
        marginTop: "0.5rem"
    },
    pathHeading: {
        fontSize: '.75rem',
        color: theme.palette.primary.main,
        opacity: .64,
        textTransform: 'uppercase'
    },
    contentBtn: {
        marginTop: "1rem"
    }
}));

function PendingFlag({children}){
    return <>
                <div style={{margin:"-.5rem -.5rem 0rem -.5rem", height:"4px", background: "#B3364C", position:"relative"}} />
                <div style={{margin: "-.25rem -.5rem 1.5rem -.5rem", position:"relative" }}>
                    <Typography display="inline" align="right" style={{background:"#B3364C", padding: "0 .5rem", right: "0px", color: "white", fontWeight: "bold", position:"absolute"}}>
                        {children}
                    </Typography>
                </div>
            </>
}

function ProfileCard({courseObj, cardType}) {
    const classes = useStyles()
    const [expandedTags, setExpandedTags] = useState(false)

    const cardDateTextMap = {
        COMPLETED: "Completed",
        WAITLIST: "Joined the Waitlist",
        REQUEST: "Requested"
    }

    const bannerTypeMap = {
        WAITLIST: "On the Waitlist",
        REQUEST: "Requested"
    }

    const handleExpandTagsKeyUp = (event, expandedTags) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            setExpandedTags(!expandedTags)
          }
    }

    const courseTags = (index, tag) => {
        return <ProfileTag key={index} tagText={tag}/>
    }

    return(
        <Grid item xs={6} xl={4}>
            <Paper elevation={0} style={{padding:".5rem", backgroundColor:"#fafafa"}}>
                {
                    cardType !== "COMPLETED" &&
                    <PendingFlag>
                        {bannerTypeMap[cardType]}
                    </PendingFlag>
                }
                <Grid container spacing={1} style={{}}>
                    <Grid item style={{display:'flex', alignItems:'center'}}>
                        {
                            cardType === "COMPLETED"?
                                <CheckCircleIcon fontSize='large' style={{color:'#00E676'}}/>
                            :
                                <PendingIcon fontSize='large' style={{color:'#FF7700'}}/>
                        }
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className={classes.pathHeading}>{courseObj.pathName?.toUpperCase()}</Typography>
                        <Typography style={{color:'#030721', fontWeight: 700}}>{courseObj.courseName}</Typography>
                        <Typography variant="subtitle2">{cardDateTextMap[cardType]} {DateTime.fromISO(courseObj.dateCompleted).toLocaleString(DateTime.DATE_MED)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            { expandedTags 
                                ? courseObj.tagsList.map((tag, index)=> { return courseTags(index, tag) })
                                : courseObj.tagsList.slice(0, 3).map((tag, index)=> { return courseTags(index, tag) })
                            }
                        </Grid>
                        <Grid container>
                            {courseObj.tagsList.length > 3 && 
                                <Typography 
                                    tabIndex="0" 
                                    className={classes.expandCollapseText} 
                                    onClick={()=>setExpandedTags(!expandedTags)} 
                                    onKeyUp={(e)=>handleExpandTagsKeyUp(e, expandedTags)}
                                >
                                    {expandedTags? "VIEW LESS TAGS": "VIEW ALL TAGS"}
                                </Typography>
                            }
                        </Grid>
                        { cardType === "COMPLETED" && courseObj.materialsStatus.toUpperCase() === COHORT_MATERIAL_STATUSES.available.label.toUpperCase() &&
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    className={classes.contentBtn}
                                    href={`/course-content/${courseObj?.courseSlug}/${courseObj?.cohortId}`}
                                >
                                    View Course Content
                                    
                                </Button>
                            </Grid>
                        }   
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default ProfileCard