import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  // Google Analytics
  useEffect(() => {
    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID, {testMode: process.env.NODE_ENV === 'test'});
    } else {
        console.warn("No GA4 measurement ID was provided");
    }
  }, [location]);
};

export default usePageTracking;
