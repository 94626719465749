import { buildGQLDispatchConfig, getTenantDomain } from "../lib/util";

const api_url = process.env.REACT_APP_API_URL

const fetchCohorts = (orgId) => {
  const query = `{
      cohortStatuses: allCohortstatusesList(condition: {orgId:${orgId}}) {
        orgId
        courseCode
        cohortStatus
        enrollmentStatus
        materialsStatus
        cohortId
        seatsRemaining
        dates {
          dateAvailable
          dateArchived
          dateStart
          dateEnd
          enrollmentStart
          enrollmentEnd
        }
        cohort {
          id
          coursePurchaseId
          dateStart
          dateEnd
          enrollmentStart
          enrollmentEnd
          externalDelivery
          coursePurchase {
            path {
              name
              pathwaySlug
              pathwayCode
            }
            course {
              id
              name
              courseCode
              description
              courseSlug
              tags
              hours
              courseStructure
              partnership {
                id
                name
                color
                logo
                orientationDocs
              }
              coursePurchasesByCourseIdList(condition:{orgId:${orgId}}) {
                organization: org {
                  id
                  name
                }
                cohortsByCoursePurchaseIdList{
                  id
                  dateStart
                  dateEnd
                  enrollmentStart
                  enrollmentEnd
                }
              }
            }
          }
        }
      }
    }`
  const configObj = buildGQLDispatchConfig(query);
  return (dispatch) => {
      fetch(
          api_url,
          configObj
      )
          .then((resp) => resp.json())
          .then((json) => {
              // Transform results into expected shape
              const cohortStatuses = json.data.cohortStatuses
              const cohorts = cohortStatuses.map( cstat => {
                // Pull cohort out of stat and remove stat from hierarchy
                const cohort = cstat.cohort
                cstat.cohort = undefined

                // embed statusDetail object
                cohort.statusDetails = cstat

                // duplicate the 'cohortStatus' as 'status' to replace deprecated 'addCohortStatus' fn
                cohort.status = cstat.cohortStatus.toLowerCase()
                cohort.materialsStatus = cstat.materialsStatus.toLowerCase()

                return cohort
              })

              dispatch(updateCohorts(cohorts))
          })
          .catch((err) => console.log(err));
  };
};

const fetchCurrentOrgDetails = () => {
    const subdomain = getTenantDomain();
    const query = `{
      allOrganizationsList(condition: {subdomain: "${subdomain}"}) {
        id
        name
        preferences
        tenantId
        featCohortEnrollment
        featCohortRequest
        featLogin
        featPayment
        subdomain
        featPathwayEnrollment
      }
    }`
    const configObj = buildGQLDispatchConfig(query);
    return (dispatch) => {
        fetch(
            api_url,
            configObj
        )
            .then((resp) => resp.json())
            .then((json) => {
                const org = json.data.allOrganizationsList[0] || null;
                // defaults in good standing to true to avoid flashing banner
                dispatch(updateCurrentOrg(org))
                dispatch(fetchLearnerPaths(org.id))
                dispatch(fetchCohorts(org.id))
            })
            .catch((err) => console.log(err));
    };
};



const fetchLearnerPathsForSupervisors = (foundOrgId) => {
    const query = `{
        organizationById(id:${foundOrgId}) {
          learnerPathsByOrgIdList(orderBy:  POSITION_ASC) {
            id
            name
            pathwayCode
            pathwaySlug
            pathwayIcon
            pathwayGroup
            description
            learnerPathSegmentsByPathIdList(orderBy:  POSITION_ASC) {
              # alias 'right' as 'course'
              course: right {
                id
                courseCode
                courseSlug
                name
                description
                hours
                prerequisitesDescription
                softwareRequirements
                tags
                # alias 'courseStructure' as 'topics'
                topics: courseStructure
                coursePurchasesByCourseIdList(condition:{orgId:${foundOrgId}}) {
                  courseId
                  # allias 'org' as 'organization'
                  organization: org {
                    id
                    name
                    subdomain
                  }
                  cohortsByCoursePurchaseIdList{
                    id
                    deliveryId
                    deliveryLinks
                    liveSessionLink
                    materialsId
                    dateStart
                    dateEnd
                    enrollmentStart
                    enrollmentEnd
                    coursePurchase {
                      course {
                        id
                        name
                        description
                        tags
                        prerequisitesDescription
                        softwareRequirements
                        hours
                        courseStructure
                      }
                    }
                    enrollmentsByCohortIdList{
                      id
                      cohortId
                      learnerId
                    }
                    cohortSessionsByCohortIdList (orderBy:  START_TIMESTAMP_ASC) {
                      startTimestamp
                      durationHours
                    }
                  }
                }
                # filtering out processed votes
                courseVotesByCourseIdList(condition: {processedDate: null}) {
                  user {
                    email
                    firstName
                    lastName
                    orgUnitName
                    orgId
                  }
                }
              }
            }
          }
        }
      }`
    const configObj = buildGQLDispatchConfig(query);
    return (dispatch) => {
        fetch(
            api_url,
            configObj
        )
            .then((resp) => resp.json())
            .then((json) => {
                const learnerPaths = json.data.organizationById.learnerPathsByOrgIdList
                const filteredVotesLearnerPaths = learnerPaths.map(path => {
                  //TODO: Remove filtering of existence of segment.course when DB is updated
                  const learnerPathSegments = path.learnerPathSegmentsByPathIdList.filter(segment => segment.course).map(segment => {
                      const courseVotes = segment.course.courseVotesByCourseIdList
                      .filter(vote => 
                          vote.user.orgId === foundOrgId
                        )
                      const updatedCourse = {...segment.course, courseVotesByCourseIdList: courseVotes, pathName: path.name, pathwayCode: path.pathwayCode, pathwaySlug: path.pathwaySlug}
                      return {course: updatedCourse}
                    }
                    )
                  return {...path, learnerPathSegmentsByPathIdList: learnerPathSegments}
                })
                dispatch(updateLearnerPaths(filteredVotesLearnerPaths))
                const coursesArray = []
                filteredVotesLearnerPaths.forEach(path=> {
                  const pathSegments=path.learnerPathSegmentsByPathIdList
                  pathSegments.forEach(segment=>{
                    coursesArray.push(segment.course)
                  })
                })
                dispatch(updateAllCourses(coursesArray))
            })
            .catch((err) => console.log(err));
    };
};

const fetchLearnerPaths = (foundOrgId) => {
    const query = `{
        organizationById(id:${foundOrgId}) {
          learnerPathsByOrgIdList(orderBy:  POSITION_ASC) {
            id
            name
            description
            pathwayCode
            pathwaySlug
            pathwayIcon
            pathwayGroup
            learnerPathSegmentsByPathIdList(orderBy:  POSITION_ASC) {
              # alias 'right' as 'course'
              course: right {
                id
                courseCode
                courseSlug
                name
                description
                hours
                prerequisitesDescription
                softwareRequirements
                tags
                # alias 'courseStructure' as 'topics'
                topics: courseStructure
                coursePurchasesByCourseIdList(condition:{orgId:${foundOrgId}}) {
                  courseId
                  # allias 'org' as 'organization'
                  organization: org {
                    id
                    name
                    subdomain
                  }
                  price
                  currency
                  cohortsByCoursePurchaseIdList{
                    id
                    deliveryId
                    deliveryLinks
                    liveSessionLink
                    materialsId
                    dateStart
                    dateEnd
                    enrollmentStart
                    enrollmentEnd
                    cohortSessionsByCohortIdList (orderBy:  START_TIMESTAMP_ASC) {
                      startTimestamp
                      durationHours
                    }
                    coursePurchase {
                      course {
                        id
                        name
                        description
                        tags
                        prerequisitesDescription
                        softwareRequirements
                        hours
                        courseStructure
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    const configObj = buildGQLDispatchConfig(query);
    return (dispatch) => {
        fetch(
            api_url,
            configObj
        )
            .then((resp) => resp.json())
            .then((json) => {
                const learnerPaths = json.data.organizationById.learnerPathsByOrgIdList
                const filteredVotesLearnerPaths = learnerPaths.map(path => {
                  //TODO: Remove filtering of existence of segment.course when DB is updated
                  const learnerPathSegments = path.learnerPathSegmentsByPathIdList.filter(segment => segment.course).map(segment => {
                      const updatedCourse = {...segment.course, pathName: path.name, pathwayCode: path.pathwayCode, pathwaySlug: path.pathwaySlug}
                      return {course: updatedCourse}
                    }
                    )
                  return {...path, learnerPathSegmentsByPathIdList: learnerPathSegments}
                })
                dispatch(updateLearnerPaths(filteredVotesLearnerPaths))
                const coursesArray = []
                filteredVotesLearnerPaths.forEach(path=> {
                  const pathSegments=path.learnerPathSegmentsByPathIdList
                  pathSegments.forEach(segment=>{
                    coursesArray.push(segment.course)
                  })
                })
                dispatch(updateAllCourses(coursesArray))
            })
            .catch((err) => console.log(err));
    };
};

const fetchLearnerCurrentCohorts = (learnerId) => {
  const query = `{
    allLearnercohortstatusesList (condition: {learnerId: ${learnerId}}) {
      courseCode
      learnerId
      learnerStatus
      cohortStatus
      materialsStatus
      tags
      enrollment {
        cohort {
          id
          dateStart
          dateEnd
          coursePurchase {
            course {
              id
              name
              courseCode
              courseSlug
              tags
              hours
              courseStructure
              partnership {
                id
                name
                color
                logo
                orientationDocs
              }
            }
            path {
              name
              pathwaySlug
              pathwayCode
            }
          }
          cohortSessionsByCohortIdList (orderBy:  START_TIMESTAMP_ASC) {
            startTimestamp
            durationHours
          }
        }
      }
    }
  }`
  
  const configObj = buildGQLDispatchConfig(query);

  return (dispatch) => {
    fetch(api_url, configObj)
      .then((resp) => resp.json())
      .then((json) => {
          const cohorts = json.data.allLearnercohortstatusesList
          dispatch(updateLearnerCurrentCohorts(cohorts))
      })
      .catch((err) => console.log(err));
  }
}

const updateAllCourses = (courses) => {
  return{
    type: "UPDATE_ALL_COURSES",
    payload: courses
  }
}

const updateCartContent = (courses) => {
  return {
    type: "UPDATE_CART_CONTENT",
    payload: courses
  }
}

const updateCohorts = (cohorts) => {
  return {
    type: "UPDATE_COHORTS", 
    payload: cohorts
  }
}

const updateCourseRegistrations = (registeredCohorts) => {
  return {
    type: "UPDATE_COURSE_REGISTRATIONS",
    payload: registeredCohorts
  }
}

const updateCourseRequests = (requestedCourses) => {
  return {
    type: "UPDATE_COURSE_REQUESTS",
    payload: requestedCourses
  }
}

const updateCurrentOrg = (org) => {
    return {
      type: "UPDATE_CURRENT_ORG",
      payload: org
    }
}

const updateCurrentUser = (user) => {
    return {
      type: "UPDATE_CURRENT_USER",
      payload: user
    }
}

const updateFoundUser = (user) => {
  return {
    type: "UPDATE_FOUND_USER",
    payload: user
  }
}

const clearFoundUser = () => {
  return {
    type: "CLEAR_FOUND_USER"
  }
}

const clearLearnerInformation = () => {
  return {
    type: "CLEAR_LEARNER_INFORMATION"
  }
}

const updateLearnerInformation = (info) => {
    return {
        type: "UPDATE_LEARNER_INFORMATION",
        payload: info
    }
}

const updateLearnerInfoForSupervisor = (info) => {
  return {
      type: "UPDATE_LEARNER_DETAIL",
      payload: info
  }
}

const updateLearnerPaths = (paths) => {
  return {
    type: "UPDATE_PATHS", 
    payload: paths
  }
}

const updateLoginOpen = (bool) => {
  return {
    type: "UPDATE_LOGIN_OPEN",
    payload: bool
  }
}

const updateSignupOpen = (bool) => {
  return {
    type: "UPDATE_SIGNUP_OPEN",
    payload: bool
  }
}

const updateSnackbarData = (message, bool=false, notificationType='success') => {
  return {
    type: "UPDATE_SNACKBAR_DATA",
    payload: {open: bool, message: message, notificationType: notificationType}
  }
}

const updatePathwayRegistrations = (registeredPathways) => {
  return {
    type: "UPDATE_PATHWAY_REGISTRATIONS",
    payload: registeredPathways
  }
}

const updateTopBarText = (text) => {
  return {
    type: "UPDATE_TOP_BAR_TEXT",
    payload: text
  }
}

const updateCourseSlides = (courses) => {
  return {
    type: "UPDATE_COURSE_SLIDES",
    payload: courses
  }
}

const updateLearnerCurrentCohorts = (cohorts) => {
  return {
    type: "UPDATE_CURRENT_COHORTS", 
    payload: cohorts
  }
}

const updateFoundUserProfile = (user) => {
  return {
    type: "UPDATE_FOUND_USER_PROFILE", 
    payload: user
  }
}

export {
    clearFoundUser,
    clearLearnerInformation,
    fetchCohorts,
    fetchCurrentOrgDetails,
    fetchLearnerPathsForSupervisors,
    fetchLearnerCurrentCohorts,
    updateSnackbarData,
    updateCartContent, 
    updateCourseRegistrations,
    updateCurrentOrg,
    updateCourseRequests,
    updateCurrentUser,
    updateFoundUser,
    updateLearnerInformation,
    updateLearnerInfoForSupervisor,
    updateLoginOpen, 
    updateSignupOpen,
    updatePathwayRegistrations,
    updateCourseSlides,
    updateTopBarText,
    updateLearnerCurrentCohorts,
    updateFoundUserProfile,
}
