import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

const SiteTourStep = ({ stepNo, stepTotal, title, subText, handleClickNext, handleClickEnd }) => {
    const lastStep = stepNo === stepTotal 
    return (
        <div style={{textAlign:"left", margin:"-1rem 0rem -1.5rem 0rem"}}>
            <Typography variant="overline" style={{color:"#E4E4E4"}}>{stepNo}/{stepTotal}</Typography>
            <Typography variant="body1" style={{fontWeight:600}}>{title}</Typography>
            <Typography style={{fontSize:'0.875rem', lineHeight:"1.25rem"}}>{subText}</Typography>
            <Grid container spacing={1} style={{color:"#FFF", marginTop:"1rem"}}>
            {
                lastStep?
                    <Grid item>
                        <Button 
                            size="small"
                            style={{
                                backgroundColor:"rgba(33, 50, 255, 1)", 
                                color:"inherit"
                            }}
                            onClick={handleClickEnd}
                        >
                            Finish
                        </Button>
                    </Grid>     
                :
                    <>
                        <Grid item>
                            <Button 
                                size="small"
                                style={{
                                    backgroundColor:"rgba(33, 50, 255, 1)", 
                                    color:"inherit"
                                }}
                                onClick={handleClickNext}
                            >
                                Next
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                size="small"
                                style={{
                                    backgroundColor:"rgba(33, 50, 255, 0.4)",
                                    color:"inherit"
                                }}
                                onClick={handleClickEnd}
                                >
                                Skip
                            </Button>
                        </Grid>
                    </>
            }
            </Grid>
        </div>
    )
}

export default SiteTourStep