import React from 'react'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TopBackground from '../../assets/imgs/top-background.jpg'
import { VIEW_TYPES } from '../../lib/globalKeys';
import { PURCHASE_URL } from '../../lib/coursePathwayHelp';

const useStyles = makeStyles((theme) => ({
    headerBackground: {
        padding:"2rem", 
        backgroundImage: `url(${TopBackground})`, 
        backgroundSize: "cover"
    },
    headerText: {
        color:"#ffffff",
    },
    headerSubtitle:{
        color:"#ffffff",
        paddingTop:"1rem"
    }
}));

function CardsContainer({children, view}){
    const classes = useStyles()

    return(
        <Grid container style={{paddingBottom:"0.5rem"}}>
                {
                    view === VIEW_TYPES.SUPERVISOR ?
                        <Grid container item className={classes.headerBackground}>
                            <Paper style={{padding:"1rem"}} elevation={0}>
                                <Typography variant="h6" style={{fontWeight:"bold", paddingBottom:"0.5rem"}}>
                                    Click here to purchase more courses.
                                </Typography>
                                <Button 
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>window.open(PURCHASE_URL)}>
                                    Purchase
                                </Button>
                            </Paper>
                        </Grid>
                    :
                        <Grid item xs={12} className={classes.headerBackground}>
                            <Typography variant="h5" className={classes.headerText}>
                                Find the right path to advance
                            </Typography>
                            <Typography variant="h5" className={classes.headerText}>
                                your skills and your career
                            </Typography>
                            <Typography variant="subtitle1" className={classes.headerSubtitle}>
                                Use the navigation to the left to find the right path for you.
                            </Typography>
                        </Grid>
                }
           
            {children}
        </Grid>
    )
}

export default CardsContainer