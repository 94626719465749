import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import PathwaysMapSVGContainer from '../components/pathways_map/PathwaysMapSVGContainer'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button, ButtonGroup } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import '../pathways_map.css'
import SelectionDrawer from './SelectionDrawer';
import { VIEW_TYPES, INITIAL_SELECTED_COURSE } from '../lib/globalKeys'
import { updateTopBarText } from '../redux/actions'
import { mappedSteps } from '../lib/joyride';
import CustomJoyride from '../components/joyride/CustomJoyride';

const useStyles = makeStyles({
    'overlay-buttons':{
      position: 'absolute',
      zIndex: 1,
      bottom: '2rem',
      left: '8rem',
    }
  });

function PathwaysMap() {
    const classes=useStyles()
    const location = useLocation()
    const dispatch=useDispatch()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selection, setSelection] = useState(INITIAL_SELECTED_COURSE)
    const [selectionType, setSelectionType] = useState('')
    const [isSupervisorView, setIsSupervisorView] = useState(false)
    const joyRideRef = useRef(null)
    const [runTour, setRunTour] = useState(false)
    const [initialValues, setInitialValues] = useState({zoom:1, x:0, y: 0})
    const [loadingTour, setLoadingTour] = useState(true)
    const openLoginForm = useSelector(state => state.openLoginForm)
    const openSignupForm = useSelector(state => state.openSignupForm)

    useEffect(() => {
        setIsSupervisorView(location.pathname.includes("/admin/"))
    }, [location.pathname])

    // Update Top Bar Heading
    useEffect(() => {
        if(isSupervisorView){
            dispatch(updateTopBarText('View & Purchase Pathways'))
        } else {
            dispatch(updateTopBarText('Pathways'))
        }
    }, [dispatch, isSupervisorView])

    // Check if tour has already run
    useEffect(()=>{
        if(localStorage.getItem('pathwaysTour')){
            setRunTour(false)
        }
        else if(openLoginForm || openSignupForm){
            setRunTour(false)
            setInitialValues({zoom:3, x:-1200, y: -200})
        }
        else{
            setInitialValues({zoom:3, x:-1200, y: -200})
            setTimeout(() => {
                setRunTour(true)
            }, 500)
        }
        setLoadingTour(false)
    },[openSignupForm, openLoginForm])

    const handleClickNext = () => joyRideRef.current.store.next()
    const handleClickEnd = () => {
        localStorage.setItem('pathwaysTour', true)
        return joyRideRef.current.store.stop()
    }

    const handleJoyrideCallback = (props) => {
        if(props.action === 'stop' && !(openLoginForm || openSignupForm)){
            const resetZoomBtn = document.getElementById("hidden-reset-zoom-btn")
            resetZoomBtn.click()
        }
    }

    const pathwaysSteps = [
        {
            target: '#_-ot-pathwayNode-ot-pc-PT002-pc-',
            stepText: "Start here to explore courses and pathways.",
            placement: 'top-start',
        },
        {
            target: '#_-ot-courseNode-ot-cc-CR00006-cc-pc-PT002-pc-',
            stepText: "Click on a course or pathway node for more details.",
            placement: 'bottom-end',
        }
    ]

    const mappedPathwaysSteps = mappedSteps(pathwaysSteps, "Find Your Path", handleClickNext, handleClickEnd)

    return(
        <>
            {
                !loadingTour &&
                <>
                    <TransformWrapper 
                        initialScale={initialValues.zoom}
                        initialPositionX={initialValues.x}
                        initialPositionY={initialValues.y}
                    >
                    {({ zoomIn, zoomOut, setTransform, ...rest }) => (
                        <>
                            <ButtonGroup
                                orientation="vertical"
                                variant='contained'
                                aria-label="zoom functionality"
                                className={classes['overlay-buttons']}
                            >
                                <Button
                                    onClick={() => zoomIn()}
                                    style={{padding:'5px'}}
                                    color="secondary"
                                >
                                    <AddIcon />
                                </Button>,
                                <Button 
                                    onClick={() => zoomOut()}
                                    style={{padding:'5px'}}
                                    color="secondary"
                                >
                                    <RemoveIcon />
                                </Button>
                            </ButtonGroup>
                            <Button
                                id="hidden-reset-zoom-btn"  
                                onClick={() => setTransform(0,0,1)}
                                style={{display:'none'}}
                            />
                            <TransformComponent>
                                <div className="scaling-svg-container">
                                    <PathwaysMapSVGContainer 
                                        className="scaling-svg"
                                        drawerOpen={drawerOpen} 
                                        setDrawerOpen={setDrawerOpen} 
                                        setSelection={setSelection}
                                        setSelectionType={setSelectionType}
                                    />
                                </div>
                            </TransformComponent>
                        </>
                    )}
                    </TransformWrapper>
                    <SelectionDrawer 
                        drawerOpen={drawerOpen} 
                        setDrawerOpen={setDrawerOpen} 
                        view={isSupervisorView ? VIEW_TYPES.SUPERVISOR : VIEW_TYPES.LEARNER} 
                        selection={selection} 
                        setSelection={setSelection}
                        selectionType = {selectionType}
                    />
                    <CustomJoyride 
                        ref={joyRideRef}
                        steps={mappedPathwaysSteps}
                        callback={handleJoyrideCallback}
                        run={runTour}
                    />
                </>
            }
        </>
    )
}

export default PathwaysMap