import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { GREEN } from '../../assets/colors'
import CheckIcon from '@material-ui/icons/Check';

const TextIconIndicator = ({ text, color, icon }) => {

  const colorMap = {
    green: GREEN,
    default: GREEN
  }

  const iconMap = {
    check: <CheckIcon />,
    default: <CheckIcon />
  }

  return (
    <Grid 
      container 
      alignItems="center" 
      spacing={1} 
      style={{color: (colorMap[color] || colorMap.default)}}
    >
        <Grid item>
            <Typography >
              {text}
            </Typography>
        </Grid>
        <Grid item>
            {iconMap[icon] || iconMap.default}
        </Grid>
    </Grid>
  )
}

export default TextIconIndicator