import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { pathIconComponent } from '../../lib/mappedPathIcons';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { handleKeyEvent } from '../../lib/util'
import { updateCartContent } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    lightText: {
        color: theme.palette.primary[300]
    },
    removeCart: {
        cursor: "pointer",
        display:"inline",
        textDecoration: "underline",
    },
    squareIcon: {
        width:"1rem", 
        paddingTop:"4px",
        color: theme.palette.primary[300]
    },
    verticalIcon:{
        paddingTop:"6px",
        color: theme.palette.primary[300]
    },
  }));

function CartPath({ pathway, pathwayCode }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(true);
    const cartContent = useSelector(state => state.cartContent)
    const cartCourseCodes = cartContent[pathwayCode]

    function cartCourses(pathway, cartCourseCodes){
        const pathSegments = pathway.learnerPathSegmentsByPathIdList
        const allPathwayCourses = pathSegments
        let cartCoursesArray = []
        allPathwayCourses.forEach((courseObj)=> {
            if(cartCourseCodes.includes(courseObj.course.courseCode)){
                cartCoursesArray.push(courseObj.course)
            }
        })
        return cartCoursesArray
    }

    function totalHours(coursesArray){
        const initialValue = 0
        const sum = coursesArray.reduce((previousValue, currentValue) => {
            return previousValue + parseInt(currentValue.hours)
        }, initialValue)
        return sum
    }

    function handleClickRemovePathway(pathwayCode){
        const updatedCartContent = {...cartContent}
        delete updatedCartContent[pathwayCode]
        const stringifiedCartContent=JSON.stringify(updatedCartContent)
        localStorage.setItem('cartContent', stringifiedCartContent)
        dispatch(updateCartContent(updatedCartContent))
    }

    function handleClickRemoveCourse(course){
        const updatedCourseArray = cartContent[course.pathwayCode].filter(cartCourse => cartCourse !== course.courseCode)
        // if removing the last course, remove the pathway
        if(updatedCourseArray.length<1){
            handleClickRemovePathway(course.pathwayCode)
        } else {
            const updatedCartContent = {...cartContent, [course.pathwayCode]: updatedCourseArray}
            const stringifiedCartContent=JSON.stringify(updatedCartContent)
            localStorage.setItem('cartContent', stringifiedCartContent)
            dispatch(updateCartContent(updatedCartContent))
        }
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Grid container spacing={1} justifyContent="space-between" alignItems="flex-start" style={{paddingTop: "1rem", width:"100%"}}>
                <Grid item style={{paddingTop:"0.75rem"}}>
                    {pathIconComponent(pathway.pathwayCode, {height:"2rem", width:"2rem", display:"inherit", margin:"auto"})}
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h6">
                        {pathway.name}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <ScheduleIcon className={classes.squareIcon} />
                                </Grid>
                                <Grid item style={{padding:'0rem'}}>
                                    <Typography className={classes.lightText} variant="subtitle2">
                                        {totalHours(cartCourses(pathway, cartCourseCodes))} Hours
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <BookOutlinedIcon className={classes.verticalIcon} />
                                </Grid>
                                <Grid item style={{padding:'0rem'}}>
                                    <Typography className={classes.lightText} variant="subtitle2">
                                        {cartCourses(pathway, cartCourseCodes).length} Courses
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="overline" tabIndex="0" className={classes.removeCart} onClick={() => handleClickRemovePathway(pathwayCode)} onKeyUp={(e) => handleKeyEvent(e, function(){ return handleClickRemovePathway(pathwayCode) })}>
                            Remove
                    </Typography>
                </Grid>
                <Grid item style={{marginTop:"-0.5rem"}}>
                    <IconButton onClick={handleExpandClick}>
                        <ExpandMoreIcon className={
                            clsx(classes.expand, 
                                {[classes.expandOpen]: expanded,
                        })}/>
                    </IconButton>
                </Grid>
            </Grid>
            <Divider style={{width:'100%'}}/>
            { expanded &&
                <Grid container style={{paddingTop:"0.5rem", paddingBottom:"0.5rem"}}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                            Courses Included
                        </Typography>
                    </Grid>
                    {
                        cartCourses(pathway, cartCourseCodes).map((course, index) => {
                            return (
                                <Grid item xs={12} key={`${index}-course-item`}>
                                    <Grid container justifyContent='space-between'>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{paddingLeft:"0.5rem"}}>
                                                {course.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="overline" tabIndex="0" className={classes.removeCart} onClick={() => handleClickRemoveCourse(course)} onKeyUp={(e) => handleKeyEvent(e, function(){ return handleClickRemoveCourse(course) })}>
                                                Remove
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </>
    )
}

export default CartPath