import React from 'react';
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';
import { ALL_PATHS_TEXT } from './globalKeys';
import { ReactComponent as Pathways } from '../assets/imgs/icons/pathways.svg'
import { ReactComponent as PT001} from '../assets/imgs/icons/pathway_icons/PT001_color_diamond.svg'
import { ReactComponent as PT002} from '../assets/imgs/icons/pathway_icons/PT002_color_diamond.svg'
import { ReactComponent as PT003} from '../assets/imgs/icons/pathway_icons/PT003_color_diamond.svg'
import { ReactComponent as PT004} from '../assets/imgs/icons/pathway_icons/PT004_color_diamond.svg'
import { ReactComponent as PT005} from '../assets/imgs/icons/pathway_icons/PT005_color_diamond.svg'
import { ReactComponent as PT006} from '../assets/imgs/icons/pathway_icons/PT006_color_diamond.svg'
import { ReactComponent as PT007} from '../assets/imgs/icons/pathway_icons/PT007_color_diamond.svg'
import { ReactComponent as PT008} from '../assets/imgs/icons/pathway_icons/PT008_color_diamond.svg'
import { ReactComponent as PT009} from '../assets/imgs/icons/pathway_icons/PT009_color_diamond.svg'
import { ReactComponent as PT010} from '../assets/imgs/icons/pathway_icons/PT010_color_diamond.svg'
import { ReactComponent as PT011} from '../assets/imgs/icons/pathway_icons/PT011_color_diamond.svg'
import { ReactComponent as PT012} from '../assets/imgs/icons/pathway_icons/PT012_color_diamond.svg'
import { ReactComponent as PT013} from '../assets/imgs/icons/pathway_icons/PT013_color_diamond.svg'
import { ReactComponent as PT014} from '../assets/imgs/icons/pathway_icons/PT014_color_diamond.svg'
import { ReactComponent as PT015} from '../assets/imgs/icons/pathway_icons/PT015_color_diamond.svg'
import { ReactComponent as PT016} from '../assets/imgs/icons/pathway_icons/PT016_color_diamond.svg'
import { ReactComponent as PT017} from '../assets/imgs/icons/pathway_icons/PT017_color_diamond.svg'

//icons for left nav buttons and learning path headers
const pathIconComponent = (pathwayCode, styleObject={}) => {
    
    const mappedPathwayObject = {
        [ALL_PATHS_TEXT]: <Pathways style={styleObject} />,
        "PT001": <PT001 style={styleObject}/>,
        "PT002": <PT002 style={styleObject}/>,
        "PT003": <PT003 style={styleObject}/>,
        "PT004": <PT004 style={styleObject}/>,
        "PT005": <PT005 style={styleObject}/>,
        "PT006": <PT006 style={styleObject}/>,
        "PT007": <PT007 style={styleObject}/>,
        "PT008": <PT008 style={styleObject}/>,
        "PT009": <PT009 style={styleObject}/>,
        "PT010": <PT010 style={styleObject}/>,
        "PT011": <PT011 style={styleObject}/>,
        "PT012": <PT012 style={styleObject}/>,
        "PT013": <PT013 style={styleObject}/>,
        "PT014": <PT014 style={styleObject}/>,
        "PT015": <PT015 style={styleObject}/>,
        "PT016": <PT016 style={styleObject}/>,
        "PT017": <PT017 style={styleObject}/>,
        "default": <MultilineChartOutlinedIcon style={styleObject}/>
    }

    const pathwayObjectKeys = Object.keys(mappedPathwayObject)
    if (pathwayObjectKeys.includes(pathwayCode)){
        return mappedPathwayObject[pathwayCode]
    } else {
        return mappedPathwayObject.default
    }
}

export {
    pathIconComponent
}