import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateLearnerInfoForSupervisor } from '../../redux/actions'
import PropTypes from 'prop-types'
import { Avatar, Button, IconButton, TableCell, TableRow } from '@material-ui/core'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import profileImg from '../../assets/imgs/leon_profile.png'

function UsersDisplayTableRow({ row }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function handleViewLearnerProfile() {
        dispatch(updateLearnerInfoForSupervisor(row))
        navigate(`${row.id}`)
    }

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} align='left' padding="none">
            <TableCell component="th" id={row.id} scope="row" padding="none" style={{width:"0.1rem"}}></TableCell>
            <TableCell style={{width:"20%"}}>
                <Avatar 
                    style={{width:"2rem", height:"2rem", display:"inline-flex", marginRight:"0.6rem", verticalAlign:"middle"}}  
                    variant="rounded" 
                    alt={`${row.fullName}`} 
                    src={profileImg}
                />
                {`${row.fullName}`}
            </TableCell>
            <TableCell style={{width:"15%"}}>{row.jobTitle}</TableCell>
            <TableCell style={{width:"15%"}}>{row.orgUnitName}</TableCell>
            <TableCell style={{width:"20%"}}>{row.currentCourses}</TableCell>
            <TableCell style={{width:"12%"}}>{row.appRole}</TableCell>
            <TableCell> 
                <IconButton
                    aria-label="learner email"
                    size="small"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${row.email}`}
                >
                    <MailOutlineIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <Button
                    aria-label="view learner profile"
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={handleViewLearnerProfile}
                >
                View Profile
                </Button>
            </TableCell>
        </TableRow>
      </>
    );
  }
  
  UsersDisplayTableRow.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string, 
      lastName: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      orgUnitName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      currentCourses: PropTypes.string,
      appRole: PropTypes.string
    }).isRequired,
    classes: PropTypes.object.isRequired,
  };

  export default UsersDisplayTableRow