import React from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  adornedEnd: {
      padding: "0rem"
  },
  searchBox: {
      width: "28rem",
  },
  input: {
      fontSize: "small",
      backgroundColor:"#fff"
  }
}));

function UsersSearch({ searchTerm, placeholder, handleSearch, resetSearch }) {
    const classes = useStyles()
    
    return(
        <>
            <TextField 
                id="course-filter" 
                variant="outlined" 
                size="small"
                className={classes.searchBox}
                placeholder={placeholder}
                value={searchTerm}
                onChange={(event) => handleSearch(event)}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          searchTerm.length === 0 ?
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>   
                          :
                            <IconButton 
                              onClick={resetSearch} 
                            >
                              <CloseIcon/>
                            </IconButton>
                        }
                      </InputAdornment>
                    ),
                    classes: {
                      adornedEnd: classes.adornedEnd,
                    },
                    className: classes.input
                  }}
            />
        </>
    )
}

export default UsersSearch