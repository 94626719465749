import React from 'react'
import Joyride from 'react-joyride';

const CustomJoyride = React.forwardRef((props, ref) => {
    const { steps, callback, run } = props
    return <Joyride 
                id="dashboard-joyride"
                ref={ref}
                steps={steps}
                callback={callback}
                run={run}
                continuous
                hideCloseButton
                disableOverlay
                styles={{
                    buttonNext: {
                        display: 'none',
                    },
                    options: {
                        arrowColor: '#001276',
                        backgroundColor: '#001276',
                        textColor:"#FFF",
                        overlayColor: 'rgba(0, 0, 0, 0)',
                        zIndex: 1100,
                    }
                }}
            />
})

export default CustomJoyride