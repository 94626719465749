import React, { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { headCells, makeRows, stableSort } from '../../lib/usersDisplay';
import UsersDisplayTableHead from './UsersDisplayTableHead'
import UsersDisplayTableRow from './UsersDisplayTableRow'
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  header: {
    fontWeight: 'bold'
  },
  table: {
    minWidth: 400
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      fontFamily: 'inherit'
    },
    '& .Mui-selected': {
        backgroundColor: 'transparent !important',
        color: '#1426FF',
        fontWeight: 'bold'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

const UsersDisplayTable = ( {learners, page, setPage} ) => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowsPerPage = 10

    const rows = makeRows(learners)
    const totalPages = Math.ceil(rows?.length/rowsPerPage)
    const emptyRows =  rowsPerPage - Math.min(rowsPerPage, rows.length - (page-1) * rowsPerPage);
    let rowStart = rows?.length <= 10 ? 0 : (page-1) * rowsPerPage
    let rowEnd = rows?.length <= 10 ? 10: (page-1) * rowsPerPage + rowsPerPage

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(1);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    return (
        <div className={classes.root}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                >
                    <UsersDisplayTableHead
                        headCells={headCells}
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, order, orderBy)
                            .slice(rowStart, rowEnd)
                            .map((row) => {
                                return (
                                    <UsersDisplayTableRow key={row.id} row={row} classes={classes} />
                                );
                            })
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 52 * emptyRows }}>
                                <TableCell colSpan={12} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Stack 
                    spacing={2}
                    direction="row"
                    justifyContent="right" 
                    alignItems="right"
                    style={{margin: '2rem 0'}}>
                    <Typography 
                        variant='body2'
                        display="inline" 
                        sx={{fontFamily: 'inherit', paddingTop: '0.2rem'}}>
                        Page: {totalPages === 0 ? 0 : page} of {totalPages}
                    </Typography>
                    <Pagination 
                        classes={ {ul:classes.pagination} }
                        size='small'
                        count={totalPages} 
                        page={page} 
                        onChange={handleChangePage} />
                </Stack>
            </TableContainer>
        </div>
    );
}

export default UsersDisplayTable