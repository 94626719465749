import { buildGQLDispatchConfig, objectOrArrayToArray, fetchGqlJson } from './util';
import { dateParse } from './date';

const api_url = process.env.REACT_APP_API_URL

const getIsEmailDomainValid = (email='', validEmailDomains=[]) => {
    //Check email domain with split at '@'
    const emailArray = email.split('@') || []
    const emailDomain = emailArray[1]
    let isEmailValid = false

    //if emailDomain exists and is in the validEmailDomains array, return true
    if(emailDomain){
        if(validEmailDomains==="ALL"){
            return true
        }
        isEmailValid = validEmailDomains.includes(emailDomain)
    }
    return isEmailValid
}

const fetchInvoiceUrl = (item, learner, enrollmentType) => {
    
    // here item could be a path or a course
    const itemInfo = {
        name: item.name,
        description: item.description,
        itemName: enrollmentType === 'course' ? item.pathName : item.name,
        itemId: item.id,
        orgId: enrollmentType === 'course' ? item.coursePurchasesByCourseIdList[0]?.organization?.id : item.learnerPathSegmentsByPathIdList[0].course.coursePurchasesByCourseIdList[0]?.org?.id,
        organization: enrollmentType === 'course' ? item.coursePurchasesByCourseIdList[0]?.organization?.name : item.name
    }

    const learnerInfo = {
        firstName: learner.firstName,
        lastName: learner.lastName,
        email: learner.email,
        orgUnit: learner.orgUnit
    }
    const query=`query StripeInvoice {
        StripeInvoice (item_info: ${JSON.stringify(itemInfo).replace(/"([^(")"]+)":/g,"$1:")}, 
        learner_info: ${JSON.stringify(learnerInfo).replace(/"([^(")"]+)":/g,"$1:")}, enrollment_type: "${enrollmentType}"){
          invoice_pdf
        }
      }`
    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            if(json.errors){
                console.log(json.errors[0].message)
            }
            resolve(json)
        })
        .catch((err) => console.log(err));
      });
}

//enrollmentTypeId is either cohortId or pathwayId
const fetchPaymentUrl = (itemName, 
    itemDescription, 
    itemType, 
    cancelUrl, 
    successUrl, 
    learnerFullName,
    email, 
    orgUnit, 
    enrollmentTypeId
    ) => {
    const query=`query StripeCheckout {
        StripeCheckout(item_name:"${itemName}", 
            item_description:"${itemDescription}",
            item_type: "${itemType.toLowerCase()}",
            learner_name: "${learnerFullName}", 
            cancel_url: "${cancelUrl}", 
            success_url:"${successUrl}", 
            email:"${email}", 
            org_unit:"${orgUnit}",
            enrollment_type_id: ${enrollmentTypeId}) 
            {
                session_url
            }
    }`
    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            if(json.errors){
                console.log(json.errors[0].message)
            }
            resolve(json)
        })
        .catch((err) => console.log(err));
      });
}

const fetchSessionData = (sessionId) => {
    const query=`{
        SuccessfulStripeSession(session_id: "${sessionId}") {
          email
          item_name
          item_description
          item_type
          learner_name
          org_unit
          enrollment_type_id
          receipt_url
      }
    }`
    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json.data.SuccessfulStripeSession)
        })
        .catch((err) => console.log(err));
      });
}

const fetchUser = (orgId, email) => {
    const query=`{
        organizationById(id: ${orgId}){
          usersByOrgIdList(condition: {email: "${email}"}){
            name
            email
            id
            orgUnitName
          }
        }
      }`
    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json.data.organizationById.usersByOrgIdList)
        })
        .catch((err) => console.log(err));
      });
    
};

const fetchCohortById = (cohortId) => {
    const query=`{
        cohortById(id: $cohortByIdId) {
          id
          deliveryId
          deliveryLinks
          liveSessionLink
          cohortSessionsByCohortIdList {
            startTimestamp
            durationHours
          }
          coursePurchase {
            course {
              name
              description
              tags
              prerequisitesDescription
              softwareRequirements
              hours
              courseStructure
            }
          }
        }
      }`
    const configObj = buildGQLDispatchConfig(query);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json.data.cohortById)
        })
        .catch((err) => console.log(err));
      });
    
};

/**
 * Retrieves a mapping of all courses in the given path which
 * are open for enrollment.
 * @param {LearnerPath} path Learner path to inspect
 */
const getEnrollableCohortsFromPathway = (path) => {
    const coursesAndNextCohorts = path.learnerPathSegmentsByPathIdList
        // Map to segment/course tuples, computing next cohort
        //  for each course into 'nextCohort' field
        .map(segment => {
            return {
                course: segment.course,
                cohort: nextEnrollableCohort(segment.course)
            }
        })
        // Filter non-enrollable cohorts
        .filter(courseAndCohort => courseAndCohort.cohort != null)

    return coursesAndNextCohorts
}

/**
 * 
 * @param {*} learnerinfo 
 * @param {Array|int} cohortIdOrIds 
 */
const createEnrollment = async (learnerinfo, cohortIdOrIds, pathwayId=null) => {
    const _cohortIds = objectOrArrayToArray(cohortIdOrIds);

    // prepare input value as string
    const input = `{
        cohortIds: ${JSON.stringify(_cohortIds)},
        learnerEmail: "${learnerinfo.email}",
        uFirstName: "${learnerinfo.firstName}",
        uLastName: "${learnerinfo.lastName}",
        uOrgunit: "${learnerinfo.orgUnit}",
        pathwayId: ${pathwayId}
    }`;

    const mutationString = `
        mutation EnrollLearnerInCohort {
            createEnrollment:enrollInCohort(input: ${input}) {
                enrollments {
                    id
                }
            }
        }
    `
    const configObj = buildGQLDispatchConfig(mutationString)
    try {
        const json = await fetchGqlJson(api_url, configObj)
        return json.data.createEnrollment.enrollments
    } catch(err) {
        console.log('Enrollment error:', err)
    }
}

const deleteEnrollment = (enrollmentId) => {
    const mutationString = `
        mutation {
            deleteEnrollmentById(input:{id: ${enrollmentId}}) {
                enrollment {
                    id
                  }
              }
        }
    `
    const configObj = buildGQLDispatchConfig(mutationString);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json.data.deleteEnrollmentById.enrollment.id)
        })
        .catch((err) => console.log(err));
      });
}

const createCourseVote = (learnerinfo, courseId) => {
    // prepare input value as string
    const input = `{
        courseId: ${courseId},
        learnerEmail: "${learnerinfo.email}",
        uFirstName: "${learnerinfo.firstName}",
        uLastName: "${learnerinfo.lastName}",
        uOrgunit: "${learnerinfo.orgUnit}",
    }`;

    const mutationString = `
        mutation {
            createCourseVote: requestCourse(input: ${input}) {
                clientMutationId
            }
        }
    `
    const configObj = buildGQLDispatchConfig(mutationString);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json.data.createCourseVote.clientMutationId)
        })
        .catch((err) => console.log(err));
    });
}

const nextEnrollableCohort = (course) => {
    // assume only one cohort is enrollable at a time per CoursePurchase
    // will need to be updated as we implement more logic around course purchase dates
    if (course?.coursePurchasesByCourseIdList?.length > 0) {
        const cohorts = course.coursePurchasesByCourseIdList[0].cohortsByCoursePurchaseIdList
        const now = new Date()
        return cohorts.find(cohort => dateParse(cohort.enrollmentStart) <= now && dateParse(cohort.enrollmentEnd, 1) >= now)
    }
}

const futureAvailableCohort = (course) => {
    if (course.coursePurchasesByCourseIdList.length > 0){
        const cohorts = course.coursePurchasesByCourseIdList[0].cohortsByCoursePurchaseIdList
        const now = new Date()
        return cohorts.find(cohort => dateParse(cohort.enrollmentStart) > now && dateParse(cohort.enrollmentEnd) > now)
    }
}

const daysLeft = (enrollmentEnd) => {
    return Math.ceil((dateParse(enrollmentEnd) - new Date()) / (1000*60*60*24))
}

const daysLeftInCourse = (course) => {
    const cohort = nextEnrollableCohort(course)
    if (cohort) {
        return daysLeft(cohort.enrollmentEnd)
    }
}

const daysLeftMessage = (course) => {
    const days = daysLeftInCourse(course)
    switch (days) {
        case 0:
            return 'CLOSES TODAY'
        case 1:
            return `${days} DAY LEFT`
        default:
            return `${days} DAYS LEFT`
    }
}



export {
    createCourseVote,
    createEnrollment,
    deleteEnrollment,
    fetchInvoiceUrl,
    fetchUser,
    fetchPaymentUrl,
    fetchSessionData,
    fetchCohortById,
    futureAvailableCohort,
    getIsEmailDomainValid,
    getEnrollableCohortsFromPathway,
    nextEnrollableCohort,
    daysLeft,
    daysLeftInCourse,
    daysLeftMessage
}
