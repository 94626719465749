import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core'
import DashboardCourseCard from '../dashboard/DashboardCourseCard';
import { REGISTERED_LEARNER_STATUS } from "../../lib/status"
import pluralize from 'pluralize';

function CurrentCourseCard( {currentCohorts} ) {
    const foundUser = useSelector(state => state.foundUser)
    const [currentCohortStatusObjs, setCurrentCohortStatusObjs] = useState([])
    const cohorts = useSelector(state => state.cohorts)
    const [loadingCohorts, setLoadingCohorts] = useState(true)
   
    useEffect(() => {
        // If user, finds all associated learner statuses that show Registered or Taking
        async function getLearnerCohorts() {
            const registeredLearnerStatuses = [REGISTERED_LEARNER_STATUS.registered.label.toUpperCase(), REGISTERED_LEARNER_STATUS.taking.label.toUpperCase()]

            // Sort by cohort start_date and set in state
            const filteredCurrentCohortStatusObjs = currentCohorts
                ?.filter(cohort => registeredLearnerStatuses.includes(cohort.learnerStatus))
                .sort((a,b)=> a.enrollment.cohort.dateStart > b.enrollment.cohort.dateStart? 1:-1)

            setCurrentCohortStatusObjs(filteredCurrentCohortStatusObjs)
            return setLoadingCohorts(false)            
        }
        if(foundUser && cohorts){
            return getLearnerCohorts()
        }
    }, [foundUser, cohorts, currentCohorts])

    if(loadingCohorts){
        return <LinearProgress color="secondary" /> 
    }

    const isRegistered = !!(currentCohortStatusObjs?.length>0)

    const CurrentCoursesEmptyState = () =>{
        return(
            <Grid container item xs={12} spacing={1} style={{padding: ".5rem .75rem"}}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary" style={{fontWeight:700}}>
                        You're not currently registered for a course.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color='secondary'
                        href="/course-catalog"
                    >
                        View Course Catalog
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color='secondary'
                        href="/pathways"
                    >
                        View Pathways
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return(
        <section id="current-classes-container">
            <Grid container style={{marginBottom: "2rem"}}>
            {
                !isRegistered &&
                <CurrentCoursesEmptyState />
            }
            {
                isRegistered &&
                <>
                    <Grid item xs={12} style={{padding:".5rem"}}>
                        <Typography variant="h6" color="primary" style={{fontWeight:700}}>
                            Current {pluralize("Course", currentCohortStatusObjs.length)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{margin: "-1rem"}}>
                        {currentCohortStatusObjs.map((cohortStatusObj, index)=> {
                            return <DashboardCourseCard 
                                key={"profile-current-cohort-" + index} 
                                cohortStatusObj={cohortStatusObj} 
                                course = {cohortStatusObj?.enrollment.cohort.coursePurchase.course}
                                pathway = {cohortStatusObj?.enrollment.cohort.coursePurchase.path}
                                cohort = {cohortStatusObj?.enrollment.cohort}
                                ctaType="course-content"
                                style={
                                    currentCohortStatusObjs.length===1 && {maxWidth: "55vw"}
                                }
                            />
                            })
                        }
                    </Grid>            
                </>
            }
            </Grid>
        </section >
    )
}

export default CurrentCourseCard