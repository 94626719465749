import { buildGQLDispatchConfig } from './util';

const api_url = process.env.REACT_APP_API_URL

const fetchUserInfo = async (id, email, newUserDetails='') => {
    const query = `{
      allUsersList( condition: {orgId: ${id}, email: "${email}"} ) {
        id
        firstName
        lastName
        email
        jobTitle
        openToMentorship
        linkedinProfile
        phoneNumber
        profilePictureUrl
        role
        orgUnitName
        tags
        pathwayEnrollmentsByLearnerIdList {
          pathway {
            name
          }
        }
        enrollmentsByLearnerIdList {
          cohort {
            id
            deliveryId
            deliveryLinks
            liveSessionLink
            externalDelivery
            cohortSessionsByCohortIdList {
              startTimestamp
              durationHours
            }
            coursePurchase {
              course {
                id
                name
                description
                tags
                prerequisitesDescription
                softwareRequirements
                hours
                courseStructure
                partnership {
                  id
                  name
                  color
                  logo
                  orientationDocs
                }
              }
            }
          }
        }
        courseVotesByUserIdList(condition: {processedDate: null}) {
          course {
            name
            tags
          }
          requestType
          creationTimestamp
        }
      }
    }`

    const configObj = buildGQLDispatchConfig(query, {newUserDetails});

    try {
      const resp = await fetch(api_url,configObj)
      const json = await resp.json()
      return json.data.allUsersList[0]
    } catch (err) {
      console.log('Could not retrieve valid user information', err)
    }
  }

export {
    fetchUserInfo
}