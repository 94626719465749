import React from 'react'
import { useParams, Navigate } from 'react-router-dom'

// This component handles the redirect for the route `/home/:pathName/:coursName`
function ExternalCoursesNavigate() {
    const { pathName, courseName } = useParams()

    return(
        <Navigate to={`/course-catalog/${pathName}/${courseName}`}/>
    )
}

export default ExternalCoursesNavigate