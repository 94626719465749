import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import ProfileBackground from '../../assets/imgs/profile-bg.png'
import ProfilePicture from '../../assets/imgs/leon_profile.png'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
// import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import ProfileTag from '../ProfileTag'
import { updateFoundUserProfile, updateSnackbarData } from '../../redux/actions'
import { updateUserInfo } from '../../lib/profile'
import { getIsEmailDomainValid } from '../../lib/registrationRequest';


const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor:"#ffffff", 
        padding:"0rem",
        overflowX: "hidden", 
        maxWidth:"30rem"
    },
    profilePaper:{
        backgroundImage: `url(${ProfileBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
    },
    avatar: {
        width: '8rem !important',
        height: '8rem !important',
        margin: '-4rem 0.4rem'
    },
    dialogWidth: {
        width: '500px'
    },
    closeBtn:{
        width: '1.4rem',
        height: '1.4rem',
        color: '#000000',
        marginRight: '1rem',
        border: '1px solid #000000'
    },
    saveBtn: {
        height: '1.8rem',
        color: '#ffffff',
        float: 'right'
    },
    uploadBtn: {
        color: '#ffffff',
        display: 'block',
        margin: 'auto',
        paddingBottom: '2rem'
    },
    categoryHeading: {
        fontSize: '.625rem',
        color: theme.palette.primary.main,
        opacity: .64,
        textTransform: 'uppercase',
        paddingBottom: '1rem'
    },
    contactSection:{
        padding:"1rem",
        marginTop: "4rem",
        backgroundColor: theme.palette.primary[50], 
        borderBottomRightRadius: "4px", 
        borderBottomLeftRadius:"4px"
    },
    errorMessage: {
        color: 'red',
        marginTop: '-1.4rem',
        marginBottom: '0.5rem',
        backgroundColor: '#E1E1E4',
        width: '100%'
    }
}));

function EditProfile(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { open, setOpen, foundUser, skillsTags, placeholder } = props;
    const emailDomains = useSelector(state => state.currentOrg?.preferences?.emailDomains)
    const [error, setError] = useState({})
    const [formChange, setFormchange] = useState(false)
    const [userInfo, setUserInfo] = useState({
        id: foundUser?.id,
        firstName: `${foundUser.firstName.trim()}`,
        lastName: `${foundUser.lastName.trim()}`,
        jobTitle: foundUser?.jobTitle.trim(),
        email: foundUser?.email.trim(),
        orgUnitName: foundUser?.orgUnitName.trim()
        // profilePictureUrl: ''
    })

    const handleClose = () => {
        setOpen(false)
        setUserInfo({
            id: foundUser?.id,
            firstName: `${foundUser.firstName.trim()}`, 
            lastName: `${foundUser.lastName.trim()}`,
            jobTitle: foundUser?.jobTitle.trim(),
            email: foundUser?.email.trim(),
            orgUnitName: foundUser?.orgUnitName.trim()
        });
        setFormchange(false)
    };

    const handleChange = (evt) => {
        setFormchange(true)
        const value = evt.target.value;
        setUserInfo({
          ...userInfo,
          [evt.target.id]: value
        });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault()
        submitProfileUpdate(userInfo)
        setOpen(false)
    }

    //TO DO in beta 3.1
    // const handleFileUpload = () => {
    // }

    const submitProfileUpdate = async (userInfo) => {
        try {
            const updateProfile = await updateUserInfo(userInfo)
            dispatch(updateSnackbarData(<>You've successfully updated your profile</>, true, 'success'))
            dispatch(updateFoundUserProfile(updateProfile))
            return updateProfile
        } 
        catch(error) {
            console.error(`Error updating profile`, error)
            dispatch(updateSnackbarData(<>We encountered a problem updating your profile</>, true, 'error'))
        }
    }

    const validateEmailDomain = () => {
        const isEmailValid = getIsEmailDomainValid(userInfo?.email, emailDomains)
        let emailErr = {}

        if(!isEmailValid) {
            emailErr = {'emailError': 'Please provide a valid email domain'}
            setError(err => ({ ...err, ...emailErr }))
        } else {
            emailErr = {...error}
            delete emailErr.emailError
            setError({ ...emailErr })
        }    
    }

    const resetErrorMessage = (type) => {
        let resetErr = {...error}
        if (type === 'email') {
            delete resetErr.emailError
        } else if (type === 'password') {
            delete resetErr.passwordError
        } else {
            delete resetErr.firebaseError
        }
        setError({ ...resetErr })
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="edit profile" classes={{ paperWidthSm: classes.dialogWidth }}>
            <DialogTitle style={{padding: '.4rem 1rem', backgroundColor: '#ffffff'}}>  
                <Grid container justifyContent="space-between">
                    <Grid item xs={8}>
                        <IconButton 
                            aria-label="close"
                            size="small"
                            className={classes.closeBtn} 
                            onClick={handleClose} 
                        >
                            <CloseIcon fontSize='small'/>
                        </IconButton>
                        <Typography variant='h6' display='inline' style={{fontWeight: 700}}>Edit Profile</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button 
                            color="secondary"
                            variant="contained"
                            className={classes.saveBtn}
                            type='submit'
                            form='edit-profile'
                            disabled={!formChange}
                        >
                            <SaveOutlinedIcon fontSize='small' style={{marginRight: '0.3rem', marginLeft: '-0.5rem'}}/>
                            <Typography display='inline' style={{fontSize: '0.7rem', fontWeight: 400, marginRight: '-0.5rem'}}>Save</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            
            <Paper elevation={3} className={classes.profilePaper} style={{paddingTop:"6rem"}}>
                {/* //TO DO in beta 3.1 */}
                {/* <IconButton 
                    aria-label="upload profile picture"
                    className={classes.uploadBtn} 
                    onClick={handleFileUpload} 
                >
                    <AddAPhotoOutlinedIcon/>
                </IconButton> */}
                <Paper elevation={0} style={{backgroundColor:"#ffffff"}}>
                <form id='edit-profile' onSubmit={(e) => handleSubmit(e)}>
                    <Grid container justifyContent="space-between">
                        <Grid item container xs={12} spacing={2} style={{padding:"1rem 0.1rem 1rem 1rem"}}>
                            <Grid item xs={4}>
                                <Avatar variant="rounded" className={classes.avatar} src={ProfilePicture} />
                                {/* //TO DO in beta 3.1 */}
                                {/* <IconButton 
                                    aria-label="upload profile picture"
                                    className={classes.uploadBtn} 
                                    onClick={handleFileUpload} 
                                >
                                    <AddAPhotoOutlinedIcon/>
                                </IconButton> */}
                            </Grid>
                            <Grid item xs={8} style={{paddingLeft:"1rem"}}>
                                <TextField 
                                    id="firstName" 
                                    label="First Name"
                                    type="text" 
                                    variant="outlined" 
                                    value={userInfo.firstName}
                                    required
                                    size="small"
                                    style={{width: "100%", paddingBottom: "1rem"}}
                                    onChange={handleChange}
                                />
                                <TextField 
                                    id="lastName" 
                                    label="Last Name"
                                    type="text" 
                                    variant="outlined" 
                                    value={userInfo.lastName}
                                    required
                                    size="small"
                                    style={{width: "100%", paddingBottom: "1rem"}}
                                    onChange={handleChange}
                                />
                                <TextField 
                                    id="jobTitle" 
                                    label="Title/Position"
                                    type="text" 
                                    variant="outlined" 
                                    value={userInfo.jobTitle}
                                    required
                                    size="small"
                                    style={{width: "100%"}}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{padding:"1rem 1.5rem"}}>
                            <Typography className={classes.categoryHeading}>
                                Skills & Qualifications
                            </Typography>
                            <Grid item container spacing={1}>
                            { skillsTags.length > 0 
                                ? skillsTags.map((tag, index) => {
                                    return <ProfileTag key={`skills-${index}`} tagText={tag} />
                                })
                                : <Typography color="primary">{placeholder}</Typography>
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.contactSection}>
                            <TextField 
                                id="email" 
                                label="Email Address"
                                type="text" 
                                variant="outlined" 
                                value={userInfo.email}
                                required
                                size="small"
                                style={{width: "100%", paddingBottom: "1rem"}}
                                onChange={handleChange}
                                onBlur={validateEmailDomain}
                                onFocus={e => resetErrorMessage(e, 'email')}
                                disabled
                            />
                             { Object.keys(error).length > 0 &&
                                <Alert severity="error" className={classes.errorMessage}>
                                    { error.emailError || error.passwordError || error.firebaseError }
                                </Alert>
                            }
                            <TextField 
                                id="orgUnitName" 
                                label="Department"
                                type="text" 
                                variant="outlined" 
                                value={userInfo.orgUnitName}
                                required
                                size="small"
                                style={{width: "100%", paddingBottom: "1rem"}}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </form>
                </Paper>
                </Paper>
            <DialogContent className={classes.root} style={{paddingTop:"0rem"}}></DialogContent>
       
        </Dialog>
    );
}

export default EditProfile
