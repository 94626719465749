import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import SupervisorCart from './SupervisorCart';
import { AppBar, Avatar, Button, CircularProgress, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '../lib/firebase'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { clearFoundUser, clearLearnerInformation, updateCourseRegistrations, updateCourseRequests, updateLearnerCurrentCohorts, updatePathwayRegistrations } from '../redux/actions'
import {
    ADMIN_PREFIX,
    VIEW_TYPES,
    ANONYMOUS_DEFAULT_PAGE,
    LEARNER_DEFAULT_PAGE,
    SUPERVISOR_DEFAULT_PAGE,
    FAQ_URL
} from '../lib/globalKeys'
import { ReactComponent as SwitchIcon } from "../assets/imgs/icons/switch_views.svg"
import { ReactComponent as AccountSettingsIcon } from "../assets/imgs/icons/account_settings.svg"
import { ReactComponent as LogoutIcon } from "../assets/imgs/icons/logout.svg"
import { ReactComponent as AccountIcon } from "../assets/imgs/icons/account_circle.svg"

const PROFILE_MENU_WIDTH = 264
const userManualPDF = '/meldR_User_Manual.pdf'

function TopBar() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser)
    const topBarText = useSelector(state => state.topBarText)
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElCart, setAnchorElCart] = React.useState(null);
    const foundUser = useSelector(state => state.foundUser)
    const currentOrg = useSelector(state => state.currentOrg)
    const [supervisorView, setSupervisorView] = React.useState(true);

    const useStyles = makeStyles((theme) => ({
        cartPaper: {
            backgroundColor: "#FFFFFF",
            // overflowX: "visible",
            // overflowY: "visible"
            maxHeight: "650px",
            width: "450px"
        },
        cartRoot: {
            minWidth: "35px",
            height: "100%",
            padding: "0.75rem 0.75rem",
        },
        topBar: {
            backgroundColor: theme.palette.primary[50],
            color: theme.palette.primary.main,
            padding: ".5rem 0rem"
        },
        topMenuItem: {
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main
        },
        topMenuUser: {
            backgroundColor: anchorElUser ? theme.palette.primary.main : "#FAFAFA",
            color: anchorElUser ? "#FAFAFA" : theme.palette.primary.main
        },
        topMenuCart: {
            backgroundColor: anchorElCart ? theme.palette.primary.main : "#FAFAFA",
            color: anchorElCart ? "#FAFAFA" : theme.palette.primary.main,
        },
        truncated: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    }));

    const classes = useStyles()

    useEffect(() => {
        if (location.pathname.includes(ADMIN_PREFIX)) {
            setSupervisorView(true)
        } else {
            setSupervisorView(false)
        }
    }, [location])

    const StyledMenu = withStyles({
        paper: {
            backgroundColor: "#FAFAFA",
            width: `${PROFILE_MENU_WIDTH}px`,
            marginTop: ".5rem"
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));

    const handleClickUser = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleClickCart = (event) => {
        setAnchorElCart(event.currentTarget);
    };

    const handleCloseUser = () => {
        setAnchorElUser(null);
    };

    const handleCloseCart = () => {
        setAnchorElCart(null);
    };

    function openLogin() {
        navigate(location.pathname + "?login=true&redirect=true")
    }

    function openSignup() {
        navigate(location.pathname + "?signup=true&redirect=true")
    }

    function handleLogout() {
        setAnchorElUser(null)
        logout()
        navigate(ANONYMOUS_DEFAULT_PAGE)
        dispatch(clearFoundUser())
        dispatch(updateCourseRegistrations([]))
        dispatch(updatePathwayRegistrations([]))
        dispatch(updateCourseRequests([]))
        dispatch(clearLearnerInformation())
        dispatch(updateLearnerCurrentCohorts(null))
    }

    function handleClickProfile() {
        setAnchorElUser(null)
        navigate('/profile')
    }

    function handleAccountSettings() {
        setAnchorElUser(null)
        navigate('/admin/account-settings')

    }

    function handleSwitchUserView() {
        let updatedPath = supervisorView ? LEARNER_DEFAULT_PAGE : SUPERVISOR_DEFAULT_PAGE
        navigate(updatedPath)
        setAnchorElUser(null)
    }

    function renderCart() {
        return (
            <Grid item>
                <Button
                    aria-controls="user-menu"
                    aria-haspopup="true"
                    variant="contained"
                    className={classes.topMenuCart}
                    onClick={handleClickCart}
                    classes={{
                        root: classes.cartRoot
                    }}
                >
                    <ShoppingCartOutlinedIcon />
                </Button>
                <Popover
                    id={Boolean(anchorElCart) ? 'cart-popover' : undefined}
                    open={Boolean(anchorElCart)}
                    anchorEl={anchorElCart}
                    onClose={handleCloseCart}
                    style={{ marginTop: "0.75rem" }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        paper: classes.cartPaper
                    }}
                >
                    <SupervisorCart />
                </Popover>
            </Grid>
        )
    }

    return (
        <AppBar className={classes.topBar} elevation={0} position="sticky">
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item sm={4}>
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                            {topBarText}
                        </Typography>
                    </Grid>
                    <Grid item sm={8} container spacing={3} justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <Button
                                startIcon={<ArticleOutlinedIcon />}
                                color='secondary'
                                href={userManualPDF}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                USER MANUAL
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<InfoOutlinedIcon />}
                                color='secondary'
                                href={FAQ_URL}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                FAQ
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<HelpOutlineIcon />}
                                color='secondary'
                                href='mailto:hello@meldr.ai'
                                target={'_blank'}
                            >
                                REQUEST HELP
                            </Button>
                        </Grid>
                        {currentOrg?.featLogin ? (
                            currentUser ?
                                <Grid item>
                                    <Grid container spacing={1} alignItems="stretch">
                                        <Grid item>
                                            <Button
                                                id="user-dropdown"
                                                aria-controls="user-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                className={classes.topMenuUser}
                                                onClick={handleClickUser}
                                                // disableRipple
                                                style={{
                                                    width: "264px",
                                                    textTransform: "none",
                                                    textAlign: "left",
                                                    padding: "0.25rem"
                                                }}
                                            >
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={11} container alignItems="center" wrap="nowrap" spacing={1} >
                                                        <Grid item >
                                                            <Avatar variant="rounded" alt="profile image" src="" />
                                                        </Grid>
                                                        <Grid item>
                                                            {foundUser.firstName ?
                                                                <>
                                                                    <Typography variant="body2" color="inherit" className={classes.truncated} style={{ fontWeight: 600, width: "100%" }}>
                                                                        {foundUser?.firstName + " " + foundUser?.lastName}
                                                                    </Typography>
                                                                    {foundUser.role === VIEW_TYPES.SUPERVISOR &&
                                                                        <Typography variant="body2" color="inherit" className={classes.truncated} style={{ width: "100%", textTransform: "uppercase", fontSize: "0.625rem", opacity: "0.6" }}>
                                                                            {supervisorView ? "Supervisor View" : "Learner View"}
                                                                        </Typography>
                                                                    }
                                                                </>
                                                                :
                                                                <CircularProgress size={20} color="secondary" />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ExpandMoreOutlinedIcon fontSize="small" style={{ marginTop: ".5rem" }} />
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                            <StyledMenu
                                                id="user-menu"
                                                anchorEl={anchorElUser}
                                                keepMounted
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUser}
                                            >
                                                <MenuItem onClick={handleClickProfile} className={classes.topMenuItem}>
                                                    <ListItemIcon style={{ minWidth: '40px' }}>
                                                        <AccountIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="My Profile" />
                                                </MenuItem>
                                                {foundUser.role === VIEW_TYPES.SUPERVISOR &&
                                                    <MenuItem onClick={handleAccountSettings} className={classes.topMenuItem}>
                                                        <ListItemIcon style={{ minWidth: '40px' }}>
                                                            <AccountSettingsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Account Settings" />
                                                    </MenuItem>
                                                }
                                                {foundUser.role === VIEW_TYPES.SUPERVISOR &&
                                                    <MenuItem onClick={handleSwitchUserView} className={classes.topMenuItem}>
                                                        <ListItemIcon style={{ minWidth: '40px' }}>
                                                            <SwitchIcon />
                                                        </ListItemIcon>
                                                        {supervisorView ? <ListItemText primary="Switch to Learner View" /> :
                                                            <ListItemText primary="Switch to Supervisor View" />}
                                                    </MenuItem>
                                                }
                                                <MenuItem onClick={handleLogout} className={classes.topMenuItem}>
                                                    <ListItemIcon style={{ minWidth: '40px' }}>
                                                        <LogoutIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Logout" />
                                                </MenuItem>
                                            </StyledMenu>
                                        </Grid>
                                        {foundUser.role === VIEW_TYPES.SUPERVISOR && renderCart()}
                                    </Grid>
                                </Grid>
                                :
                                <>
                                    <Grid item>
                                        <Button variant="outlined" color="secondary" onClick={openSignup}>SIGN UP</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="secondary" onClick={openLogin}>Login</Button>
                                    </Grid>
                                </>
                        ) :
                            <Grid item>
                                <Grid container spacing={1} alignItems="stretch">
                                    {renderCart()}
                                </Grid>
                            </Grid>}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar