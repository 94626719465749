import { letteredDate } from './date'
import { REGISTRATION_STATUSES_SUPERVISOR, COHORT_STATUSES, COHORT_MATERIAL_STATUSES, upcomingStatusKey, openStatusKey } from './status'

const STATUSES = {...REGISTRATION_STATUSES_SUPERVISOR, ...COHORT_STATUSES, ...COHORT_MATERIAL_STATUSES}
function getStatusLabel( status ){
  const idx = status?.toLowerCase()
  if( STATUSES[idx] ){
    return STATUSES[idx].label
  }
  return `UNKNOWN(${status})`
}

// HEADERS
const headCells = [
    { id: 'course', numeric: false, disablePadding: true, label: 'Course', sortable: true },
    { id: 'dates', numeric: false, disablePadding: false, label: 'Dates', sortable: true },
    { id: 'signups', numeric: true, disablePadding: false, label: 'Signups', sortable: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true },
    { id: 'registration', numeric: false, disablePadding: false, label: 'Registration', sortable: false },
    // { id: 'actions', numeric: false, disablePadding: false, label: 'Actions', sortable: true },
  ];
  

// CREATE ROWS
const makeRows = (cohorts) => {
    return cohorts.map(cohort => new CohortTableData(cohort))
}

/**
 * private type to represent data for a cohort table row
 */
class CohortTableData {
  constructor( cohort ){
    this.id = cohort.id
    this.course = cohort.coursePurchase.course.name
    this.dates = createDatesString(cohort.dateStart, cohort.dateEnd)
    this.signups = cohort.enrollments.length
    this.registration = registrationMsg(cohort)
    this.enrollments = cohort.enrollments
    this.enrollmentEnd = cohort.enrollmentEnd

    // for sorting by date
    this.rawDate = cohort.dateStart

    // for styling status div
    this.rawStatus = cohort.status?.toLowerCase()
    this.rawMaterialsStatus = cohort.statusDetails.materialsStatus?.toLowerCase()

    this.status = getStatusLabel(this.rawStatus)
    this.materialsStatus = getStatusLabel(this.rawMaterialsStatus)
  }
}

const createDatesString = (start, end) => {
    return letteredDate(start) + " to " + letteredDate(end) + ", " + end.slice(0, 4)
}

const registrationMsg = (cohort) => {
    if (cohort.status === upcomingStatusKey) {
        return "Opens " + letteredDate(cohort.enrollmentStart)
    } else if (cohort.status === openStatusKey) {
        return "Closes " + letteredDate(cohort.enrollmentEnd)
    }
}

// SORT HELPERS
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function descendingDateComparator(a, b) {
    if (b.rawDate < a.rawDate) {
      return -1;
    }
    if (b.rawDate > a.rawDate) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    if (orderBy === 'dates') {
      return order === 'desc'
        ? (a, b) => descendingDateComparator(a, b)
        : (a, b) => -descendingDateComparator(a, b);
    } else {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
  }
  
  function stableSort(array, order, orderBy) {
    const comparator = getComparator(order, orderBy)
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

export {
    headCells,
    makeRows,
    stableSort
}