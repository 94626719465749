import SiteTourStep from "../components/joyride/SiteTourStep"

const mappedSteps = (steps, title, handleClickNext, handleClickEnd) => {
    return steps.map((step, index) => {
        return {
            ...step,
            hideFooter: true,
            disableBeacon: true,
            floaterProps: { 
                    styles: {
                        arrow: {
                            length: 8,
                            spread: 16
                        }
                    },
                    disableAnimation: true
                },
            content: <SiteTourStep 
                        stepNo={index+1} 
                        stepTotal={steps.length} 
                        title={title}
                        subText={step.stepText}
                        handleClickNext={handleClickNext}
                        handleClickEnd={handleClickEnd} 
                    />,
        }
    })
    
}

export {
    mappedSteps
}