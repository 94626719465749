import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { updateTopBarText } from '../redux/actions'
import leonDashboard from'../assets/imgs/leon-dashboard.png';
import leonBottom from'../assets/imgs/leon-bottom-circle.png';
import { Button, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core'
import DashboardCourseCard from '../components/dashboard/DashboardCourseCard';
import AliceCarousel from 'react-alice-carousel';
import '../carousel.css';
import { ReactComponent as ArrowForward } from '../assets/imgs/icons/arrow_forward.svg'
import { ReactComponent as ArrowBackward } from '../assets/imgs/icons/arrow_backward.svg'
import pluralize from 'pluralize';
import { fetchCurrentCohorts } from '../lib/dashboard'
import { mappedSteps } from '../lib/joyride';
import CustomJoyride from '../components/joyride/CustomJoyride';

const useStyles = makeStyles((theme) => ({
    activeCarouselButton: {
        fill: theme.palette.primary.main
    },
    disabledCarouselButton: {
        fill: 'gray'
    },
  }));

function Dashboard(){
    const dispatch = useDispatch()
    const classes = useStyles()
    const navigate = useNavigate()
    const foundUser = useSelector(state => state.foundUser)
    const [currentCohortStatusObjs, setCurrentCohortStatusObjs] = useState([])
    const cohorts = useSelector(state => state.cohorts)
    const [availableCohorts, setAvailableCohorts] = useState([])
    const [loadingCohorts, setLoadingCohorts] = useState(true)
    const joyRideRef = useRef(null)
    const [runTour, setRunTour] = useState(false)

    // Update Top Bar Heading
    useEffect(() => {
        dispatch(updateTopBarText('Dashboard'))
    }, [dispatch])

    useEffect(() => {
        // If user, finds all associated learner statuses that show Registered or Taking
        async function getLearnerCohorts() {
            const registeredLearnerStatuses = ["REGISTERED", "TAKING"]
            const retrievedLearnerCohorts = await fetchCurrentCohorts(foundUser.id)
            
            // Sort by cohort start_date and set in state
            const filteredCurrentCohortStatusObjs = 
                retrievedLearnerCohorts.filter(cohort => registeredLearnerStatuses.includes(cohort.learnerStatus))
                .sort((a,b)=> a.enrollment.cohort.dateStart > b.enrollment.cohort.dateStart? 1:-1)
            setCurrentCohortStatusObjs(filteredCurrentCohortStatusObjs)

            // create array of all of the learner's cohort's courseCodes.
            const enrolledCourseCodes = retrievedLearnerCohorts.map(cstoj => cstoj.enrollment.cohort.coursePurchase.course.courseCode)
            
            // Get all org cohorts and filter leaving only those open (and not full) and are not already enrolled in/completed by learner
            const filteredOpenCohortObjs = 
                cohorts.filter(cohort => cohort.status.toLowerCase() === "open")
                .filter(cohort => {
                    const isAvailableCohortFull = !!(parseInt(cohort?.statusDetails.seatsRemaining) <= 0)
                    return (!enrolledCourseCodes.includes(cohort.coursePurchase.course.courseCode) && !isAvailableCohortFull)})

            setAvailableCohorts(filteredOpenCohortObjs)
            return setLoadingCohorts(false)            
        }
        if(foundUser && cohorts){
            return getLearnerCohorts()
        }
    }, [foundUser, cohorts])

    //Check if tour has already run
    useEffect(()=>{
        if(localStorage.getItem('dashboardTour')){
            setRunTour(false)
        }else{
            setTimeout(() => {
                setRunTour(true)
            }, 1000)
        }
    },[])

    const isRegistered = !!(currentCohortStatusObjs?.length>0)
    const isAvailableCourses = !!(availableCohorts?.length > 0)
    const displayBottomContainer = isRegistered || isAvailableCourses

    let leonText = ["Howdy y'all."]

    if(isRegistered){
        leonText = ["That’s a nice looking", "course you got there."]
    }

    const renderPrevButton = ({ isDisabled }) => {
        return  <IconButton size="small" disabled={isDisabled} style={{marginTop:"-1rem"}}>
                    <ArrowBackward
                        className={
                            isDisabled
                            ? 
                            classes.disabledCarouselButton
                            : 
                            classes.activeCarouselButton
                        } 
                        style={{
                            height:"1rem", 
                            width:"1rem"
                        }} 
                    />
                </IconButton>
    };

    const renderNextButton = ({ isDisabled }) => {
        return  <IconButton size="small" disabled={isDisabled} style={{marginTop:"-1rem"}}>
                    <ArrowForward
                        className={
                            isDisabled
                            ? 
                            classes.disabledCarouselButton
                            : 
                            classes.activeCarouselButton
                        } 
                        style={{
                            height:"1rem", 
                            width:"1rem"
                            }} 
                        />
                </IconButton>
    };

    const CurrentCoursesEmptyState = () =>{
        return(
            <Grid container item xs={6} spacing={1} style={{padding:"4rem"}}>
                <Grid item style={{marginBottom:"1rem"}}>
                    <Typography color="primary" style={{fontSize: "3rem", fontWeight:400}}>
                        You're not currently registered for a course.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        id="empty-state-view-pathways"
                        variant="contained"
                        color='secondary'
                        onClick={() => navigate("/pathways")}
                    >
                        View Pathways
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color='secondary'
                        onClick={() => navigate("/course-catalog")}
                    >
                        View Course Catalog
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const AvailableCoursesEmptyState = () =>{
        return(
            <Grid container item xs={6} spacing={1} style={{padding:"4rem"}}>
                <Grid item style={{marginBottom:"1rem"}}>
                    <Typography variant="h4">
                        Chart Your Course
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Browse the map Pathways and look for courses to Request.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color='secondary'
                        onClick={() => navigate("/pathways")}
                    >
                        View Pathways
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const handleClickNext = () => joyRideRef.current.store.next()
    const handleClickEnd = () => {
        localStorage.setItem('dashboardTour', true)
        return joyRideRef.current.store.stop()
    }

    const dashboardSteps = [
        {
            target: '#user-dropdown',
            stepText: "Access your profile through this dropdown.",
            placement: 'bottom-end',
        }
    ]
    if(!isRegistered){
        const conditionalSecondStep = {
            target: '#empty-state-view-pathways',
            stepText: "Find a course that’s right for you in Pathways or Course Catalog.",
            placement: 'bottom-start',
        }
        dashboardSteps.unshift(conditionalSecondStep)
    }
    if(isAvailableCourses){
        const conditionalFirstStep = {
            target: '#first-register-card',
            stepText: "Register for a course that’s already been purchased by your Supervisor.",
            placement: 'right-end',
        }
        dashboardSteps.unshift(conditionalFirstStep)
    }

    const mappedDashboardSteps = mappedSteps(dashboardSteps, "Begin Your Journey", handleClickNext, handleClickEnd)

    if(loadingCohorts){
        return <LinearProgress color="secondary" /> 
    }

    return(
        <>
            <div id="dashboard-container" style={{position:"fixed", right:0}}>
                <div id="image-and-message-container" style={{height:"46rem", position:"absolute",  right:'8rem', top: displayBottomContainer ? '-10rem' : '-7rem'}} >
                    <div id="message-container" style={{position:"absolute", right:'12rem', top: (leonText.length ===2? '11rem' : '12rem')}}>
                        <div id="text-container" style={{position:'relative'}}>
                            {
                                leonText.map((text, index) => {
                                    return(
                                        <Typography key = {'leon-line-'+index} color="primary" variant="body1" align="center">
                                            {text}
                                        </Typography>
                                    )
                                })
                            }
                        </div>
                        <div 
                            style={{
                                borderBottom: "1px solid black", 
                                width: "10%", 
                                transform: "rotate(50deg)", 
                                transformOrigin: "top left",
                                position:'absolute',
                                right:'2rem',
                                bottom:'-0.5rem'
                            }} 
                        />
                    </div>
                    <img  src={leonDashboard}  alt="Error Page" style={{height:"100%"}}/>
                    {!displayBottomContainer &&
                        <div id="conditional-bottom-circle" style={{position:"absolute", bottom:"-2rem", left:"-5rem"}}>
                            <img  src={leonBottom}  alt="" style={{height:"15rem"}}/>
                        </div>
                    }
                </div>
            </div>
            <section id="current-classes-container" style={{minHeight:"40vh"}}>
                <Grid container>
                {
                    !isRegistered &&
                        <div style={{paddingTop: isAvailableCourses? "0rem": "9rem"}}>
                            <CurrentCoursesEmptyState />
                        </div>
                }
                {
                    isRegistered &&
                    <>
                        <Grid item xs={12} style={{padding:"1rem"}}>
                            <Typography variant="h6" color="primary" style={{fontWeight:700}}>
                                Current {pluralize("Course", currentCohortStatusObjs.length)}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} style={{marginTop: "-1rem"}}>
                            <AliceCarousel 
                                mouseTracking 
                                responsive = {{
                                    0: { items: 1 },
                                    1024: { items: 1 },
                                }}
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                                controlsStrategy="responsive"
                                items={
                                    currentCohortStatusObjs.map((cohortStatusObj, index)=> {
                                        return <DashboardCourseCard 
                                            key={"current-cohort-" + index} 
                                            cohortStatusObj={cohortStatusObj} 
                                            course = {cohortStatusObj?.enrollment.cohort.coursePurchase.course}
                                            pathway = {cohortStatusObj?.enrollment.cohort.coursePurchase.path}
                                            cohort = {cohortStatusObj?.enrollment.cohort}
                                            ctaType="course-content"
                                            style={
                                                currentCohortStatusObjs.length===1 && {maxWidth: "55vw"}
                                            }
                                        />
                                    })
                                } 
                            />
                        </Grid>
                    </>
                }
                </Grid>
            </section >
            {
                displayBottomContainer &&
                <>
                    <div id="dashboard-divider" style={{height:"4px", boxShadow: "0px -4px 12px #00000029", backgroundColor:"white", position:'relative'}} />
                    <section id="available-courses-container" style={{minHeight:"40vh", backgroundColor:"white", position:'relative'}}>
                        <Grid container>
                            {
                                isAvailableCourses?
                                <>
                                    <Grid item xs={12} style={{padding:"1rem"}}>
                                        <Typography variant="h6" color="primary" style={{fontWeight:700}}>
                                            Your Available Courses
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={availableCohorts.length>1 ? 12 : 7} style={{marginTop:"-1rem"}}>
                                        <AliceCarousel 
                                                mouseTracking 
                                                responsive = {{
                                                    0: { items: 1 },
                                                    1024: { items: 2 },
                                                }}
                                                controlsStrategy="responsive"
                                                renderPrevButton={renderPrevButton}
                                                renderNextButton={renderNextButton}
                                                paddingRight={availableCohorts.length>2 ? 120 : 0}
                                                items={
                                                    availableCohorts.map((cohort, index)=> {
                                                        return <DashboardCourseCard 
                                                                    cardIndex={index}
                                                                    key={"available-course-" + index} 
                                                                    course={cohort?.coursePurchase.course}
                                                                    pathway={cohort?.coursePurchase.path}
                                                                    cohort={cohort}
                                                                    ctaType="register"
                                                                />
                                                    })
                                                }  
                                            />
                                    </Grid>
                                </>
                                :
                                    <Grid item xs={12}>
                                        <AvailableCoursesEmptyState />
                                    </Grid>
                            }
                        </Grid>
                    </section>
                </>
            }
            <CustomJoyride 
                ref={joyRideRef}
                steps={mappedDashboardSteps}
                run={runTour}
            />
        </>
    )
}

export default Dashboard