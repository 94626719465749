import { Typography, makeStyles } from "@material-ui/core"

/**
 * Display price for a CoursePurchase.
 * Parameter must specify `price` as zero-decimal value
 * and `currency` (defaults to `USD`)
 * @param {CoursePurchase} coursePurchase
 * @returns
 */
const EnrollmentPrice = ({coursePurchase, styled = true}) => {
    const isStyled = !!styled

    const useStyles = makeStyles((theme) => ({
        courseCost: {
            color: "#4F5164",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "24px",
        }
    }))

    const classes = useStyles();

    // No-op if not given purchase details
    if (!coursePurchase) return null

    const {
        currency = 'USD',
        price,
    } = coursePurchase;

    // Try to get user's preferred locale
    const userLocale = navigator.language || 'en-US'

    // Select the symbol and units based on currency
    const currencyUnit = currency === 'USD' ? 100 : 1

    // Convert price from zero-decimal value
    const zerodecimalPrice = price
    const unitPrice = (Math.round(zerodecimalPrice) / currencyUnit)

    const formattedValue = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    })
    .format(unitPrice);

    return !isStyled
        ? (<>{formattedValue}</>)
        : (
            <Typography className={classes.courseCost}>
                {formattedValue}
            </Typography>
        )
}

export default EnrollmentPrice
