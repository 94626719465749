const _TENANT_DOMAIN_QUERY_PARAM_NAME = "tdomain";
// const domain_suffix_test = process.env.REACT_APP_DOMAIN_SUFFIX; // test if this will work outside the function
// console.log("Domain suffix outside fn", domain_suffix_test)

/** buildGQLDispatchConfig
 *  creates the config object for graphql queries to our backend
 * @param {*} query the query string
 * @param {*} extraArgs an optional object that allows us to pass any additional values, named as needed for the backend to handle
 * @returns the config object that works with fetch
 */
function buildGQLDispatchConfig(query, extraArgs=undefined){

    const token = localStorage.token || ""
    let body = {query}
    if (extraArgs) {
        body = {
            ...body,
            ...extraArgs
        }
    }
    
    return {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
            "tdomain": getTenantDomain()
        },
        body: JSON.stringify(body),
    }
}

// need to update endpoint for courses coming from learner paths

/**
 * Resolve the current context's tenant subdomain
 */
 function getTenantDomain(){
    let tenantSubdomain = ''

    // Resolve subdomain by layering possible values,
    const layers = [
        getTenantDomainFromURLQuery(),
        getTenantDomainFromHost(),
        getTenantDomainFromCache(),
        getTenantDomainDefault()
    ]

    // Take the first value as a match
    tenantSubdomain = layers.find(td => td && td.length > 0)

    if( tenantSubdomain && !tenantSubdomain.length ){
        console.error("Could not find org domain")
        return ''
    }

    cacheTenantDomain(tenantSubdomain)

    return tenantSubdomain
 }

 /**
  * Get a tenant domain from URL query param
  */
 function getTenantDomainFromURLQuery(){
    // initialize tenant subdomain to empty string
    let tenantSubdomain = '';

    // Pull domain from queryparam
    const urlParams = new URLSearchParams(window.location.search);
    const domainQuery = urlParams.get( _TENANT_DOMAIN_QUERY_PARAM_NAME );

    // If present, use it to match subdomain
    if( domainQuery ) {
        tenantSubdomain = domainQuery;
    }

    return tenantSubdomain
 }

 /**
  * Read a cached tenant domain
  */
 function getTenantDomainFromCache(){
    return sessionStorage.getItem( _TENANT_DOMAIN_QUERY_PARAM_NAME )
 }

 /**
  * Cache the provided tenant domain
  * @param {string} domain 
  */
 function cacheTenantDomain( domain ){
    return sessionStorage.setItem( _TENANT_DOMAIN_QUERY_PARAM_NAME, domain )
 }

 /**
  * Get a tenant domain from URL host
  */
 function getTenantDomainFromHost(){
    // initialize tenant subdomain to empty string
    let tenantSubdomain = '';

    // Read application domain suffix from environment
    let domain_suffix = process.env.REACT_APP_DOMAIN_SUFFIX;

    if( window.location.hostname.indexOf(domain_suffix) > -1 ){
        // Remove domain from hostname
        let subdomains = window.location.hostname.replace(domain_suffix, '');
    
        // Check if there are subdomains
        if( subdomains.indexOf('.') > -1 ){
            // we have at least one subdomain
            // Use leftmost part as tenant ID
            tenantSubdomain = subdomains.split('.')[0];
        }
    }

    return tenantSubdomain;
}

function getTenantDomainDefault(){
    return process.env.REACT_APP_PUBLIC_TENANT_SUBDOMAIN
}

const detectNotChrome = () => {
    const { userAgent } = navigator
    let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    let temp
  
    if (/trident/i.test(match[1])) {
      temp = /\brv[ :]+(\d+)/g.exec(userAgent) || []
  
      return `IE ${temp[1] || ''}`
    }
  
    if (match[1] === 'Chrome') {
      temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
  
      if (temp !== null) {
        return temp.slice(1).join(' ').replace('OPR', 'Opera')
      }
  
      temp = userAgent.match(/\b(Edg)\/(\d+)/)
  
      if (temp !== null) {
        return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)')
      }
    }
  
    match = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ]
    temp = userAgent.match(/version\/(\d+)/i)
  
    if (temp !== null) {
      match.splice(1, 1, temp[1])
    }
  
    return match[0] === "Chrome" ? false : true
    
  }

function objectOrArrayToArray( data ){
  return Array.isArray(data)
      ? data
      : [data];
}

/**
 * Makes a downstream GraphQL request via Fetch
 * @param {string} url URL to fetch
 * @param {object} payload Configuration payload to pass to fetch
 * @throws if response is not OK, or if response includes GQL errors
 */
async function fetchGqlJson( url, payload ){
    const resp = await fetch(url, payload)
    if( !resp.ok ){
        throw resp.status
    }

    const json = await resp.json()

    if( json.errors ){
        throw json
    }

    return json
}

// Accepts event, function to execute, and an string of keyType (defaulting to return)
function handleKeyEvent(event, functionName, keyType="return"){
    const mappedKeys = {
        "return": 13,
        "escape": 27
    }

    if (event.keyCode === mappedKeys[keyType]) {
        event.preventDefault();
        functionName()
    }
}

// Accepts id of element to copy and setTooltipOpen function
function handleCopy(textAreaId, setTooltipOpen){
    const copyText = document.getElementById(textAreaId);
    copyText.hidden = false
    copyText.select();
    document.execCommand("copy")
    copyText.hidden = true;
    setTooltipOpen(true)
    setTimeout(function(){ setTooltipOpen(false) }, 1500)
}

/**
 * Applies key/value pairs to the given object
 * @param {URLSearchParams} searchParams URLSearchParams from which to source key/values
 * @param {object} obj object to which to apply key/values
 */
function applyBooleanValuesFromSearchParams( searchParams, obj ){
    for ( const key of Object.keys(obj) ){
        const paramValue = searchParams.get(key);
        if( paramValue !== undefined ){
            obj[key] = (paramValue === 'true');
        }
    }
}

export {
    _TENANT_DOMAIN_QUERY_PARAM_NAME,
    applyBooleanValuesFromSearchParams,
    buildGQLDispatchConfig,
    handleCopy,
    detectNotChrome,
    fetchGqlJson,
    getTenantDomain,
    getTenantDomainFromURLQuery,
    getTenantDomainFromHost,
    handleKeyEvent,
    objectOrArrayToArray,
}