import React from 'react'
import { useSelector } from 'react-redux';
import ErrorPage from '../../pages/ErrorPage';
import LinearProgress from '@material-ui/core/LinearProgress';

function FeaturedLoginRoute({ children, loadingAuth, loadingUser }) {
    const currentOrg = useSelector(state => state.currentOrg)

    // 1. If org has not loaded or no org is found, return loading component
    // 2. If the org has loaded, auth/user has loaded and there's no login feature, return 404 page
    // 3. If the org has loaded and there's a login feature, return the children components
    // 4. Return Loader if no cases above are met
    if( !currentOrg ){
        return <LinearProgress color="secondary" />
    }

    else if(!currentOrg.featLogin && !(loadingAuth || loadingUser)){
        return <ErrorPage errorType="404" />
    }

    else if(currentOrg.featLogin){
        return (
            <>
                {children}
            </>
        )
    }
    return <LinearProgress color="secondary" />
}

export default FeaturedLoginRoute