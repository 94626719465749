import React from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  adornedEnd: {
      padding:"0rem"
  },
}));

function CourseSearch({ courseSearchTerm, setCourseSearchTerm }) {
    const classes = useStyles()
    
    return(
        <>
            <TextField 
                id="course-filter" 
                variant="outlined" 
                size="small"
                placeholder="Filter by Course Name"
                value={courseSearchTerm}
                onChange={(event) => setCourseSearchTerm(event.target.value)}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          courseSearchTerm.length === 0 ?
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>   
                          :
                            <IconButton 
                              onClick={() => setCourseSearchTerm('')} 
                            >
                              <CloseIcon/>
                            </IconButton>
                        }
                      </InputAdornment>
                    ),
                    classes: {
                      adornedEnd: classes.adornedEnd
                    }
                  }}
            />
        </>
    )
}

export default CourseSearch