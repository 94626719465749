import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Button, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import FlagIcon from '@material-ui/icons/Flag';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { formattedDate } from '../lib/date';
import { nextEnrollableCohort, futureAvailableCohort } from '../lib/registrationRequest';
import { INITIAL_SELECTED_COURSE, SELECTION_TYPES, VIEW_TYPES } from '../lib/globalKeys'
import { GREEN } from '../assets/colors'
import pluralize from 'pluralize';
import EnrollmentPrice from './course_catalog/EnrollmentPrice';
import DaysLeftIndicator from './course_state/DaysLeftIndicator';
import TextIconIndicator from './course_state/TextIconIndicator';
import PriceWrapper from './course_state/PriceWrapper';
import { COHORT_STATUSES } from '../lib/globalKeys';

function CourseCard ({divId, course, view, drawerOpen, setDrawerOpen, selection, setSelection, setSelectionType, courseIndex, totalNumCourses, showPathwayConnections}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [hover, setHover] = useState(false)
    const currentOrg = useSelector(state => state.currentOrg)
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const requestedCourses = useSelector(state => state.requestedCourses)
    const allCohorts = useSelector(state => state.cohorts)
    const registeredCohort = registeredCohorts.find(cohort => cohort.coursePurchase.course.name === course.name)
    const updatedRegisteredCohort = allCohorts?.find(cohort => cohort.id === registeredCohort?.id)
    const foundUser = useSelector(state => state.foundUser)
    const loggedIn = !!foundUser.email
    const paymentEnabled = currentOrg.featPayment


    const isCourseSelected = selection===course

    const useStyles = makeStyles((theme) => ({
        root: {
            cursor: "pointer",
            padding: "1rem",
            borderRight: `2px solid ${isCourseSelected? theme.palette.primary['A100']: theme.palette.primary[50]}`,
            borderTop: `1px solid ${isCourseSelected? theme.palette.primary['A100']: theme.palette.primary[50]}`,
            borderBottom: `1px solid ${isCourseSelected? theme.palette.primary['A100']: theme.palette.primary[50]}`,
            borderLeft: `4px solid ${isCourseSelected? theme.palette.primary['A700'] : (hover? theme.palette.primary.main : theme.palette.secondary['A700'])}`,
            backgroundColor:  isCourseSelected? theme.palette.primary[50] : ""
        },
        courseIndicator: {
            color:  isCourseSelected? theme.palette.primary['A700'] : theme.palette.primary.main
        },
        courseText:{
            textDecoration: hover?"underline":"",
            fontWeight:"bold",
            color: theme.palette.primary.main 
        },
        tagBackground: {
            padding:"0 .5rem",
            backgroundColor: isCourseSelected? theme.palette.primary[100] : theme.palette.background.paper
        },
        squareIcon: {
            width:"1rem", 
            paddingTop:"4px",
            color: theme.palette.primary[300]
        },
        verticalIcon:{
            paddingTop:"6px",
            color: theme.palette.primary[300]
        },
        lightText: {
            color: theme.palette.primary[300]
        },
        vCentered: {
            display: 'flex',
            alignItems: 'center',
        }
      }));

    const classes = useStyles();

    const handleClickCourse = (e) => {
        e.preventDefault()

        const prefix = view===VIEW_TYPES.SUPERVISOR? '/admin': ''
        const searchParams = new URLSearchParams(location.search)
        const pathParam = searchParams.get('path')
        const querySuffix = pathParam? `?path=${pathParam}` : ''
        if (isCourseSelected){
            setDrawerOpen(!drawerOpen)
            setSelection(INITIAL_SELECTED_COURSE)
            setSelectionType('')
            navigate(`${prefix}/course-catalog${querySuffix}`)
        } else {
            setSelection(course)
            setSelectionType(SELECTION_TYPES.COURSE)
            setDrawerOpen(true)
            navigate(`${prefix}/course-catalog/${course.pathwaySlug}/${course.courseSlug}${querySuffix}`)
        }
    }

    const publicLearnerView = view===VIEW_TYPES.LEARNER && !(currentOrg.featCohortEnrollment || currentOrg.featCohortRequest) 
    const foundAvailableCohort = nextEnrollableCohort(course)
    const availableEnrollableCohort = allCohorts?.find(cohort => foundAvailableCohort?.id === cohort.id)
    const isAvailableCohortFull = !!(parseInt(availableEnrollableCohort?.statusDetails.seatsRemaining) <= 0)
    const futureEnrollableCohort = futureAvailableCohort(course)
    const coursePurchaseExists = course.coursePurchasesByCourseIdList?.length > 0
    const coursePurchase = coursePurchaseExists ? course.coursePurchasesByCourseIdList[0] : null
    // TODO: need to check course purchase hasn't ended in original fetch
    const learnerRequestedCourse = !!(requestedCourses.includes(course.name))

    const CardAction = ({color="secondary", variant="contained", children}) => {
        return <Button
                    component={Typography}
                    color={color}
                    variant={variant}
                    onClick={handleClickCourse}
                >
                    {children}
                </Button>
    }
    
    const LearnerRegistered = () => {
        return  <>
                    <Grid item>
                        <DaysLeftIndicator course = {course} />
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <TextIconIndicator text='Registered' color="green" icon="check"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
    }

    const LearnerRegisteredAndCompleted = () => {
        return (
            <Grid item>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <TextIconIndicator text='Completed' color="green" icon="check"/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const OpenForRegistration = () => {
        return (
            <>
                <Grid item>
                    <DaysLeftIndicator course = {course} />
                </Grid>
                <Grid item>
                    {
                        loggedIn ? 
                            <CardAction>
                                Register
                            </CardAction>
                        :
                            <CardAction>
                                Log in to Register
                            </CardAction>
                    }
                </Grid>
            </>
        )
    }

    const OpenForRegistrationWithPay = () => {
        return  <>
                    <Grid item>
                        <DaysLeftIndicator course = {course} />
                    </Grid>
                    <Grid item>
                        {
                            loggedIn ? 
                                <CardAction>
                                    Register
                                    &nbsp;
                                    <EnrollmentPrice coursePurchase={coursePurchase} styled={false}/>
                                </CardAction>
                            :
                            <PriceWrapper coursePurchase={coursePurchase}>
                                <CardAction>
                                    Log in to Register
                                </CardAction>
                            </PriceWrapper>
                        }
                        
                    </Grid>
                </>
    }

    const WillBeOpenForRegistration = ({futureEnrollableCohort}) => {
        return (
            <Grid item>
                <PriceWrapper coursePurchase={coursePurchase}>
                    <Typography align="right" color="primary" variant="subtitle2">Registration will open on {formattedDate(futureEnrollableCohort.enrollmentStart)}.</Typography>
                </PriceWrapper>
            </Grid>
        )
    }

    const JoinWaitlist = () => {
        return (
            <Grid item>
                <PriceWrapper coursePurchase={coursePurchase}>
                    <CardAction>
                        { loggedIn ? 'Join Waitlist' : 'Log in to Join Waitlist' }
                    </CardAction>
                </PriceWrapper>
            </Grid>
        )
    }

    const LearnerWaitlisted = () => {
        return (
            <Grid item>
                <PriceWrapper coursePurchase={coursePurchase}>
                    <TextIconIndicator text="On the Waitlist" color="green" icon="check"/>
                </PriceWrapper>
            </Grid>
        )
    }

    const LearnerRequested = () => {
        return(
            <Grid item>
                <TextIconIndicator text="Requested" color="green" icon="check"/>
            </Grid>
        )  
    }

    const RequestCourse = () => {
        return  <Grid item>
                    <CardAction variant="outlined">
                        { loggedIn ? 'Request' : 'Log in to Request' }
                    </CardAction>
                </Grid>
    }

    const CoursePurchased = () => {
        return  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography style={{color: GREEN}}>
                                Purchased 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CheckIcon style={{color: GREEN}}/>
                        </Grid>
                    </Grid>
                </Grid>
    }

    const PurchaseCourse = () => {
        return  <Grid item>
                    <CardAction>
                        Purchase
                    </CardAction>
                </Grid>
    }

    const RequestedOrWaitlisted = ({course, requestType}) => {
        return <>
        {
            requestType === "WAITLIST" &&
            <Grid item>
                <Tooltip title="No open or upcoming cohorts" aria-label="No open or upcoming cohorts">
                    <FlagIcon style={{color:"red"}}/>
                </Tooltip>
            </Grid>
        }
        {course.courseVotesByCourseIdList?.length>0 &&
            <Grid item>
                <Grid container>
                    <Grid item style={{paddingRight:".25rem"}}>
                        <AssignmentIndOutlinedIcon color="primary"/>
                    </Grid>
                    <Grid item>
                        <Typography color="primary" style={{fontWeight:"bold"}}>
                            {course?.courseVotesByCourseIdList?.length} {requestType === "WAITLIST"? "Waitlisted": "Requested"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        }
        </>
    }

    function mappedCourseActions(view, course){
        if(currentOrg.featCohortEnrollment){
            if(view===VIEW_TYPES.LEARNER){
                if(registeredCohort){
                    // enrollment exists - learner has completed the course or is registered
                    if(updatedRegisteredCohort?.status===COHORT_STATUSES.COMPLETED){
                        return <LearnerRegisteredAndCompleted  />
                    } else {
                        return <LearnerRegistered  />
                    }
                }
                if(availableEnrollableCohort && !isAvailableCohortFull){
                    // available current cohort and seats remaining -> register
                    if(paymentEnabled){
                        // payment through the app is enabled
                        return <OpenForRegistrationWithPay />
                    }
                    return <OpenForRegistration />
                }
                if(futureEnrollableCohort){
                    // availble cohort is full but available future cohort -> registration opens...
                    // no available current cohort but available future cohort -> registration opens...
                    return <WillBeOpenForRegistration futureEnrollableCohort = {futureEnrollableCohort} />
                }
                if(coursePurchaseExists && learnerRequestedCourse){
                    // course purchase and already joined waitlist/requested
                    return <LearnerWaitlisted />
                }
                if(coursePurchaseExists){
                    // course purchase but no current or future cohort -> join waitlist
                    return <JoinWaitlist />
                }
                if(learnerRequestedCourse){
                    // no course purchase exists and learner joined waitlist/requested
                    return <LearnerRequested />
                }
                // none of the others are true, so no coursePurchase exists and therefore can request
                return < RequestCourse />
            } else if (view === VIEW_TYPES.SUPERVISOR){
                if(coursePurchaseExists){
                    // no available cohort with space or no future cohort, show number of waitlisted
                    // if course purchase exists, returned Purchased
                    return <>
                    {
                        (!(availableEnrollableCohort && !isAvailableCohortFull) && !futureEnrollableCohort) && 
                        <RequestedOrWaitlisted course = {course} requestType={"WAITLIST"}/>
                    }
                    <CoursePurchased />
                    </>
                }
                // no course purchase - show requested & purchase action
                return  <>
                            <RequestedOrWaitlisted course = {course} requestType={"REQUEST"}/>
                            <PurchaseCourse />
                        </>
            }
        }
        return null
    }

    return(
        <>
            <Grid id={divId} container className={classes.root} justifyContent="space-between" alignItems="center" onClick={handleClickCourse} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                <Grid item xs={publicLearnerView? 12 : 8}>
                    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} style={{position: 'relative'}}>
                        {
                            (courseIndex !== 0 && !showPathwayConnections)&&
                            <div 
                                id={`top-connecting-course-line-`+course.courseCode}
                                style={{
                                    border: "2px solid black", 
                                    borderRadius: "0 0 4px 4px",
                                    width: "0", 
                                    height:"48%",
                                    position:'absolute',
                                    top: "-0.6rem",
                                    left:'1.7rem'
                                }} 
                            />
                        }
                        {
                            (courseIndex !== totalNumCourses-1 && !showPathwayConnections) &&
                            <div 
                                id={`bottom-connecting-course-line-`+course.courseCode}
                                style={{
                                    border: "2px solid black", 
                                    borderRadius: "4px 4px 0 0", 
                                    width: "0", 
                                    height:"48%",
                                    position:'absolute',
                                    bottom: "-0.55rem",
                                    left:'1.7rem',
                                }} 
                            />
                        }
                        <Grid item style={{paddingLeft: "1.25rem"}}>
                            <div 
                                id={`circle-indicator-`+course.courseCode}
                                style={{
                                    border: "4px solid black", 
                                    borderRadius: "50%",
                                    width: "18px", 
                                    height:"18px",
                                }} 
                            />
                        </Grid>
                        <Grid item xs={10} xl={11}>
                            <Grid container direction="column" style={{paddingLeft: "0.75rem"}}>
                                <Grid item >
                                    <Typography className={classes.courseText} variant="subtitle1">
                                        {course.name}
                                    </Typography>
                                </Grid>
                                <Grid item> 
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <ScheduleIcon className={classes.squareIcon} />
                                                </Grid>
                                                <Grid item style={{padding:'0rem'}}>
                                                    <Typography className={classes.lightText} variant="subtitle2">
                                                        {Math.round(course.hours)} Hours
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <BookOutlinedIcon className={classes.verticalIcon} />
                                                </Grid>
                                                <Grid item style={{padding:'0rem'}}>
                                                    <Typography className={classes.lightText} variant="subtitle2">
                                                        {pluralize("Topic", course?.topics?.length, true)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            (availableEnrollableCohort || futureEnrollableCohort)&&
                                            <Grid item>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <EventIcon className={classes.squareIcon} />
                                                    </Grid>
                                                    <Grid item style={{padding:'0rem'}}>
                                                        <Typography className={classes.lightText} variant="subtitle2">
                                                            {   availableEnrollableCohort?
                                                                formattedDate(availableEnrollableCohort.dateStart) + "-" + formattedDate(availableEnrollableCohort.dateEnd)
                                                                :
                                                                formattedDate(futureEnrollableCohort.dateStart) + "-" + formattedDate(futureEnrollableCohort.dateEnd)
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item >
                                    <Grid container spacing={1}>
                                        {course.tags.map((tag, index) => {
                                            return (
                                                <Grid item key={index}>
                                                    <Paper className = {classes.tagBackground} elevation={0}>
                                                        <Typography variant="caption" style={{fontFamily:"Roboto", fontWeight:"500"}}>{tag.toUpperCase()}</Typography>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={publicLearnerView? false : 4}>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                        {mappedCourseActions(view, course)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CourseCard