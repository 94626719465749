import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as MeldrLogo } from '../assets/imgs/icons/meldr-icon.svg'
import { ReactComponent as BrowseUsersIcon } from '../assets/imgs/icons/browse-users.svg'
// import { ReactComponent as CalendarIcon} from '../assets/imgs/icons/calendar.svg'
import { ReactComponent as DashboardIcon } from '../assets/imgs/icons/dashboard.svg'
import { ReactComponent as PathwaysIcon } from '../assets/imgs/icons/pathways.svg'
import { ReactComponent as CohortsIcon } from '../assets/imgs/icons/cohorts.svg'
import { ReactComponent as CourseCatalogIcon } from '../assets/imgs/icons/bookmark.svg'
import AppsIcon from '@material-ui/icons/Apps';
import { VIEW_TYPES } from '../lib/globalKeys'


const useStyles = makeStyles(theme => ({
    navbar: {
        backgroundColor: theme.palette.primary.main,
        height: "100vh",
        padding: "0 0 1rem 0",
        borderRight: `4px solid ${theme.palette.primary[50]}`,
    },
    navLogo: {
        textAlign: "center",
        backgroundColor: "#ffffff",
        padding: "1rem 0 1rem 0"
    },
    navLogoImg: {
        width: "50%"
    },
    navItemGrid: {
        textAlign: "center",
        padding: ".75rem 0 .75rem 0",
        color: theme.palette.primary[100]
    },
    selectedNavItemGrid: {
        textAlign: "center",
        padding: ".75rem 0 .75rem 0",
        backgroundColor: theme.palette.primary[400],
        color: "#ffffff"
    },
    navSvg: {
        fill: theme.palette.primary[100]
    },
    selectedNavSvg: {
        fill: "#ffffff"
    }
}));

function SideNavBar() {
    const classes = useStyles()
    const location = useLocation()
    const navigate = useNavigate()
    const currentOrg = useSelector(state => state.currentOrg)
    const currentRole = useSelector(state => state?.foundUser?.role)

    const generalNav = [
        {
            component: "Pathways",
            path: "/pathways",
            iconText: "Pathways"
        },
        {
            component: "CourseCatalog",
            path: "/course-catalog",
            iconText: "Course Catalog"
        }
    ]

    const learnerNav = [
        {
            component: "Dashboard",
            path: "/dashboard",
            iconText: "Dashboard"

        },
        {
            component: "Pathways",
            path: "/pathways",
            iconText: "Pathways"
        },
        {
            component: "CourseCatalog",
            path: "/course-catalog",
            iconText: "Course Catalog"
        }
    ]

    const supervisorNav =
        [
            // {
            //     component:"Dashboard",
            //     path: "/admin/dashboard",
            //     iconText: "Dashboard"

            // },
            {
                component: "PathwaysMap",
                path: "/admin/pathways",
                iconText: "Pathways"
            },
            {
                component: "CourseCatalog",
                path: "/admin/course-catalog",
                iconText: "Course Catalog"
            },
            {
                component: "BrowseUsers",
                path: "/admin/browse-users",
                iconText: "Browse Users"
            },
            {
                component: "Cohorts",
                path: "/admin/cohorts",
                iconText: "Cohorts"
            }
        ]

    function navOptions() {
        // if user is logged in as supervisor and at '/admin/...', see admin nav
        // if user is logged in as supervisor and not at '/admin/...', see learner nav
        // if user is logged in as learner, see learner nav
        // if not logged in or no login feature, see general nav
        if (currentOrg?.featLogin) {
            if (currentRole === VIEW_TYPES.SUPERVISOR) {
                if (location.pathname.includes('/admin/')) {
                    return supervisorNav
                } else {
                    return learnerNav
                }
            } else if (currentRole === VIEW_TYPES.LEARNER) {
                return learnerNav
            }
        }
        return generalNav
    }

    function navIcon(path, classname) {
        switch (true) {
            case path.includes("/dashboard"):
                return <DashboardIcon className={classname} />
            case path.includes("/pathways"):
                return <PathwaysIcon className={classname} />
            case path.includes("/course-catalog"):
                return <CourseCatalogIcon className={classname} />
            case path.includes("/browse-users"):
                return <BrowseUsersIcon className={classname} />
            case path.includes("/cohorts"):
                return <CohortsIcon className={classname} />
            // // case "/calendar":
            // //     return <CalendarIcon className={classname}/>
            default:
                return <AppsIcon fontSize="large" className={classname} />
        }
    }

    function isActive(navItem) {
        return location.pathname.includes(navItem.path)
    }

    function getGridClass(navItem) {
        return isActive(navItem) ? classes.selectedNavItemGrid : classes.navItemGrid
    }

    function getFillClass(navItem) {
        return isActive(navItem) ? classes.selectedNavSvg : classes.navSvg
    }

    return (
        <div >
            <Grid container className={classes.navbar} direction="column" alignItems="center">
                <Grid item container justifyContent="center">
                    <Grid item xs={12} className={classes.navLogo}>
                        {currentOrg?.preferences?.logo
                            ? <img
                                className={classes.navLogoImg}
                                alt={`${currentOrg.name}`}
                                src={`${currentOrg.preferences.logo}`} />
                            : <MeldrLogo className={classes.navLogoImg} />
                        }
                    </Grid>
                    {navOptions().map((navItem, index) => {
                        return (
                            <Grid
                                item
                                key={index} xs={12}
                                className={getGridClass(navItem)}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(navItem.path)}
                            >
                                {navIcon(navItem.path, getFillClass(navItem))}
                                <Typography color="inherit" style={{ fontSize: ".75rem" }} >
                                    {navItem.iconText}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}

export default SideNavBar