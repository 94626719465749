import { combineReducers } from 'redux'

const cartContentReducer = (prevState=[], action) => {
  switch(action.type){
    case "UPDATE_CART_CONTENT":
      return action.payload
    default:
      return prevState
  }
}

const initialOrg = {
  preferences: {
    colors: {
      primary: "",
      secondary: ""
    }
  }
}

const currentOrgReducer = (prevState=initialOrg, action) => {
  switch(action.type) {
    case "UPDATE_CURRENT_ORG":
      return action.payload
    default:
      return prevState
  }
}

const cohortsReducer = (prevState=null, action) => {
  switch(action.type) {
    case "UPDATE_COHORTS":
      return action.payload
    default:
      return prevState
  }
}

const learnerCurrentCohortsReducer = (prevState=null, action) => {
  switch(action.type) {
    case "UPDATE_CURRENT_COHORTS":
      return action.payload
    default:
      return prevState
  }
}

const learnerPathsReducer = (prevState=[], action) => {
  switch(action.type) {
    case "UPDATE_PATHS":
      return action.payload
    default:
      return prevState
  }
}

const allCoursesReducer = (prevState =[], action) => {
  switch(action.type) {
    case "UPDATE_ALL_COURSES":
      return action.payload
    default:
      return prevState
  }
}

const initialLearner={
  firstName: "",
  lastName:"",
  email: "",
  orgUnit: ""
}

const learnerInformationReducer = (prevState=initialLearner, action) => {
  switch(action.type) {
    case "UPDATE_LEARNER_INFORMATION":
      return action.payload
      case "CLEAR_LEARNER_INFORMATION":
        return initialUser
    default:
      return prevState
  }
}

const learnerInfoForSupervisorReducer = (prevState=initialLearner, action) => {
  switch(action.type) {
    case "UPDATE_LEARNER_DETAIL":
      return action.payload
    default:
      return prevState
  }
}

const initialUser = {
  "firstName": "",
  "lastName":"",
  "email": "",
  "jobTitle": "",
  "openToMentorship": null,
  "linkedinProfile": "",
  "id": null,
  "orgUnitName": "",
  "role": "",
}

//user found in meldR database
const foundUserReducer = (prevState=initialUser, action) => {
  switch(action.type) {
    case "UPDATE_FOUND_USER":
      return action.payload
    case "UPDATE_FOUND_USER_PROFILE":
      return {...prevState, ...action.payload}
    case "CLEAR_FOUND_USER":
      return initialUser
    default:
      return prevState
  }
}

//user found in firebase
const currentUserReducer = (prevState=null, action) => {
  switch(action.type) {
    case "UPDATE_CURRENT_USER":
      return action.payload
    default: return prevState
  }
}

const initialSnackbarData = {
  open: false,
  message: "Success!",
  notificationType: "success"
}

const snackbarDataReducer = (prevState=initialSnackbarData, action) => {
  switch(action.type) {
    case "UPDATE_SNACKBAR_DATA":
      return action.payload
    default:
      return prevState
  }
}

const openLoginFormReducer = (prevState=false, action) => {
  switch(action.type){
    case "UPDATE_LOGIN_OPEN":
      return action.payload
    default:
      return prevState
  }
}

const openSignupFormReducer = (prevState=false, action) => {
  switch(action.type){
    case "UPDATE_SIGNUP_OPEN":
      return action.payload
    default:
      return prevState
  }
}

const requestedCoursesReducer = (prevState=[], action) => {
  switch(action.type){
    case "UPDATE_COURSE_REQUESTS":
      return action.payload
    default:
      return prevState
  }
}

const registeredCohortsReducer = (prevState=[], action) => {
  switch(action.type){
    case "UPDATE_COURSE_REGISTRATIONS":
      return action.payload
    default:
      return prevState
  }
}

const registeredPathwaysReducer = (prevState=[], action) => {
  switch(action.type){
    case "UPDATE_PATHWAY_REGISTRATIONS":
      return action.payload
    default:
      return prevState
  }
}

const topBarTextReducer = (prevState='', action) => {
  switch(action.type){
    case "UPDATE_TOP_BAR_TEXT":
      return action.payload
    default:
      return prevState
  }
}

const rootReducer = combineReducers({
    allCourses: allCoursesReducer,
    cartContent: cartContentReducer,
    cohorts: cohortsReducer,
    snackbarData: snackbarDataReducer,
    currentOrg: currentOrgReducer,
    currentUser: currentUserReducer,
    foundUser: foundUserReducer,
    learnerInformation: learnerInformationReducer,
    learnerInfoForSupervisor: learnerInfoForSupervisorReducer,
    learnerPaths: learnerPathsReducer,
    learnerCurrentCohorts:learnerCurrentCohortsReducer,
    openLoginForm: openLoginFormReducer,
    openSignupForm: openSignupFormReducer,
    requestedCourses: requestedCoursesReducer,
    registeredCohorts: registeredCohortsReducer,
    registeredPathways: registeredPathwaysReducer,
    topBarText: topBarTextReducer
});

export default rootReducer;

