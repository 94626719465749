import { buildGQLDispatchConfig } from './util';

const api_url = process.env.REACT_APP_API_URL

const updateUserInfo = (userInfo) => {
    // prepare input value as string
    const input = `{
        id: ${userInfo.id},
        patch: {
            firstName: "${userInfo.firstName}",
            lastName: "${userInfo.lastName}",
            jobTitle: "${userInfo.jobTitle}",
            email: "${userInfo.email}",
            orgUnitName: "${userInfo.orgUnitName}"
        }
    }`;

    const mutationString = `
        mutation {
            updateUser: updateUserById (input: ${input}) {
                clientMutationId
                user {
                    firstName
                    lastName
                    jobTitle
                    orgUnitName
                }
            }
        }
    `
    const configObj = buildGQLDispatchConfig(mutationString);
    return new Promise(resolve => {
        fetch(
            api_url,
            configObj
        )
        .then((resp) => resp.json())
        .then((json) => {
            resolve(json?.data?.updateUser?.user)
        })
        .catch((err) => console.log(err));
    });
}


export {
    updateUserInfo
}