import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import CourseCatalog from './pages/CourseCatalog'
import CourseContent from './pages/CourseContent'
import ForgotPassword from './components/login/ForgotPassword'
import PaymentConfirmation from './pages/PaymentConfirmation'
import SupervisorCohorts from './pages/SupervisorCohorts'
import Profile from './pages/Profile'
import AccountSettings from './pages/AccountSettings'
import { fetchLearnerCurrentCohorts, fetchCurrentOrgDetails, updateSnackbarData, updateCartContent, updateCourseRegistrations, updateCourseRequests, updateCurrentUser, updateFoundUser, updateLearnerInformation, updateLoginOpen, updateSignupOpen, updatePathwayRegistrations } from './redux/actions'
import { fetchUserInfo } from './lib/login'
import { auth } from './lib/firebase'
import { Snackbar } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './assets/material_ui_theme.js'
import CssBaseline from '@material-ui/core/CssBaseline';
import SnackbarNotification from './components/SnackbarNotification'
import Login from './components/login/Login'
import Signup from './components/login/Signup'
import usePageTracking from './lib/usePageTracking'
import BrowseUsers from './pages/BrowseUsers'
import Dashboard from './pages/Dashboard'
import PathwaysMap from './pages/PathwaysMap'
import Layout from './layouts/Layout'
import ErrorPage from './pages/ErrorPage'
import UnsupportedDevice from './pages/UnsupportedDevice'
import PrivateRoute from './components/routes/PrivateRoute'
import FeaturedLoginRoute from './components/routes/FeaturedLoginRoute'
import InitialNavigate from './components/routes/InitialNavigate'
import { LEARNER_DEFAULT_PAGE, SUPERVISOR_DEFAULT_PAGE, VIEW_TYPES, FAQ_URL, TERMS_URL } from './lib/globalKeys'
import ExternalCoursesNavigate from './components/routes/ExternalCoursesNavigate'
import ExternalRedirect from './components/routes/ExternalRedirect';
import WelcomeModal from './components/modals/WelcomeModal';

const SUPPORTED_SCREEN_WIDTH = 1200

function isScreenSupported(){
  return window.innerWidth >= SUPPORTED_SCREEN_WIDTH && !isMobile
}

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentOrg = useSelector(state => state.currentOrg)
  const snackbarData = useSelector(state => state.snackbarData)
  const [loadingAuth, setLoadingAuth] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)
  const currentUser = useSelector(state => state.currentUser)
  const foundUser = useSelector(state => state.foundUser)
  const [supportedScreen, setSupportedScreen] = useState(isScreenSupported())

  // Google Analytics
  usePageTracking();

  // Update tab title to reflect environment
  useEffect(() => {
    if (process.env.REACT_APP_ENV_TITLE) {
      document.title = document.title + ' | ' + process.env.REACT_APP_ENV_TITLE
    }
  }, [])

  // Watch auth state change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      dispatch(updateCurrentUser(user))
      setLoadingAuth(false)
      console.debug("auth state changed", user?.email)
    })
    return unsubscribe
  }, [dispatch])

  // Grab cartContent in localStorage and update store
  useEffect(() => {
    if (localStorage.getItem('cartContent')) {
      const retrievedCartContent = localStorage.getItem('cartContent')
      const parsedCartContent = JSON.parse(retrievedCartContent)
      dispatch(updateCartContent(parsedCartContent))
    }
  }, [dispatch])

  // Handle flow once org has been identified (check for user)
  useEffect(() => {
    async function getUser() {
      let user
      try {
        const token = await auth.currentUser?.getIdToken()
        localStorage.setItem("token", token)
        const newUserDetails = localStorage.getItem('newUserDetails')
        user = await fetchUserInfo(currentOrg.id, currentUser?.email, newUserDetails)
        if (newUserDetails) localStorage.removeItem('newUserDetails')
      } catch (err) {
        console.log('Could not retrieve valid user information', err)
      }

      // 1. updateLearnerInformation
      // 2. updatePathwayEnrollments (array of strings)
      // 3. updateCourseEnrollments (array of strings)
      // 4. updateCourseVotes (array of strings)
      // 5. updateFoundUser
      // 6. updateLoadingUser
      if (user) {
        const { firstName, lastName, email, orgUnitName, id } = user
        dispatch(updateLearnerInformation({ firstName, lastName, email, orgUnitName }))
        const registeredPathways = user.pathwayEnrollmentsByLearnerIdList.map(pathEnrollment => pathEnrollment.pathway.name)
        dispatch(updatePathwayRegistrations(registeredPathways))
        const registeredCohorts = user.enrollmentsByLearnerIdList.map(enrollment => enrollment.cohort)
        dispatch(updateCourseRegistrations(registeredCohorts))
        const requestedCourses = user.courseVotesByUserIdList.map(vote => vote.course.name)
        dispatch(updateCourseRequests(requestedCourses))
        dispatch(updateFoundUser(user))
        dispatch(fetchLearnerCurrentCohorts(id))
        setLoadingUser(false)
      }
    }

    // Handle flows once org has been identified
    // 1. Retrieve user details if currentUser (firebase user logged in)
    // 2. Prompt user to log in if org is private and/or set loading user to false
    if (currentOrg.id) {
      if (currentUser) {
        getUser()
      }
      else if (!loadingAuth) {
        if (currentOrg.preferences.privateCatalog) {
          dispatch(updateLoginOpen(true))
          setLoadingUser(false)
        } else {
          setLoadingUser(false)
        }
      }
    }
  }, [currentUser, currentOrg, dispatch, loadingAuth, location.search])

  // Watch for login and signup modals
  // 1. Redirect - If a currentUser (firebase) and a 'redirect' search param exist, if there's a foundUser (meldR db), 
  // redirect to Learner / Supervisor default page.
  // 2. Log in - If a 'login' search param exists and a firebase user is found, navigate tothe pathname without
  // the search params. Otherwise, open the login modal.
  // 3. Sign up - If a 'signup' search param exists and a current user is found, navigate tothe pathname without
  // the search params. Otherwise, open the signup modal.
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (currentUser && params.get('redirect')) {
      dispatch(updateLoginOpen(false))
      dispatch(updateSignupOpen(false))
      if (foundUser?.role === VIEW_TYPES.SUPERVISOR) {
        navigate(SUPERVISOR_DEFAULT_PAGE)
      } else if (foundUser?.role === VIEW_TYPES.LEARNER) {
        navigate(LEARNER_DEFAULT_PAGE)
      }
    } else if (params.get('login')) {
      if (currentUser) {
        navigate(location.pathname)
        dispatch(updateLoginOpen(false))
      } else {
        dispatch(updateLoginOpen(true))
      }
    } else if (params.get('signup')) {
      if (currentUser) {
        navigate(location.pathname)
        dispatch(updateSignupOpen(false))
      } else {
        dispatch(updateSignupOpen(true))
      }
    }
  }, [location, currentUser, foundUser, dispatch, navigate])

  // Fetch current organization on app init
  useEffect(() => {
    dispatch(fetchCurrentOrgDetails())
  }, [dispatch]);




  // Handle close snackbar function (from close button or clickaway)
  function handleCloseSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateSnackbarData(snackbarData.message, false, snackbarData.notificationType))
  }

  // If org is found, set secondaryColor according to org preferences. Otherwise, use default.
  let secondaryColor = ""
  if (currentOrg) {
    secondaryColor = currentOrg.preferences.colors.secondary
  }

  // If a mobile size is detected, return the Mobile component (screen size not supported) instead of the desktop app.
  useEffect(() => {
    const handleResize = () => {
      const isSupported = isScreenSupported()
      if (isSupported !== supportedScreen)
        setSupportedScreen(isSupported)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  if (!supportedScreen) {
    return (
      <ThemeProvider theme={theme(secondaryColor)}>
        <CssBaseline />
        <Routes>
          <Route path="*" element={<UnsupportedDevice />} />
        </Routes>
      </ThemeProvider>
    )
  }
  return (
    !loadingAuth &&
    <ThemeProvider theme={theme(secondaryColor)}>
      <CssBaseline />
      <WelcomeModal/>
      <Routes>
        <Route path='successful-payment' element={<PaymentConfirmation />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<InitialNavigate loadingAuth={loadingAuth} loadingUser={loadingUser} />} />
          <Route path="pathways" element={<PathwaysMap />}>
            <Route path=":pathName" element={<></>} />
            <Route path=":pathName/:courseName" element={<></>} />
          </Route>
          <Route path="course-catalog" element={<CourseCatalog />}>
            <Route path=":pathName" element={<></>} />
            <Route path=":pathName/:courseName" element={<></>} />
          </Route>
          <Route path="courses/:pathName/:courseName" element={<ExternalCoursesNavigate />}/>
          <Route path="faq" element={<ExternalRedirect url={FAQ_URL} />}/>
          <Route path="terms" element={<ExternalRedirect url={TERMS_URL} />}/>
          <Route path="manual" element={<ExternalRedirect url={'/meldR_User_Manual.pdf'} />}/>
          <Route element={<PrivateRoute routeType="general" loadingAuth={loadingAuth} loadingUser={loadingUser} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="course-content/:courseSlug/:cohortId" element={<CourseContent />} />
          </Route>
          <Route path="admin" element={<PrivateRoute routeType="admin" loadingAuth={loadingAuth} loadingUser={loadingUser} />}>
            <Route index element={<Navigate to="/admin/pathways?login=true" replace />} />
            <Route path="pathways" element={<PathwaysMap />}>
              <Route path=":pathName" element={<></>} />
              <Route path=":pathName/:courseName" element={<></>} />
            </Route>
            <Route path="course-catalog" element={<CourseCatalog />}>
              <Route path=":pathName" element={<></>} />
              <Route path=":pathName/:courseName" element={<></>} />
            </Route>
            <Route path="cohorts" element={<SupervisorCohorts />} />
            <Route path="browse-users" element={<BrowseUsers />} />
            <Route path="browse-users/:userId" element={<Profile />} />
            <Route path="account-settings" element={<AccountSettings />} />
          </Route>
          <Route path="reset-password" element={
            <FeaturedLoginRoute loadingAuth={loadingAuth} loadingUser={loadingUser}>
              <ForgotPassword />
            </FeaturedLoginRoute>
          } />
          <Route path='*' element={<ErrorPage errorType="404" />} />
        </Route>
      </Routes>
      <Signup />
      <Login />
      <Snackbar
        open={snackbarData?.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleCloseSnackbar}
      >
        <SnackbarNotification handleClose={handleCloseSnackbar} message={snackbarData.message} notificationType={snackbarData.notificationType} />
      </Snackbar>
    </ThemeProvider>
  );

}

export default App;
