import { DateTime } from 'luxon'

const dateParse = (dateString, offsetDays=0) => {
    // used in place of Date.parse() in order to have consistent timezone handling -- 
    // returns a DateTime of the first millisecord of the dateString (based on local System timezone)
    // offsetDays allows other functions that need to handle for inclusive or exclusive start and end dates to adjust accordingly

    const day = 1000 * 60 * 60 * 24

    if (dateString) {
        const parts = dateString.split('-')
        const date = new Date(parts[0], parts[1]-1, parts[2])
        return new Date(date.getTime() + offsetDays * day)
    } 
}

const betweenDates = (date, startString, endString, {startInclusive, endInclusive}= {}) => {
    //the first argument is a dateTime. Then next two are "YYYY-MM-DD"
    const startOffset = (startInclusive ? 0 : 1)
    const endOffset = (endInclusive ? 1 : 0)
    return dateParse(startString, startOffset) < date && dateParse(endString, endOffset) > date
}

const formattedDate = (dateString) => {
    const dateArray = dateString.split("-")
    const month = dateArray[1][0] ==="0"? dateArray[1][1] : dateArray[1]
    const day = dateArray[2][0] ==="0"? dateArray[2][1] : dateArray[2]
    const year = dateArray[0][0] ==="0"? dateArray[0][1] : dateArray[0]
    return month + "/" + day + "/" + year
}

const letteredDate = (dateString) => {
    const threeLetterMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const dateArray = dateString.split("-")
    const month = dateArray[1][0] ==="0"? dateArray[1][1] : dateArray[1]
    const day = dateArray[2][0] ==="0"? dateArray[2][1] : dateArray[2]
    return threeLetterMonths[parseInt(month - 1)] + " " + day
}

const addHours = (numOfHours, date) => {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date.toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'});
}

const getTimezoneName = () => {
    // Get user's timezone
    return DateTime.local().toFormat('ZZZZ')
}

export {
    betweenDates,
    dateParse,
    formattedDate,
    letteredDate,
    addHours,
    getTimezoneName
}