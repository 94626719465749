import React from 'react'
import SideNavBar from '../components/SideNavBar';
import TopBar from '../components/TopBar'
import { Outlet } from "react-router-dom";
import { Grid } from '@mui/material';
import { detectNotChrome } from '../lib/util'
import WarningBanner from '../components/WarningBanner'

function Layout() {
  return (
    <>
      {detectNotChrome() && <WarningBanner bannerType="browserSupport" />}
      <Grid container>
        <Grid item style={{ position: "fixed", width: "100px" }}>
          <SideNavBar />
        </Grid>
        {/* This is an empty grid item that takes the same space as the above NavBar component */}
        <Grid item style={{ width: "100px" }} />
        <Grid item style={{ width: 'calc(100% - 100px)' }}>
          <TopBar />
          <Outlet />
        </Grid>
      </Grid>
    </>
  )
}

export default Layout