import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import AvTimerIcon from '@material-ui/icons/AvTimer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { showDaysLeftTrigger } from '../../lib/status';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv'
import { daysLeft } from '../../lib/registrationRequest'

function CohortDisplayTableRow({ row, classes }) {
    const [open, setOpen] = useState(false);
    const csvLink = useRef()

    useEffect(()=> {
      if(row.signups===0 && open){
        setOpen(false)
      }
    // eslint-disable-next-line
    },[row.signups])

    const daysLeftBadge = (row) => {
      const daysLeftToEnroll = daysLeft(row.enrollmentEnd)
      if (daysLeftToEnroll <= showDaysLeftTrigger && daysLeftToEnroll >= 0) {
        return (
          <Grid item className={classes.daysLeft}>
                <AvTimerIcon /> 
                <span>
                    {`${daysLeftToEnroll}d`}
                </span>
          </Grid>
        )
      }
    }

    function csvData(row){
      return row.enrollments.map((enrollment, index) => {
        return {
            "": index+1, 
            "Course": row.course,
            "Dates": row.dates,
            "Registration": row.registration,
            "First Name": enrollment.learner.firstName,
            "Last Name": enrollment.learner.lastName,
            "Email": enrollment.learner.email,
            "Department": enrollment.learner.orgUnitName,
        }
      })  
  }

  function handleDownload() {
    csvLink.current.link.click()
  }

  
    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled={!(row.signups>0)}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" id={row.id} scope="row" padding="none">
              {row.course}
          </TableCell>
          <TableCell>{row.dates}</TableCell>
          <TableCell align="right">{row.signups}</TableCell>
          <TableCell>
            <div className ={`${classes.status} ${classes[row.rawStatus]}`}>{row.status}</div>
          </TableCell>
          <TableCell>
            <Grid container className={classes.registration}>
                {daysLeftBadge(row)}
                <Grid item>
                  {row.registration}
                </Grid>
            </Grid>
          </TableCell>
          <TableCell>
            <>
              <IconButton
                aria-label="download cohort"
                size="small"
                disabled={!(row.signups>0)}
                onClick={handleDownload}
              >
                <GetAppIcon />
              </IconButton>
              <CSVLink
                data={csvData(row)}
                filename={`${row.course} Enrollments`}
                style={{display:"none"}}
                separator={"\t"}
                ref={csvLink}
                target='_blank'
              />
            </>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="enrollments">
                    <TableHead>
                      <TableRow>
                        <TableCell className={`${classes.header} ${classes.nestedTableHeader}`} style={{width: '20%'}}>First Name</TableCell>
                        <TableCell className={`${classes.header} ${classes.nestedTableHeader}`} style={{width: '30%'}}>Last Name</TableCell>
                        <TableCell className={`${classes.header} ${classes.nestedTableHeader}`} style={{width: '30%'}}>Email</TableCell>
                        <TableCell className={`${classes.header} ${classes.nestedTableHeader}`} style={{width: '20%'}}>Department</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.enrollments.map((enrollment, index) => {
                        return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {enrollment.learner.firstName}
                          </TableCell>
                          <TableCell>{enrollment.learner.lastName}</TableCell>
                          <TableCell>{enrollment.learner.email}</TableCell>
                          <TableCell>{enrollment.learner.orgUnitName}</TableCell>
                          <TableCell />
                        </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
  
  CohortDisplayTableRow.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.number.isRequired,
      course: PropTypes.string.isRequired,
      dates: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      registration: PropTypes.string,
      enrollments: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
          name: PropTypes.string,
          orgUnitName: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
  };

  export default CohortDisplayTableRow