const GREEN = "#0A9565"
const BRIGHT_GREEN = "#00E676"
const PINK = "#C114B1"
const YELLOW = "#F0C717"
const BLUE = "#00B1E6"

const pathwayColorMap = {
    PT001: {
        hex: '#0E2A58',
        rgbValues: '14, 42, 88'
    },
    PT002: {
        hex: '#EE352E',
        rgbValues: '238, 53, 46'
    },
    PT003: {
        hex: '#093CE9',
        rgbValues: '9, 60, 233'
    },
    PT004: {
        hex: '#093CE9',
        rgbValues: '9, 60, 233'
    },
    PT005: {
        hex: '#093CE9',
        rgbValues: '9, 60, 233'
    },
    PT006: {
        hex: '#F28608',
        rgbValues: '242, 134, 8'
    },
    PT007: {
        hex: '#ACE809',
        rgbValues: '172, 232, 9'
    },
    PT008: {
        hex: '#09E8DF',
        rgbValues: '9, 232, 223'
    },
    PT009: {
        hex: '#2EDB0F',
        rgbValues: '46, 219, 15'
    },
    PT010: {
        hex: '#7909E8',
        rgbValues: '121, 9, 232'
    },
    PT011: {
        hex: '#DB0F9C',
        rgbValues: '219, 15, 156'
    },
    PT012: {
        hex: '#75B900',
        rgbValues: '117, 185, 0'
    },
    PT013: {
        hex: '#75B900',
        rgbValues: '117, 185, 0'
    },
    PT014: {
        hex: '#75B900',
        rgbValues: '117, 185, 0'
    },
    PT015: {
        hex: '#030721',
        rgbValues: '3, 7, 33'
    },
    PT016: {
        hex: '#030721',
        rgbValues: '3, 7, 33'
    },
    PT017: {
        hex: '#030721',
        rgbValues: '3, 7, 33'
    },
    default: {
        hex: '#093CE9',
        rgbValues: '9, 60, 233'
    }
}

export {
    GREEN,
    BRIGHT_GREEN,
    PINK,
    YELLOW,
    BLUE,
    pathwayColorMap
}

