import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CardsContainer from '../components/course_catalog/CardsContainer';
import LearnerPathHeading from '../components/LearnerPathHeading'
import SelectionDrawer from './SelectionDrawer';
import CatalogDownload from '../components/CatalogDownload';
import CourseCard from '../components/CourseCard';
import CourseSearch from '../components/course_catalog/CourseSearch';
import StatusFilter from '../components/StatusFilter';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as MeldrPoweredByDS } from '../assets/imgs/meldr-powered-by-ds.svg'
import { ALL_PATHS_TEXT, VIEW_TYPES, INITIAL_SELECTED_COURSE, SELECTION_TYPES } from '../lib/globalKeys'
import { pathIconComponent } from '../lib/mappedPathIcons';
import { COURSE_STATUSES, INITIAL_COURSE_STATUS_FILTER, REGISTRATION_STATUSES_LEARNER,  INITIAL_REGISTRATION_LEARNER_STATUS_FILTER } from '../lib/status';
import { nextEnrollableCohort, futureAvailableCohort } from '../lib/registrationRequest';
import leonError from'../assets/imgs/leon-error.png';
import { updateTopBarText } from '../redux/actions'
import { applyBooleanValuesFromSearchParams } from '../lib/util';

const dataSocietyURL = 'https://datasociety.com/'

const useStyles = makeStyles((theme) => ({
    fixedNavContainer: {
        position:"fixed",
        borderRight: `2px solid ${theme.palette.primary[50]}`,
        width: "400px"
    },
    fixedNavTop: {
        overflowY: "auto",
        height: "calc(100% - 250px)",
        padding:"2rem"
    },
    fixedNavFooter: {
        padding:"2rem",
        borderTop: `4px solid ${theme.palette.primary[50]}`,
        height:"250px"
    },
    pathGroupLabel: {
        margin: '18px 0px 0px',
        color: '#4F5164',
        fontSize: "0.875rem"
    },
    pathNav: {
        backgroundColor: "#ffffff",
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        padding: ".75rem",
        cursor: "pointer",
        textTransform:"none",
        justifyContent:"left",
        textAlign:"left"
    },
    selectedPathNav: {
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main,
        padding: ".75rem",
        pointerEvents: "none",
        textTransform:"none",
        justifyContent:"left",
        textAlign:"left",
    }
  }));

function CourseCatalog() {
    const { pathName, courseName } = useParams()

    const [searchParams, setSearchParams] = useSearchParams();

    // apply params to initial filter config
    const filterParams = INITIAL_REGISTRATION_LEARNER_STATUS_FILTER;
    applyBooleanValuesFromSearchParams(searchParams, filterParams);

    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [pathFilter, setPathFilter] = useState(ALL_PATHS_TEXT)
    const classes = useStyles()
    const learnerPaths = useSelector(state => state.learnerPaths)
    const allCourses = useSelector(state => state.allCourses)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selection, setSelection] = useState(INITIAL_SELECTED_COURSE)
    const [selectionType, setSelectionType] = useState('')
    const [courseSearchTerm, setCourseSearchTerm] = useState('')
    const [statusFilter, setStatusFilter] = useState(INITIAL_COURSE_STATUS_FILTER)
    const [statusFilterRegistration, setStatusFilterRegistration] = useState(INITIAL_REGISTRATION_LEARNER_STATUS_FILTER)
    const [isSupervisorView, setIsSupervisorView] = useState(false)
    const [scrollToId, setScrollToId] = useState(null)
    const cohorts = useSelector(state => state.cohorts)

    useEffect(() => {
        setIsSupervisorView(location.pathname.includes("/admin/"))
    }, [location.pathname])

    // Update Top Bar Heading
    useEffect(() => {
        if(isSupervisorView){
            dispatch(updateTopBarText('View & Purchase Pathways'))
        } else {
            dispatch(updateTopBarText('Course Catalog'))
        }
    }, [dispatch, isSupervisorView])


    function matchUrlPath(pathSlug) {
        const foundPath = learnerPaths.find(path => {
            return path.pathwaySlug===pathSlug
        })
        return foundPath
    } 

    function matchUrlCourse(courseSlug, foundPathName) {
        const foundCourse = allCourses.find(course => {
            return course.courseSlug===courseSlug && course.pathName===foundPathName
        })
        return foundCourse
    }

    useEffect(()=>{
        if(allCourses.length>0){
            const foundPath = matchUrlPath(pathName)
            if(foundPath){
                if(courseName && learnerPaths.length > 0){
                    const foundCourse = matchUrlCourse(courseName, foundPath.name)
                    if(foundCourse){
                        // set selected course and open drawer
                        setSelection(foundCourse)
                        setDrawerOpen(true)
                        setSelectionType(SELECTION_TYPES.COURSE)
                        // scroll to selected course card
                        setScrollToId(foundCourse.pathwaySlug+foundCourse.courseSlug)
                    } else {
                        alert(`Sorry, ${courseName} could not be located`)
                    }
                } else {
                    // set selected path and open drawer
                    setSelection(foundPath)
                    setDrawerOpen(true)
                    setSelectionType(SELECTION_TYPES.PATHWAY)
                    // scroll to selected pathway card
                    setScrollToId(foundPath.pathwaySlug)
                }
            } 
        }
        // eslint-disable-next-line
    }, [allCourses])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const pathParam = searchParams.get('path')
        const foundPath = learnerPaths.find(path => path.pathwaySlug === pathParam)
        setPathFilter(foundPath?.name)
        if(!pathParam){
            setPathFilter(ALL_PATHS_TEXT)
        }
    }, [learnerPaths, location.search])

    useEffect(() => {
        if(scrollToId){
            const elementToScroll = document.getElementById(scrollToId);
            window.scrollTo({
                top: elementToScroll.offsetTop-100,
            });
        }
    }, [scrollToId]);

    const filteredPaths =  (paths) => {
        if(pathFilter===ALL_PATHS_TEXT){
            return paths
        } else {
            return paths.filter(path => path.name===pathFilter)
        }
    }

    const filteredByStatus = (courses) => {
        if(isSupervisorView){
            if(statusFilter.purchased && statusFilter.requested){
                return courses.filter(course => nextEnrollableCohort(course) || futureAvailableCohort(course) || course.courseVotesByCourseIdList.length>0)
            } else if (statusFilter.purchased){
                return courses.filter(course => nextEnrollableCohort(course) || futureAvailableCohort(course))
            } else if (statusFilter.requested){
                return courses.filter(course => course.courseVotesByCourseIdList.length>0)
            }
        } else {
            if(statusFilterRegistration.open && statusFilterRegistration.upcoming){
                return courses.filter(course => nextEnrollableCohort(course) || futureAvailableCohort(course))
            } else if (statusFilterRegistration.open){
                return courses.filter(course => {
                    const foundNextCohort = nextEnrollableCohort(course)
                    let isAvailableCohortFull = true
                    if(foundNextCohort){
                        const foundCohort = cohorts.find(cohort => cohort.id === foundNextCohort.id)
                        isAvailableCohortFull = !!(parseInt(foundCohort?.statusDetails.seatsRemaining) <= 0)
                    }
                    return !isAvailableCohortFull
                })
            } else if (statusFilterRegistration.upcoming){
                return courses.filter(course => futureAvailableCohort(course))
            }
        }
        return courses
    }

    const filteredCourses = (segments) => {
        const courses = segments.map(segment => segment.course)
        return filteredByStatus(courses).filter(course => course.name.toLowerCase().includes(courseSearchTerm.toLocaleLowerCase()))
    }

    const filteredLearnerPathsByStatus = (paths) => {
        return paths.filter(path => {
            const pathSegments = path.learnerPathSegmentsByPathIdList
            const courses = pathSegments.map(segment => segment.course)
            return filteredByStatus(courses).find(course => course.name.toLowerCase().includes(courseSearchTerm.toLowerCase()))
        })
    } 

    const handleClickNavigation = (path) => {
        //if any drawers are open, close and clear
        setDrawerOpen(false)
        setSelection(INITIAL_SELECTED_COURSE)
        //navigate away from any selected paths/courses and include filter query
        const prefix = isSupervisorView? '/admin': ''
        const queryParam = path.pathwaySlug ? `?path=${path.pathwaySlug}`: ''
        navigate(`${prefix}/course-catalog${queryParam}`)
        // reset scroll to top
        window.scrollTo({ top: 0 })
    }

    const handleStatusFilterChange = (event) => {
        setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
    };

    const handleStatusFilterChangeRegistration = (event) => {
        setStatusFilterRegistration({ ...statusFilterRegistration, [event.target.name]: event.target.checked });

        // Also set this as a search param
        searchParams.set(event.target.name, event.target.checked);
        setSearchParams( searchParams );
    };

    // Build grouped pathway nav buttons
    let currentGroup
    let navButtonContents = []

    const pathwayItems = [{name: ALL_PATHS_TEXT, pathwayCode: ALL_PATHS_TEXT}].concat(learnerPaths)

    // Transform pathways into a collection of group names and paths
    pathwayItems.forEach((path,index)=>{
        // If the next group is different, then add a Typography item
        if (path.pathwayGroup && path.pathwayGroup !== currentGroup) {
            currentGroup = path.pathwayGroup
            navButtonContents.push((
                <Typography className={classes.pathGroupLabel}>
                    {path.pathwayGroup}
                </Typography>
            ))
        }

        // Always add the pathway item
        navButtonContents.push((
            <Button
                fullWidth
                className={pathFilter===path.name ? classes.selectedPathNav : classes.pathNav}
                onClick={() => handleClickNavigation(path)}
                startIcon={pathIconComponent(
                    path.pathwayCode,
                    { height:"36px", width:"36px" }
                )}
            >
                {path.name}
            </Button>
        ))
    })

    // Map contents to wrapped grid items
    const navButtons = navButtonContents.map((path, index) => {
        return (<Grid item key={index} xs={12}>{path}</Grid>)
    })

    const finalFilteredPaths = filteredLearnerPathsByStatus(filteredPaths(learnerPaths))

    const isFiltered = statusFilter.purchased || statusFilter.requested || statusFilter.open || statusFilter.upcoming || courseSearchTerm

    return(
        <Grid container justifyContent="space-between">
            <Grid item className={classes.fixedNavContainer}>
                <Grid container direction="column" justifyContent="space-between" style={{ height: "100vh" }}>
                    <Grid item className={classes.fixedNavTop}>
                        <Grid container spacing={1}>
                            {navButtons}
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" className={classes.fixedNavFooter}>
                        <Grid item style={{ marginTop: "-1rem" }}>
                            <CatalogDownload />
                        </Grid>
                        <Grid item xs={12}>
                            <a href={dataSocietyURL} target='_blank' rel='noopener noreferrer'>
                                <MeldrPoweredByDS />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ width: "400px" }} />
            <Grid item style={{ width: 'calc(100% - 400px)' }}>
                <CardsContainer
                    view={isSupervisorView ? VIEW_TYPES.SUPERVISOR : VIEW_TYPES.LEARNER}
                >
                    <Grid item container className={classes.topDivider} justifyContent="space-between" alignItems="center" style={{ padding: "1rem" }}>
                        {isSupervisorView ?
                            <StatusFilter statusFilter={statusFilter} handleStatusFilterChange={handleStatusFilterChange} statuses={COURSE_STATUSES} label={"Course Status"} />
                            :
                            <StatusFilter statusFilter={statusFilterRegistration} handleStatusFilterChange={handleStatusFilterChangeRegistration} statuses={REGISTRATION_STATUSES_LEARNER} label={"Registration Status"} />}

                        <CourseSearch courseSearchTerm={courseSearchTerm} setCourseSearchTerm={setCourseSearchTerm} />
                    </Grid>
                    {(finalFilteredPaths.length < 1 && learnerPaths.length > 0) &&
                        <Grid container style={{ padding: "1rem" }} justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3" style={{ fontWeight: 300, marginBottom: "1rem" }}>
                                    No Results Found
                                </Typography>
                                <Typography variant="h5">
                                    Try another search term or filter.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <img src={leonError} alt="No results found" style={{ height: "20rem" }} />
                            </Grid>
                        </Grid>}
                    {finalFilteredPaths.map((path, index) => {
                        const pathSegments = path.learnerPathSegmentsByPathIdList;
                        return (
                            <Grid item key={index} xs={12}>
                                <LearnerPathHeading
                                    divId={path.pathwaySlug}
                                    key={index}
                                    path={path}
                                    view={isSupervisorView ? VIEW_TYPES.SUPERVISOR : VIEW_TYPES.LEARNER}
                                    drawerOpen={drawerOpen}
                                    setDrawerOpen={setDrawerOpen}
                                    selection={selection}
                                    setSelection={setSelection}
                                    setSelectionType={setSelectionType} />
                                {filteredCourses(pathSegments)
                                    .map((course, index) => {
                                        return (
                                            <CourseCard
                                                divId={course.pathwaySlug + course.courseSlug}
                                                key={index}
                                                courseIndex={index}
                                                totalNumCourses={pathSegments.length}
                                                course={course}
                                                view={isSupervisorView ? VIEW_TYPES.SUPERVISOR : VIEW_TYPES.LEARNER}
                                                drawerOpen={drawerOpen}
                                                setDrawerOpen={setDrawerOpen}
                                                selection={selection}
                                                setSelection={setSelection}
                                                setSelectionType={setSelectionType}
                                                showPathwayConnections={isFiltered} />
                                        );
                                    })}
                            </Grid>
                        );
                    })}
                </CardsContainer>
            </Grid>
            <SelectionDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                view={isSupervisorView ? VIEW_TYPES.SUPERVISOR : VIEW_TYPES.LEARNER}
                selectionType={selectionType}
                selection={selection}
                setSelection={setSelection} />
        </Grid>
    )
}

export default CourseCatalog