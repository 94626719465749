import { createTheme } from '@material-ui/core/styles'
import { pink, red, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey } from '@material-ui/core/colors'

const colorOptions = ["pink", "red", "purple", "deepPurple", "indigo", "blue", "lightBlue", "cyan", "teal", "green", "lightGreen", "lime", "yellow", "amber", "orange", "deepOrange", "brown", "grey", "blueGrey"]
const COLOR_MAP = {
  DEFAULT: purple,
  pink: pink,
  red: red,
  purple: purple,
  deepPurple: deepPurple,
  indigo: indigo,
  blue: blue,
  lightBlue: lightBlue,
  cyan: cyan,
  teal: teal,
  green: green,
  lightGreen: lightGreen,
  lime: lime,
  yellow: yellow,
  amber: amber,
  orange: orange,
  deepOrange: deepOrange,
  brown: brown,
  grey: grey,
  blueGrey: blueGrey
}

function mappedColor(color){
  // no color specified
  if (!color) {
    return COLOR_MAP.DEFAULT;
  }

  let selected_color = COLOR_MAP[color];

  // Log invalid selection and return default color
  if( !selected_color ){
    console.warn(`Unsupported color selection "${color}"`);
    return COLOR_MAP.DEFAULT;
  }

  // return selected color
  return selected_color;
}

const defaultPrimary = {
  main: '#030721',
  50: '#E1E1E4',
  100: '#B3B5BC',
  200: '#818390',
  300: '#4F5164',
  400: '#292C42',
  500: '#030721',
  600: '#03061D',
  700: '#020518',
  800: '#020414',
  900: '#01020B',
  A100: '#5050FF',
  A200: '#1D1DFF',
  A400: '#0000E9',
  A700: '#0000CF'
}

const defaultSecondary = {
  main: '#1426FF',
  50: '#E3E5FF',
  100: '#B9BEFF',
  200: '#8A93FF',
  300: '#5B67FF',
  400: '#3747FF',
  500: '#1426FF',
  600: '#1222FF',
  700: '#0E1CFF',
  800: '#0B17FF',
  900: '#060DFF',
  A100: '#FFFFFF',
  A200: '#F3F3FF',
  A400: '#C0C1FF',
  A700: '#A7A8FF'
}

function theme(secondaryColor = defaultSecondary){
    const primaryColor = defaultPrimary
  
  // if secondary color isn't valid, default to meldR-DS colors
  if (!(colorOptions.includes(secondaryColor))){
    secondaryColor = defaultSecondary
  } else {
    secondaryColor = mappedColor(secondaryColor)
  }

  return createTheme({
    palette: {
        primary: primaryColor,
        secondary: secondaryColor,
        background: {
          paper: primaryColor[50],
          default: '#FFFFFF'
        },
      },
      typography: {
        fontFamily: [
          "Mulish",
          "Outfit",
          'sans-serif',
        ].join(','),
        button: {
          fontWeight: "bold"
        }
      },
  });
}

export const exportedForTesting = {
  mappedColor
}

export default theme
