import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { Box, Button, Dialog, DialogContent, Typography, Link } from '@material-ui/core';
import welcomescreenshot2 from '../../assets/imgs/welcomescreenshot2.png'
import welcomepathway from '../../assets/imgs/welcome_pathway.png'
import DataSocietyWelcomeLogo from '../../assets/imgs/DataSocietyWelcomeLogo.png'

const WelcomeModal = () => {
    const [open, setOpen] = useState(false);
    
    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('welcomeModalSeen', 'true');
    }

    // TODO: Should eventually have this passed from the backend, but logic to store if the modal has ran in local storage similar to site tour
    useEffect(() => {
        const hasOpenedModal = localStorage.getItem('welcomeModalSeen');

        if (!hasOpenedModal) {
            setOpen(true);
        }
    }, []);

    return (
            <Dialog 
                open={open}
                onClose={handleClose}
                aria-labelledby="welcome-modal-title"
                aria-describedby="welcome-modal-description"
                maxWidth="md"
            >
                <DialogContent style={{backgroundColor: "#fff", padding:"0"}}>
                    {/* Only apply padding to scrollable content so footer spans entire width of modal */}
                    <Box p={4}>
                    {/* Section 1 */}
                        <Typography
                            id="welcome-modal-title"
                            variant="h6"
                            component="h2"
                            style={{
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 900,
                                fontSize: 64
                                }}
                            tabIndex="0"
                        >
                            Welcome to meldR!
                        </Typography>
                        <Typography
                            id="welcome-modal-description"
                            variant="h3"
                            style={{
                                fontFamily: 'Mulish',
                                fontSize: '24px',
                                fontWeight: 700,
                                lineHeight: '32px',
                                textAlign: 'left'
                                }}
                        >
                            The latest Data Science and web technology training is now one click away! You can take online courses in AI, Machine Learning, web development, and data visualization with live instructors in an interactive learning environment where you write real code.
                        </Typography>

                    {/* Video embed */}
                        <ReactPlayer
                            url="https://vimeo.com/854551229/a33d946be1"
                            width="100%"
                            controls={true}
                            style={{
                                marginTop: '20px',
                                marginBottom: '20px'
                                }}
                        />

                    {/* Section 2 */}
                        <Box
                            mt="2.5rem"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <img
                                src={welcomescreenshot2}
                                alt="coding exercise screenshot"
                                style={{
                                    width: '357px',
                                    height: '284px',
                                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                                    borderRadius: '10px'
                                }}
                            />
                            <Box ml="2rem">
                                <Typography
                                    variant="h2"
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontSize: '32px',
                                        fontWeight: 900,
                                        lineHeight:'48px',
                                        textAlign: 'center'
                                        }}
                                    tabIndex="0"
                                >
                                     Live, Hands On Instruction
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: '30px',
                                        fontFamily: 'Mulish',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '26px',
                                        textAlign: 'left'
                                        }}
                                >
                                    All courses have live online instructors and come with code-along notebooks so you can put what you’ve learned to work in guided exercises inside meldR — no external tools required. All of your course material, including your code, stays available so you can reference it at any time.
                                </Typography>
                            </Box>
                        </Box>

                    {/* Section 3 */}
                        <Box mt="2.5rem">
                            <Typography
                                variant="h2"
                                style={{
                                    fontFamily: 'Mulish',
                                    fontSize: '32px',
                                    fontWeight: 900,
                                    lineHeight: '48px',
                                    textAlign: 'center',
                                    marginBottom: '20px'
                                    }}
                                tabIndex="0"
                            >
                                Discover the Right Pathway For You
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        lineHeight: '26px',
                                        textAlign: 'left',
                                        marginRight: '20px',
                                        flex: 1
                                        }}
                                >
                                    Interested in branching out? meldR shows you how all of our courses are connected, so you can map out a path to the skills you need that fits your interests and experience. 
                                </Typography>
                                <img
                                    src={welcomepathway}
                                    alt="pathway example"
                                    style={{
                                        width: '524px',
                                        height: '310px'
                                        }}
                                />
                            </Box>
                        </Box>


                    {/* End */}
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                        >
                            <Typography
                                component="span"
                                style={{
                                    fontFamily: 'Mulish',
                                    fontSize: '32px',
                                    fontWeight: 900,
                                    lineHeight: '48px',
                                    textAlign: 'center',
                                    marginRight: '10px'
                                    }}
                            >
                                Powered by
                            </Typography>
                            <img
                                src={DataSocietyWelcomeLogo}
                                alt="Data Society Logo"
                                style={{ height: '48px' }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="center"
                            mt={4}
                        >
                            <Link
                                aria-label="Learn more about Data Society"
                                style={{
                                    textDecoration: 'none',
                                    color: 'blue'
                                    }}
                                href="https://datasociety.com/"
                            >
                                LEARN MORE ABOUT DATA SOCIETY
                            </Link>
                        </Box>
                    </Box>

                    {/* Footer */}
                    <Box
                        style={{ 
                            position: 'sticky', 
                            bottom: 0, 
                            left: 0, 
                            right: 0, 
                            height: '100px', 
                            backgroundColor: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingRight: '20px'
                            }}
                    >
                        <Button 
                            aria-label="Start learning with Meldr"
                            onClick={handleClose} 
                            style={{ 
                                backgroundColor: '#1426FF', 
                                color: 'white',
                                }}
                        >
                            Start learning with Meldr
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
    );
}

export default WelcomeModal;