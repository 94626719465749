import React, { useRef } from 'react' 
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { CSVLink } from 'react-csv'
import { makeStyles } from '@material-ui/core/styles';
import { nextEnrollableCohort, futureAvailableCohort } from '../../lib/registrationRequest';
import { SELECTION_TYPES } from '../../lib/globalKeys';
import { BRIGHT_GREEN } from '../../assets/colors';
import { updateCartContent } from '../../redux/actions'

const useStyles = makeStyles((theme) => ({
    fixedDrawerFooter: {
        height: "8rem",
        padding: "1.5rem 2rem",
        borderTop: `4px solid ${theme.palette.primary[50]}`,
    },
  }));

function SupervisorDrawerCta({setDrawerOpen, selection, setSelection, selectionType}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const csvLink = useRef()
    const cartContent = useSelector(state => state.cartContent)
    const learnerPaths = useSelector(state => state.learnerPaths)
    const coursePurchaseExists = selectionType === SELECTION_TYPES.COURSE && selection.coursePurchasesByCourseIdList?.length > 0
    const allCohorts = useSelector(state => state.cohorts)
    let foundAvailableCohort
    if(selectionType === SELECTION_TYPES.COURSE) {
        foundAvailableCohort = nextEnrollableCohort(selection)
    }
    const availableEnrollableCohort = allCohorts?.find(cohort => foundAvailableCohort?.id === cohort.id)
    const isAvailableCohortFull = !!(parseInt(availableEnrollableCohort?.statusDetails.seatsRemaining) <= 0)
    const willBeEnrollable = selectionType === SELECTION_TYPES.COURSE && futureAvailableCohort(selection)

    function csvData(votes, course){
        return selectionType === SELECTION_TYPES.COURSE && votes.map((vote, index) => {
            return {
                "": index+1, 
                "First Name": vote.user.firstName,
                "Last Name": vote.user.lastName,
                "Email": vote.user.email,
                "Department": vote.user.orgUnitName,
                "Course": course.name
            }
        })  
    }

    function handleDownload() {
        selectionType === SELECTION_TYPES.COURSE && csvLink.current.link.click()
    }

    const addCourseToCart = (course) => {
        let updatedCartContent
        let courseArray = []
        if(cartContent[course.pathwayCode]){
            courseArray = cartContent[course.pathwayCode]
        }
        courseArray.push(course.courseCode)
        updatedCartContent = {...cartContent,  [course.pathwayCode]: courseArray }  
        dispatch(updateCartContent(updatedCartContent))
        const stringifiedCartContent=JSON.stringify(updatedCartContent)
        localStorage.setItem('cartContent', stringifiedCartContent)
        setDrawerOpen(false)
    }
    
    const addPathwayToCart = (pathway) => {
        let updatedCartContent
        let courseArray = []
        const pathwayObj = learnerPaths.find(path => pathway.pathwayCode === path.pathwayCode)
        if(pathwayObj){
            const courseSegments = pathwayObj.learnerPathSegmentsByPathIdList
            courseArray = courseSegments.map(cs => {
                return cs.course.courseCode
            })
            if(courseArray?.length < 1){
                console.log('Error: No courses found')
                // TODO: Throw error notification
            } else {
                updatedCartContent = {...cartContent,  [pathway.pathwayCode]: courseArray }  
                dispatch(updateCartContent(updatedCartContent))
                const stringifiedCartContent=JSON.stringify(updatedCartContent)
                localStorage.setItem('cartContent', stringifiedCartContent)
            }
        } else {
            console.log('Error: Pathway not found')
            // TODO: Throw error notification
        }   
        setDrawerOpen(false)
    }

    function handleOnClickAddToCart() {
        if (selectionType === SELECTION_TYPES.COURSE) {
            addCourseToCart(selection)
        } else {
            addPathwayToCart(selection)
        }
    }

    const selectionInCart = () => {
        if(selectionType === SELECTION_TYPES.COURSE){
            // checks to see if the cart contains anything from the course's pathway
            if(cartContent[selection.pathwayCode]){
                return cartContent[selection.pathwayCode].includes(selection.courseCode)
            }
        } else {
            // checks to see if the cart contains the pathway
            if(cartContent[selection.pathwayCode]){
                const pathwayObj = learnerPaths.find(path => selection.pathwayCode === path.pathwayCode)
                return pathwayObj.learnerPathSegmentsByPathIdList?.length === cartContent[selection.pathwayCode]?.length
            } else {
                return false
            }
        }
    }

    const requestsCsvButton = () => {
        function DisplayButtonCSV({csvType}) {
            return <Grid item>
                        <>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDownload}
                                startIcon={<GetAppOutlinedIcon />}
                            >
                            {csvType} CSV
                            </Button>
                            <CSVLink
                                data={csvData(selection.courseVotesByCourseIdList, selection)}
                                filename={`${selection.name} ${csvType}`}
                                style={{display:"none"}}
                                separator={"\t"}
                                ref={csvLink}
                                target='_blank'
                            />
                        </>
                    </Grid>
        }

        if(selectionType === SELECTION_TYPES.COURSE && selection.courseVotesByCourseIdList?.length>0){
            if(coursePurchaseExists){
                if(!(availableEnrollableCohort && !isAvailableCohortFull) && !willBeEnrollable){
                    return <DisplayButtonCSV csvType={"Waitlist"}/>
                }
            } else {
                return <DisplayButtonCSV csvType={"Requests"}/>
            }
        }
        return null
    }

    const purchasedButton = (<Button 
        variant="contained"
        color="secondary"
        disabled
        endIcon={<CheckIcon style={{color: BRIGHT_GREEN}} />}>
        Purchased
    </Button>)

    const addToCartButton = (<Button 
        variant="contained"
        color="secondary"
        onClick={handleOnClickAddToCart}>
        Add to Cart
    </Button>)

    const disabledAddedToCartButton = (<Button 
        variant="contained"
        color="secondary"
        disabled>
        Added to Cart
    </Button>)

    const mappedFooter = () => {

        let text, button
        
        if (coursePurchaseExists) {
            // Removed check/display for if pathways is purchased
            text = `Thank you for purchasing this ${selectionType}.`
            button = purchasedButton
        } else if (selectionInCart()) {
            text = `This ${selectionType} has been added to your cart.`
            button = disabledAddedToCartButton
        } else {
            text = `This will add this ${selectionType} to your cart.`
            button = addToCartButton
        }
        
        
        return (<>
            <Typography align="center" variant="subtitle1" style={{fontWeight:"bold", paddingBottom:"0.5rem"}} color="primary">
                {text}
            </Typography>
            <Grid container justifyContent='center' spacing={1}>
                <Grid item>
                    {button}
                </Grid>
                {requestsCsvButton()}
            </Grid> 
        </>)
    }

    return(
        <div className = {classes.fixedDrawerFooter}>
            {mappedFooter()}
        </div>
    )
}

export default SupervisorDrawerCta