import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LinearProgress, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { fetchCourseSlides } from '../lib/courseSlides'
import NavElement from '../components/course_content/NavElement'
import { updateTopBarText } from '../redux/actions';
import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as WorkspaceIcon } from '../assets/imgs/icons/workspace.svg'
import { ReactComponent as VideoIcon } from '../assets/imgs/icons/live_session_videocam.svg'
import { ReactComponent as DocsIcon } from '../assets/imgs/icons/orientation_documents.svg'
import TopBackground from '../assets/imgs/course_content_image.jpg'
import PartnershipBackground from '../assets/imgs/partnership_background.jpg'
import pluralize from 'pluralize';
import { addHours, getTimezoneName } from '../lib/date';
import { mappedSteps } from '../lib/joyride';
import CustomJoyride from '../components/joyride/CustomJoyride'; 
import ErrorPage from './ErrorPage'
import { handleCopy } from '../lib/util';

const welcomeFromDataSocietyPDF = '/Welcome_from_Data_Society.pdf'
const trainingEnvironmentDocPDF = '/Training_Environment_Doc.pdf'

function CourseContent() {
    const { courseSlug, cohortId } = useParams()
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const currentOrg = useSelector(state => state.currentOrg)
    const dispatch = useDispatch()
    const [hasLoaded, setHasLoaded] = useState(false)
    const [dataReady, setDataReady] = useState(false)
    const [navOpen, setNavOpen] = useState(true)
    const [selectedSlidesObj, setSelectedSlidesObj] = useState(null)
	const [currentCohort, setCurrentCohort] = useState(null)
    const [courseSlides, setCourseSlides] = useState(null)
    const [cohortSessions, setCohortSessions] = useState([])
    const joyRideRef = useRef(null)
    const [runTour, setRunTour] = useState(false)
    const [loadingTour, setLoadingTour] = useState(true)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const partnership = currentCohort?.coursePurchase.course.partnership
    const externalLink = currentCohort?.externalDelivery?.ext_delivery_link
    const isDeliveryText = currentCohort?.externalDelivery?.ext_delivery_value
    const partnerColor = currentCohort?.coursePurchase.course.partnership?.color

    //TODO: Need to update to check for workspace link (generation in backend)
    const workspaceLink = courseSlides?.workspace_link
    const contentDownloadLink = courseSlides?.download_link

    const useStyles = makeStyles((theme) => ({
        collapsedActions:{
            fill: "white",
            color: "white",
            position: "absolute", 
            marginLeft: "0.5rem",
            padding:"0.5rem 0.75rem",
            border: "1px solid white",
            borderRadius: "4px"
        },
        collapsedActionsBlue:{
            fill: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
            position: "absolute", 
            marginLeft: "0.5rem",
            padding:"0.5rem 0.75rem",
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: "4px",
            '&:hover': {
                backgroundColor: theme.palette.secondary[50],
            }
        },
        courseIcon: {
            width:"1rem", 
            paddingTop:"4px",
            color: theme.palette.primary[400]
        },
        customIconBlue: {
            fill: theme.palette.secondary.main
        },
        descriptionHeading: {
            fontWeight:"bold",
            paddingBottom:"0.5rem"
        },
        externalTextContainer: {
            backgroundColor: partnerColor, 
            paddingLeft: "0.5rem",
            borderRadius: "5px",
        },
        externalText: {
            padding:"0.25rem 0.5rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 4px 4px 0px",
            border: "1px solid #E0E0E4",
        },
        fixedNavContainer: {
            position:"fixed",
            borderRight: `1px solid ${theme.palette.primary[50]}`,
            width: "400px"
        },
        fixedNavTop: {
            overflowY: "auto",
            maxHeight: "65%",
            padding:"1rem"
        },
        fixedNavFooter: {
            overflowY: "auto",
            padding:"1.5rem 1rem 4rem 1rem",
            borderTop: `1px solid ${theme.palette.primary[50]}`,
            maxHeight:"35%"
        },
        headerBackground: {
            backgroundImage: `url(${!!partnership ? PartnershipBackground : TopBackground})`, 
            backgroundSize: "cover"
        },
        headerText: {
            color: !!partnership ?theme.palette.primary.main : "#ffffff",
            fontWeight: 700
        },
        lightText: {
            color: theme.palette.primary[400]
        },
        sideExpand: {
            position: "absolute", 
            color: !!partnership ? theme.palette.primary.main :"white",
            marginTop:"2rem",
            padding:"0.75rem 1rem",
            border: `1px solid ${!!partnership ? theme.palette.primary.main :"white"}`,
            borderLeft: "none",
            borderRadius: "0px 4px 4px 0px"
        },
        tagBackground: {
            padding:"0rem 0.5rem"
        },
        topLevelNavIcon: {
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main
        },
        verticalIcon:{
            paddingTop:"6px",
            color: theme.palette.primary[300]
        }
    }));

    const classes = useStyles()

    useEffect(() => {
        const timeZone = getTimezoneName()
        const retrievedCurrentCohort = registeredCohorts.filter(cohort => cohort.id === parseInt(cohortId))[0];
        const retrievedCohortSessions = retrievedCurrentCohort?.cohortSessionsByCohortIdList.map(retrievedSession => {
            const dateTime = new Date(retrievedSession.startTimestamp)
            const dateStr = dateTime.toLocaleDateString()
            const timeStr = `${dateTime.toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'})}-${addHours(retrievedSession.durationHours, dateTime)} ${timeZone}`
            const startTime = `${new Date(retrievedSession.startTimestamp).toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'})} ${timeZone}`
            return {
                dateStr: dateStr,
                timeStr: timeStr,
                startTime: startTime
            }
        })
        setCurrentCohort(retrievedCurrentCohort)
        setCohortSessions(retrievedCohortSessions)
    }, [cohortId, registeredCohorts])

    // fetch course course slides with signed URLs
    useEffect(() => {
        async function getCourseSlides() {
            try {
                const token = localStorage.token || ""
                let response = await fetchCourseSlides(courseSlug, cohortId);
                let retrievedCourseSlides = JSON.parse(response?.data?.CourseSlideURLs?.course_slide_urls)
                const formattedCourseSlides = retrievedCourseSlides?.topics?.map(topic => {
                    const modifiedTopic = {
                        topicName: topic.topic_title,
                        subLevels: topic.modules.map(module => {
                            return {
                                navType: 'code',
                                navAction: 'slides',
                                text: module.module_code,
                                link: module.module_link
                            }
                        })
                    }

                    // Add Excercise / Notebook links if provided
                    if( topic.exercises ) {
                        modifiedTopic.subLevels.push({
                            navType: 'launch',
                            navAction: 'new_tab',
                            text: 'Exercises',
                            link: `${topic.exercises}?$token=${token}`
                        })
                    }

                    if( topic.code_notebook ){
                        modifiedTopic.subLevels.push({
                            navType: 'launch',
                            navAction: 'new_tab',
                            text: 'Code Notebook',
                            link: `${topic.code_notebook}?$token=${token}`
                        })
                    }

                    return modifiedTopic
                })
                formattedCourseSlides.workspace_link = retrievedCourseSlides?.workspace_link
                formattedCourseSlides.download_link = retrievedCourseSlides?.download_url
                setCourseSlides(formattedCourseSlides)
            } catch(err) {
                console.log('Could not retrieve course slides', err)
                setCourseSlides(undefined)
            }
        }
        if(cohortId){
            getCourseSlides()
        }
    }, [cohortId, courseSlug, currentOrg]);

    // Update Top Bar Heading
    useEffect(() => {
        if(currentCohort?.coursePurchase?.course){
            dispatch(updateTopBarText(currentCohort?.coursePurchase?.course?.name))
        }
    }, [dispatch, currentCohort])

    //Check if tour has already run
    useEffect(()=>{
        if(localStorage.getItem('courseContentTour')){
            setRunTour(false)
        }else{
            setTimeout(() => {
                setRunTour(true)
            }, 1000)
        }
    },[])

    const handleClickNext = () => joyRideRef.current.store.next()
    const handleClickEnd = () => {
        localStorage.setItem('courseContentTour', true)
        return joyRideRef.current.store.stop()
    }

    useEffect(() => {
        const firstExpandBtn = document.getElementById("first-course-expand")
        if(firstExpandBtn && runTour){
            firstExpandBtn.click()
            setLoadingTour(false)
        }
    }, [currentCohort, courseSlides, runTour])

    // Build content page tour steps
    const courseContentSteps = [
        {
            target: '#first-sub-menu-item',
            stepText: "View upcoming Course Content here.",
            placement: 'right-start',
        }
    ]

    // Conditionally add the schedule
    if( cohortSessions.length > 0 ){
        courseContentSteps.push({
            target: '#cohort-schedule',
            stepText: "View your Cohort Schedule here.",
            placement: 'right-start',
        })
    }

    courseContentSteps.push({
            target: '#connect-to-workspace-btn',
            stepText: "Click ‘Connect to Workspace’ to enter your Integrated Development Environment (IDE).",
            placement: 'top-end',
        })

    courseContentSteps.push({
            target: '#open-video-btn',
            stepText: "Click ‘Open Video Link’ to join your instructor for a scheduled cohort session.",
            placement: 'top-end',
        })

    const mappedCourseContentSteps = mappedSteps(courseContentSteps, "Start Learning", handleClickNext, handleClickEnd)
    // Watch for loaded state
    useEffect(() => {
        if( currentCohort !== null && courseSlides !== null ){
            // We've completed loading (values are non-default)
            setHasLoaded(true)

            // Also set whether this loaded data is 'ready'
            // If one of this is loaded, but not set (e.g. there was an error),
            //   then we'll need to respond to that
            if(!!currentCohort && currentCohort?.coursePurchase.course.partnership){
                setDataReady(true)
            } else if(!!(currentCohort && courseSlides)) {
                setDataReady(true)
            }
        }
    }, [currentCohort, courseSlides])

	// TODO: make tags a reusable component instead of creating them separately here and in LearnerPathHeading / SelectionDrawer
	const courseTags = currentCohort?.coursePurchase?.course?.tags
	const courseDescription = currentCohort?.coursePurchase?.course?.description
	const courseSoftwareRequirements = currentCohort?.coursePurchase?.course?.softwareRequirements
	const coursePrerequisites = currentCohort?.coursePurchase?.course?.prerequisitesDescription
    let orientationDocuments
    const defaultOrientationDocs = [
        {
            navType: 'welcome',
            navAction: 'new_tab',
            text: 'Welcome from Data Society',
            link: welcomeFromDataSocietyPDF
        },
        {
            navType: 'welcome',
            navAction: 'new_tab',
            text: 'Training Environment Doc',
            link: trainingEnvironmentDocPDF
        }
    ]
    if(partnership?.orientationDocs){
        orientationDocuments=partnership?.orientationDocs
    } else {
        orientationDocuments=defaultOrientationDocs
    }

	function handleWorkspaceLink() {
		const token = localStorage.token || ""
        let jhub_url = `${courseSlides.workspace_link}?$token=${token}`
        return jhub_url
	}

    function getSessionLinkText( sessionLink ){
        const destinationMap = {
            "zoom.us": "Zoom",
            "webex.com": "Webex"
        }

        const destinationDomainKey = Object.keys(destinationMap)
        .find( domain => sessionLink.includes(domain))

        const destination = destinationDomainKey ? destinationMap[destinationDomainKey] : "Session"

        return `Open ${destination} Link`
    }

    // Show loading indicator while loading
    // show error if loading failed
    // show content otherwise
    if( !hasLoaded ) return <LinearProgress color="secondary" />
    else if (!dataReady) return <ErrorPage errorType="Unknown" />
    else return(
            <>
                <Grid container>
                        {
                            navOpen &&
                            <>
                                <Grid item className={classes.fixedNavContainer}>
                                    <Grid container direction = "column" justifyContent="space-between" style={{height: "95vh"}}>
                                        <Grid item className={classes.fixedNavTop} >
                                            <Grid container spacing={1} justifyContent='space-between' alignItems="center" style={{paddingBottom:"1rem"}}>
                                                <Grid item>
                                                    <Typography variant="h6" style={{fontWeight:"bold"}}>
                                                        Course Content
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton size="small" onClick={()=>setNavOpen(false)}>
                                                        <KeyboardDoubleArrowLeftIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <Divider style={{margin: "0rem -1rem"}} />
                                            <Grid container style={{padding:"0.75rem"}}>
                                                {
                                                    courseSlides && courseSlides.map((topic, index) => {
                                                        return(
                                                            <NavElement
                                                                navIndex={index}
                                                                key={topic+index}
                                                                menuIcon={
                                                                    <BookOutlinedIcon className={classes.topLevelNavIcon} style={{paddingTop: '2px'}} />
                                                                }
                                                                menuText={topic.topicName}
                                                                subMenuArray={topic.subLevels}
                                                                selectedSlidesObj={selectedSlidesObj}
                                                                setSelectedSlidesObj={setSelectedSlidesObj}
                                                            />
                                                        )
                                                    })
                                                }
                                                {
                                                <NavElement
                                                        menuIcon={
                                                            <DocsIcon className={classes.topLevelNavIcon} style={{paddingTop: '2px'}} />
                                                        }
                                                        menuText={'Orientation Documents'}
                                                        subMenuArray={orientationDocuments}
                                                        selectedSlidesObj={selectedSlidesObj}
                                                        setSelectedSlidesObj={setSelectedSlidesObj}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.fixedNavFooter}>
                                            <Grid container>
                                                {
                                                    isDeliveryText &&
                                                    <>
                                                        <textarea id="copy-value-course-content" readOnly name="copy-value-course-content" value={currentCohort?.externalDelivery?.ext_delivery_value} hidden/>
                                                        <Grid item container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <div className={classes.externalTextContainer}>
                                                                    <div className={classes.externalText}>
                                                                        <Typography variant="body1" display="inline" style={{fontWeight:"700"}}>
                                                                            {currentCohort?.externalDelivery?.ext_delivery_label.toUpperCase() + ": "}
                                                                        </Typography>
                                                                        <Typography display="inline" variant="body1" style={{fontWeight:"700"}}>
                                                                            {currentCohort?.externalDelivery?.ext_delivery_value}
                                                                        </Typography>
                                                                    </div>
                                                                </div> 
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip 
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    placement="right"
                                                                    onClose={() => setTooltipOpen(false)}
                                                                    open={tooltipOpen}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title="Copied"
                                                                >
                                                                    <IconButton
                                                                        onClick={() => handleCopy('copy-value-course-content', setTooltipOpen)}
                                                                        size="small"
                                                                    >
                                                                        <ContentCopyIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                                    {
                                                        !!workspaceLink &&
                                                        <Grid item xs={12}>
                                                        <Button
                                                            id="connect-to-workspace-btn"
                                                            fullWidth
                                                            variant="contained"
                                                            color="secondary"
                                                            startIcon={<WorkspaceIcon style={{fill: "white"}}/>}
                                                            href={handleWorkspaceLink()}
                                                            style={{margin:"0.5rem 0rem"}}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            Connect to Workspace
                                                        </Button>
                                                        </Grid>
                                                    }
                                                    {
                                                        !!externalLink &&
                                                        <Grid item xs={12}>
                                                        <Button
                                                            id="open-video-btn"
                                                            fullWidth
                                                            variant="contained"
                                                            color="secondary"
                                                            startIcon={<LaunchIcon style={{fill: "white"}}/>}
                                                            style={{margin:"0.5rem 0rem"}}
                                                            href={externalLink}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            Open Course in New Tab
                                                        </Button>
                                                        </Grid>
                                                    }
                                                    {
                                                        !!currentCohort?.liveSessionLink &&
                                                        <Grid item xs={12}>
                                                            <Button
                                                                id="open-video-btn"
                                                                fullWidth
                                                                variant="outlined"
                                                                color="secondary"
                                                                startIcon={<VideoIcon className={classes.customIconBlue}/>}
                                                                style={{margin:"0.5rem 0rem"}}
                                                                href={currentCohort?.liveSessionLink}
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                {getSessionLinkText(currentCohort?.liveSessionLink)}
                                                            </Button>
                                                        </Grid>
                                                    }
                                                    {
                                                        !!contentDownloadLink &&
                                                        <Grid item xs={12}>
                                                            <Button
                                                                id="download-materials-btn"
                                                                fullWidth
                                                                variant="outlined"
                                                                color="secondary"
                                                                startIcon={<DownloadIcon className={classes.customIconBlue}/>}
                                                                href={contentDownloadLink}
                                                                style={{margin:"0.5rem 0rem"}}
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                            >
                                                                Download Course Content
                                                            </Button>
                                                        </Grid>
                                                    }
                                                    { cohortSessions.length > 0 &&
                                                    <Grid item xs={12}>
                                                        <Typography align="center">
                                                            Course starts {`${cohortSessions[0].startTime}, ${cohortSessions[0].dateStr}`}
                                                        </Typography>
                                                        </Grid>
                                                    }
                                              
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{padding:"1rem", width:"400px"}}/>
                            </>
                        }
                        <Grid item style={{width: navOpen ? "calc(100% - 400px)" : "100%"}}>
                            {
                                !navOpen &&
                                <>  
                                    <IconButton
                                        size="small"
                                        aria-label='Expand Navigation of Course Materials, button'
                                        className={classes.sideExpand}
                                        onClick={() => setNavOpen(true)}
                                    >
                                        <KeyboardDoubleArrowRightIcon/>
                                    </IconButton>
                                    {
                                        !!workspaceLink &&
                                        <IconButton
                                            size="small"
                                            className={selectedSlidesObj ? classes.collapsedActions : classes.collapsedActionsBlue}
                                            style={{bottom: !!externalLink? '12rem' : '8.5rem'}}
                                            href={handleWorkspaceLink()}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <WorkspaceIcon />
                                        </IconButton>
                                    }
                                    {
                                        !!externalLink &&
                                        <IconButton
                                            size="small"
                                            className={selectedSlidesObj ? classes.collapsedActions : classes.collapsedActionsBlue}
                                            style={{bottom: '8.5rem'}}
                                            href={externalLink}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    }
                                    {
                                        !!currentCohort?.liveSessionLink && 
                                        <IconButton
                                            size="small"
                                            className={selectedSlidesObj ? classes.collapsedActions : classes.collapsedActionsBlue}
                                            style={{bottom: '5rem'}}
                                            href={currentCohort?.liveSessionLink}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <VideoIcon />
                                        </IconButton>
                                    }
                                    {
                                        !!contentDownloadLink &&
                                        <IconButton
                                            size="small"
                                            className={selectedSlidesObj ? classes.collapsedActions : classes.collapsedActionsBlue}
                                            style={{bottom: !!externalLink? '12rem' : '8.5rem'}}
                                            href={contentDownloadLink}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    }
                                </>
                            }
                        <Grid container justifyContent="center">
                            {
                                selectedSlidesObj ?
                                    <Grid item xs={12} style={{backgroundColor:"#27252c", padding: (navOpen? "2rem" : "2rem 7rem"), height: (navOpen? 550 : "85vh")}}>
                                        <iframe height="100%" width="100%" title="course-slides-all" src={selectedSlidesObj.link} />
                                    </Grid>
                                :
                                    <Grid item xs={12} className={classes.headerBackground} style={{padding: (navOpen? (partnership?.logo? "1rem 4rem" : "4rem") : "7rem")}}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={9}>
                                                <Typography variant="h5" className={classes.headerText}>
                                                    Select a file on the left to view it
                                                </Typography>
                                            </Grid>
                                            {
                                               partnership?.logo &&
                                            <Grid item>
                                                <img
                                                    alt={`${partnership?.name}`}
                                                    src={`${partnership?.logo}`}/>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                            }
                            <Grid container item xs={12} style={{padding: navOpen ? "2rem 4rem" : "2rem 7rem"}}>
                                {
                                cohortSessions.length !== 0 &&
                                    <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" className={classes.descriptionHeading}>Cohort Schedule</Typography>
                                        </Grid>
                                        <Grid item container xs={navOpen ? 4 : 3}>
                                            <Paper id="cohort-schedule" elevation={0} style={{backgroundColor: "#fafafa", padding:"1rem", width:"100%", marginTop: "0.5rem"}}>
                                                {
                                                    cohortSessions.map((session, index) => {
                                                        return(
                                                            <Grid key={session.dateStr + index} item container justifyContent='space-between'>
                                                                <Grid item>{session.dateStr}</Grid>
                                                                <Grid item>{session.timeStr}</Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12} style={{paddingTop: "1.5rem"}}>
                                    <Typography variant="h5" className={classes.descriptionHeading}>Course Description</Typography>
                                </Grid>
                                <Grid item xs={12} container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <ScheduleIcon className={classes.courseIcon} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.lightText} variant="subtitle2">
                                                    {Math.round(currentCohort?.coursePurchase?.course?.hours)} Hours
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <BookOutlinedIcon className={classes.verticalIcon} />
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.lightText} variant="subtitle2">
                                                    {pluralize("Topic", currentCohort?.coursePurchase?.course?.courseStructure.length, true)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1} xs={navOpen? 8 : 6} style={{padding:"0rem 1rem 1rem 0rem"}}>
                                    {
                                        courseTags.map((tag, index) => {
                                            return (
                                                <Grid item key={tag+index}>
                                                    <Paper className = {classes.tagBackground} elevation={0}>
                                                        <Typography variant="caption" style={{fontFamily:"Roboto", fontWeight:"500"}}>{tag.toUpperCase()}</Typography>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item>
                                        <Typography variant="body1">{courseDescription}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={navOpen? 8 : 6}>
                                    <Paper style={{padding:"1rem"}} elevation={0}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="subtitle2" style={{fontWeight:"bold", fontSize: "0.625rem"}}>SOFTWARE REQUIREMENTS:</Typography>
                                                <Typography color="primary" variant="subtitle2" style={{fontSize: "0.75rem"}}>{courseSoftwareRequirements}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="subtitle2" style={{fontWeight:"bold", fontSize: "0.625rem"}}>PREREQUISITES:</Typography>
                                                <Typography color="primary" variant="subtitle2" style={{fontSize: "0.75rem"}}>{coursePrerequisites}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            {
                !loadingTour &&
                <CustomJoyride
                    ref={joyRideRef}
                    steps={mappedCourseContentSteps}
                    // run={runTour}
                    run={true}
                />
            }
        </>
    )
}

export default CourseContent