import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { pathIconComponent } from '../../lib/mappedPathIcons';
import ProfileTag from '../ProfileTag';
import { ReactComponent as PathwaysIcon} from '../../assets/imgs/icons/pathways.svg'
import { ReactComponent as CourseCatalogIcon} from '../../assets/imgs/icons/bookmark.svg'
import { daysLeftInCourse } from '../../lib/registrationRequest';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { createEnrollment, fetchInvoiceUrl, fetchPaymentUrl, nextEnrollableCohort } from '../../lib/registrationRequest';
import { updateSnackbarData, updateCourseRegistrations } from '../../redux/actions'
import { showDaysLeftTrigger } from '../../lib/status';
import { addHours, formattedDate, getTimezoneName } from '../../lib/date'
import pluralize from 'pluralize';
import { handleCopy } from '../../lib/util'
import { pathwayColorMap } from '../../assets/colors.js'
import DaysLeftIndicator from '../course_state/DaysLeftIndicator.jsx';

function DashboardCourseCard({cardIndex, cohortStatusObj, course, pathway, cohort, ctaType="register"}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentOrg = useSelector(state => state.currentOrg)
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const paymentEnabled = currentOrg.featPayment
    const learnerInformation = useSelector(state => state.learnerInformation)
    const timeZone = getTimezoneName()
    const [tooltipOpen, setTooltipOpen] = useState(false)

    // USED FOR PARTNER COURSES
    const isDeliveryText = cohort.externalDelivery?.ext_delivery_value

    const pathwayColorObj = pathwayColorMap[pathway.pathwayCode] || pathwayColorMap.default

    const useStyles = makeStyles((theme) => ({
        cardActions: {
            fill: theme.palette.secondary.main
        },
        colorfulHeader:{
            // if a course has a partnership, use partner colors for gradient. Otherwise, use default colors
            background: `linear-gradient(to left, rgb(${pathwayColorObj.rgbValues}, 0.6), rgb(${pathwayColorObj.rgbValues}))`,
            borderRadius: '8px 8px 0px 0px'
        },
        daysLeft: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "7.5rem",
            height: "2rem",
            backgroundColor: theme.palette.primary[900],
            borderRadius: ".25rem",
            opacity: "1",
            fontSize: ".75rem",
            color: "#FFFFFF",
        },
        externalTextContainer: {
            marginLeft:"1rem",
            backgroundColor: pathwayColorMap[pathway.pathwayCode].hex, 
            paddingLeft: "0.5rem",
            borderRadius: "5px",
          },
        externalText: {
            padding: "0rem 0.25rem",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 4px 4px 0px",
            border: "1px solid #E0E0E4",
        },
        pathHeading: {
            fontSize: '.75rem',
            color: theme.palette.primary.main,
            opacity: .64,
            textTransform: 'uppercase'
        },
        smallIcon:{
            width:"1rem", 
            paddingTop:"4px",
            color: theme.palette.primary[300]
        },
        verticalIcon:{
            paddingTop:"6px",
            color: theme.palette.primary[300]
        },
        subtitleText:{
            color: theme.palette.primary[300]  
        },
        truncated: {
            width:"100%",
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
      }));
    
    const classes = useStyles()

    //FIXME: may want to refactor fetchLearnerPathways to get pathway from database instead of iterating through and injecting
    course.pathName=pathway.name

    //FIXME: combine logic here with LearnerDrawerCta
    async function handleClickRegisterAndPay(){
        const successUrl = window.location.origin+"/successful-payment"
        const currentUrl = window.location.href

        // TODO: Figure out how best to incoporate unit
        // Redirect to payment URL
        const {firstName, lastName, email, orgUnitName} = learnerInformation
        const fullName = firstName + " " + lastName

        const stripePackage = [course.name, course.description, 'course', currentUrl, successUrl, fullName, email, orgUnitName, course.id]
        const paymentUrlResponse = await fetchPaymentUrl(...stripePackage)
        
        // Checking to see if stripe is returning errors
        if(paymentUrlResponse.errors || !paymentUrlResponse.data.StripeCheckout){
            dispatch(updateSnackbarData("A checkout session could not be generated. Please contact an administrator.", true, 'error'))
            console.log('paymentUrlResponse', paymentUrlResponse)
        } else {
            const paymentUrl = paymentUrlResponse.data.StripeCheckout.session_url
            window.location.href = paymentUrl
        }
    }

    //FIXME: combine logic here with LearnerDrawerCta
    async function handleClickDownloadInvoice(){
        setInvoiceLoading(true)
        const invoiceUrlResponse = await fetchInvoiceUrl(course, learnerInformation, 'course')

        // Checking to see if stripe is returning errors
        if(invoiceUrlResponse.errors || !invoiceUrlResponse.data.StripeInvoice){
            console.log(invoiceUrlResponse.errors)
            dispatch(updateSnackbarData("An invoice could not be generated. Please contact an administrator.", true, 'error'))
        } else {
            const invoiceUrl = invoiceUrlResponse.data.StripeInvoice?.invoice_pdf
            // TODO: Refactor so that backend forward stripe errors from earlier on in resolver
            if(invoiceUrl){
                window.location.href=invoiceUrl
            }else{
                dispatch(updateSnackbarData("An invoice could not be generated. Please contact an administrator.", true, 'error'))
            }
        }
        setInvoiceLoading(false)
    }

    function handleClickRegister(){
        // handle enroll submission
        submitCourseRegistration(learnerInformation, course)
    }

    async function submitCourseRegistration(learnerinfo, course) {
        // Enroll in a cohort if there are any enrollable cohorts
        const cohort = nextEnrollableCohort(course)
        const result = await createEnrollment(learnerinfo, cohort.id)
        if (result && result.length > 0) {
            dispatch(updateSnackbarData(`You've successfully registered for ${course.name}`, true, 'success'))
            // Add registration to store
            const updatedRegistrations = [...registeredCohorts, {
                "id": cohort?.id,
                "deliveryId": cohort?.deliveryId,
                "deliveryLinks": cohort?.deliveryLinks,
                "liveSessionLink": cohort?.liveSessionLink,
                "cohortSessionsByCohortIdList": cohort?.cohortSessionsByCohortIdList,
                "coursePurchase": {
                    "course": {
                        "name": course?.name,
                        "description": course?.description,
                        "tags": course?.tags,
                        "prerequisitesDescription": course?.prerequisitesDescription,
                        "softwareRequirements": course?.softwareRequirements
                    }
                }
            }]
            dispatch(updateCourseRegistrations(updatedRegistrations))
            setTimeout(() => {
                window.location.reload(true)
            }, 1000)
        } else {
            dispatch(updateSnackbarData(`We encountered a problem registering for ${course.name}`, true, 'error'))
        }
    }

    return(
        <div style={{padding:"1rem"}}>
            <Paper elevation={5} style={{backgroundColor:"#ffffff", borderRadius:"8px"}} >
                <div className={classes.colorfulHeader} style={{height:"8px"}} />
                <Grid container>
                    <Grid item container xs={12} style={{padding:"1.5rem"}}>
                        <Grid item>
                            {pathIconComponent(pathway?.pathwayCode, {height:"3rem", width:"3rem", display:"inherit", margin:"auto"})}
                        </Grid>
                        <Grid container item style={{paddingLeft: "1rem"}} xs={10}>
                            <Grid item xs={12}>
                                <Typography className={classes.pathHeading}>
                                    {pathway?.name}
                                </Typography>
                                <Typography color="primary" variant="h6" className={classes.truncated} style={{fontWeight:700}}>
                                    {course?.name}
                                </Typography>
                            </Grid>
                            <Grid item container alignItems="center" style={{paddingTop:"0.25rem"}}>
                                <Grid item xs={ctaType==='register' ? 12 : null} style={{paddingRight: "0.5rem"}}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <EventIcon className={classes.smallIcon}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" className={classes.subtitleText}>
                                                {formattedDate(cohort.dateStart) + "-" + formattedDate(cohort.dateEnd)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{paddingRight: "0.5rem"}}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <ScheduleIcon className={classes.smallIcon}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" className={classes.subtitleText}>
                                                {pluralize("Hour", parseInt(course?.hours), true)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{paddingRight: "0.5rem"}}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <BookOutlinedIcon className={classes.verticalIcon} />
                                        </Grid>
                                        <Grid item style={{padding:'0rem'}}>
                                            <Typography variant="subtitle2" className={classes.subtitleText}>
                                                {pluralize("Topic", course?.courseStructure.length, true)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{marginTop:"0.5rem"}}>
                            {course?.tags.slice(0,3).map((tag, index) => {
                                return (
                                    <ProfileTag key={index} tagText={tag}/>
                                )
                            })}
                            </Grid>
                            {cohortStatusObj?.enrollment?.cohort?.cohortSessionsByCohortIdList?.length > 0 && 
                            <Grid item container xs={8} style={{marginTop:"1rem"}}>
                                <Paper style={{padding:"1rem", backgroundColor:"#fafafa", width:"100%"}} elevation={0}>
                                    {cohortStatusObj?.enrollment?.cohort?.cohortSessionsByCohortIdList.map( (session, index) => {
                                        const dateTime = new Date(session.startTimestamp)
                                        return(
                                            <Grid key={`cohort-session-${index}`} item container justifyContent='space-between' spacing={2}>
                                                <Grid item>{dateTime.toLocaleDateString()}</Grid>
                                                <Grid item>{`${dateTime.toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'})}-${addHours(session.durationHours, dateTime)} ${timeZone}`}</Grid>
                                            </Grid>
                                        )
                                    })}
                                </Paper>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{padding:"0rem 0rem"}}>
                        <Divider />
                    </Grid>
                    <Grid item container direction="row" xs={12} style={{padding:"1.5rem"}} justifyContent="space-between" alignItems="center">
                        {
                            ctaType === "course-content" &&
                            <Grid item>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Button 
                                        variant="contained" 
                                        color="secondary"
                                        disabled={!(cohortStatusObj?.materialsStatus==="AVAILABLE")}
                                        href={`/course-content/${course?.courseSlug}/${cohortStatusObj?.enrollment.cohort.id}`}
                                        >
                                            {
                                                cohortStatusObj?.materialsStatus==="AVAILABLE"?
                                                "View Course Content"
                                                :
                                                `Course Content Available ${cohortStatusObj?.cohortdates?.dateAvailable}`
                                            }
                                        </Button>
                                    </Grid>
                                    {
                                        (cohortStatusObj?.materialsStatus==="AVAILABLE" && isDeliveryText) &&
                                        <>
                                            <textarea id="copy-value" readOnly name="copy-value" value={cohort.externalDelivery?.ext_delivery_value} hidden/>
                                            <Grid item>
                                                <div className={classes.externalTextContainer}>
                                                    <div className={classes.externalText}>
                                                        <Typography style={{fontFamily:"Roboto", fontSize: "0.625rem", fontWeight:"500", lineHeight:"1rem",}}>
                                                            {cohort.externalDelivery?.ext_delivery_label.toUpperCase()}
                                                        </Typography>
                                                        <Typography variant="subtitle2" display="block" style={{fontWeight:"700", lineHeight:"1rem",}}>
                                                            {cohort.externalDelivery?.ext_delivery_value}
                                                        </Typography>
                                                    </div>
                                                </div> 
                                            </Grid>
                                            <Grid item>
                                                <Tooltip 
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    placement="right"
                                                    onClose={() => setTooltipOpen(false)}
                                                    open={tooltipOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied"
                                                >
                                                    <IconButton
                                                        onClick={()=>handleCopy('copy-value', setTooltipOpen)}
                                                        size="small"
                                                    >
                                                        <ContentCopyIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>        
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        }
                        {
                            ctaType === "register" &&
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                    {
                                        paymentEnabled?
                                        <>
                                            <Grid item>
                                                <Button 
                                                    id={cardIndex===0? 'first-register-card' : ''}
                                                    variant="contained" 
                                                    color="secondary" 
                                                    name="register_pay" 
                                                    type="submit" 
                                                    onClick={handleClickRegisterAndPay}
                                                >
                                                    Register & Pay
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button 
                                                    variant="text" 
                                                    color="secondary" 
                                                    name="download_invoice" 
                                                    startIcon={invoiceLoading ? <CircularProgress size={20} color="secondary" /> : <FileDownloadOutlinedIcon />}
                                                    disabled={invoiceLoading}
                                                    onClick={handleClickDownloadInvoice}
                                                    style={{textTransform: "none"}}
                                                >
                                                    { invoiceLoading ? 'Downloading' : 'Download a Printable Invoice' }
                                                </Button>
                                            </Grid>
                                        </>
                                    :
                                        <Grid item>
                                            <Button 
                                                id={cardIndex===0? 'first-register-card' : ''}
                                                variant="contained" 
                                                color="secondary" 
                                                fullWidth 
                                                name="register" 
                                                onClick={handleClickRegister}
                                            >
                                                Register
                                            </Button>
                                        </Grid>     
                                    }
                                    {
                                        daysLeftInCourse(course) <= showDaysLeftTrigger && 
                                        <Grid item>
                                            <DaysLeftIndicator course={course}/>
                                        </Grid>
                                    }
                                    </Grid>
                                </Grid>
                            }
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <IconButton size="small" onClick={() => navigate(`/course-catalog/${pathway?.pathwaySlug}/${course?.courseSlug}`)}>
                                        <CourseCatalogIcon className={classes.cardActions} style={{height:"1.5rem", width:"auto"}}/>
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton size="small" onClick={() => navigate(`/pathways/${pathway?.pathwaySlug}/${course?.courseSlug}`)}>
                                        <PathwaysIcon className={classes.cardActions} style={{height:"1.5rem", width:"auto"}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default DashboardCourseCard