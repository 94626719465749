import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchSessionData, getEnrollableCohortsFromPathway, fetchCohortById } from '../lib/registrationRequest'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ErrorPage from './ErrorPage'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ReceiptLogo } from '../assets/imgs/receipt-meldr-powered.svg'
import { updateCourseRegistrations, updatePathwayRegistrations, updateSnackbarData } from '../redux/actions'

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor:"#F0F0F1", 
        minHeight: "100vh", 
        paddingBottom:"2rem"
    },
    topActions: {
        padding: '2rem'
    },
    subHeading: {
        fontWeight:"bold",
        paddingTop:"1rem"
    },
    confirmationPaper: {
        backgroundColor:"#ffffff",
        padding:"2rem"
    },
    infoDivider: {
        backgroundColor:"#F0F0F1",
        borderRadius:'8px', 
        padding:"1rem", 
        margin:"1rem 0rem"
    },
    dividerHeading: {
        fontWeight:"bold", 
        paddingRight:".5rem"
    },
    paymentBlock: {
        backgroundColor:"#F0F0F1", 
        padding:"1rem", 
        marginTop:"1rem"
    },
    rightInfo: {
        paddingLeft: '2rem'
    }
}));

function PaymentConfirmation(){
    const classes = useStyles()
    const location = useLocation()
    const dispatch = useDispatch()
    const [sessionId, setSessionId] = useState('')
    const [sessionData, setSessionData] = useState(null)
    const [loading, setLoading] = useState(true)
    const learnerInformation = useSelector(state => state.learnerInformation)
    const registeredCohorts = useSelector(state => state.registeredCohorts)
    const registeredCourseNames = registeredCohorts.map(cohort => cohort.coursePurchase.course.name)
    const registeredPathways = useSelector(state => state.registeredPathways)
    const learnerPaths = useSelector(state => state.learnerPaths)

    useEffect(()=>{
        const params = new URLSearchParams(location.search)
        if (params.get('session_id')){
            setSessionId(params.get('session_id'))
        } 
        setLoading(false)
    },[location.search])

    useEffect(() => {
        async function getSessionData(sessionId){
            const retrievedSessionData = await fetchSessionData(sessionId)
            setSessionData(retrievedSessionData) 
            return retrievedSessionData 
        }

        if(sessionId){
            getSessionData(sessionId)
        }
    }, [sessionId])
    
    useEffect(() => {
        async function submitUserRegistrationCourse(learnerinfo, cohortId, itemName) {

            const retrievedCohortInfo = await fetchCohortById(cohortId)

            // // Add registration to localStorage and udpate in redux
            const updatedRegistrations = [...registeredCohorts, retrievedCohortInfo]
            dispatch(updateCourseRegistrations(updatedRegistrations))
            
            return true
        }

        async function submitUserRegistrationPathway(learnerInfo, pathId) {
            const pathway = getPathById(pathId)
            if( !pathway ){
                console.warn(`Unable to match Pathway by ID, so we can't note enrollments in local storage.`)
                return null
            }

            const coursesAndNextCohorts = getEnrollableCohortsFromPathway( pathway )

            // Put new enrollments into local storage
            try {
                const updatedPathwayRegistrations = [...registeredPathways, pathway?.name]
                dispatch(updatePathwayRegistrations(updatedPathwayRegistrations))

                const updatedRegistrations = [...registeredCohorts, ...coursesAndNextCohorts.map(courseAndNextCohort => {
                    
                    return {
                        "id": courseAndNextCohort?.cohort?.id,
                        "deliveryId": courseAndNextCohort?.cohort?.deliveryId,
                        "deliveryLinks": courseAndNextCohort?.cohort?.deliveryLinks,
                        "liveSessionLink": courseAndNextCohort?.cohort?.liveSessionLink,
                        "cohortSessionsByCohortIdList": courseAndNextCohort?.cohort?.cohortSessionsByCohortIdList,
                        "coursePurchase": {
                            "course": {
                                "name": courseAndNextCohort?.cohort?.coursePurchase?.course?.name,
                                "description": courseAndNextCohort?.cohort?.coursePurchase?.course?.description,
                                "tags": courseAndNextCohort?.cohort?.coursePurchase?.course?.tags,
                                "prerequisitesDescription": courseAndNextCohort?.cohort?.coursePurchase?.course?.prerequisitesDescription,
                                "softwareRequirements": courseAndNextCohort?.cohort?.coursePurchase?.course?.softwareRequirements
                            }
                        }
                    }
                })]
                dispatch(updateCourseRegistrations(updatedRegistrations))

            }
            catch(registrationError){
                // Need to add error handling!
                console.error(`Error enrolling in pathway ${pathway.name}`, registrationError)
                dispatch(updateSnackbarData(<>We encountered a problem registering for <strong>{pathway.name}</strong></>, true, 'error'))
            }
        }

        function getPathById( pathId ){
            return learnerPaths.find(path => path.id === Number(pathId))
        }

        if(sessionData && sessionData.item_type==="course" && !registeredCourseNames.includes(sessionData.item_name)){
            submitUserRegistrationCourse(learnerInformation, sessionData.enrollment_type_id, sessionData.item_name)
        }
        if(sessionData && sessionData.item_type==="pathway" && !registeredPathways.includes(sessionData.item_name)){
            submitUserRegistrationPathway(learnerInformation, sessionData.enrollment_type_id)
        }
    }, [sessionData, learnerInformation, dispatch, registeredCohorts, registeredCourseNames, registeredPathways, learnerPaths])

    function handleClickReceipt(receiptUrl) {
        window.open(receiptUrl)
    }

    return(
        <div className={classes.root}>
        {!loading && !sessionId &&
            <div style={{padding:"2rem 4rem"}}>
                <ErrorPage errorType="404" />
            </div>
        }
        {
            !loading && sessionId && sessionData &&
            <>
                <Grid container justifyContent="space-between" className={classes.topActions}>
                    <Grid item>
                        <Button 
                            href="/"
                            variant='contained' 
                            color='secondary'
                            startIcon={<ArrowBackOutlinedIcon />}
                        >
                            Back to meldR
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{padding:'1rem', marginTop:'4rem'}}>
                    <Grid item xs={11} md={10} lg={8}>
                        <Paper className={classes.confirmationPaper}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <ReceiptLogo id="receipt-logo"/>
                                </Grid>
                                <Grid item xs={8} className={classes.rightInfo}>
                                    <Typography variant='h4' style={{paddingBottom:"1rem"}}>
                                        Registration & Payment Confirmed
                                    </Typography>
                                    <Typography variant='h5'>
                                        An email receipt will be sent to: 
                                    </Typography>
                                    <Typography variant='h5' style={{fontWeight:"bold"}}>
                                        {sessionData.email}
                                    </Typography>
                                    <Button color="secondary" style={{marginTop:'2rem'}} onClick={()=>handleClickReceipt(sessionData.receipt_url)}>
                                        View Receipt
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </>
            }
        </div>
    )
}

export default PaymentConfirmation